import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import { TiArrowLeft } from 'react-icons/ti';
import { Container, Img } from './styles';

import api from '~/services/api';
import Loading from '~/components/Loading';
import NoData from '~/components/NoData';
import GalleryModal from '~/components/GalleryModal';

interface IParams {
  slug: string;
  gallerySlug: string;
}

interface IGallery {
  id: number;
  title: string;
  year: string;
}

interface IArchive {
  id: number;
  archive_url: string;
}

interface IArchiveResponse {
  data: IArchive[];
  from: number;
  to: number;
  total: number;
}

const Gallery: React.FC = () => {
  const params = useParams<IParams>();
  const [gallery, setGallery] = useState({} as IGallery);
  const [photos, setPhotos] = useState<IArchive[]>([]);
  const [photoIndexSelected, setPhotoIndexSelected] = useState(0);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(true);

  const handleLoadPhotos = useCallback(
    async (page) => {
      if (gallery.id) {
        const response = await api.get<IArchiveResponse>(
          `archives/galleries/${gallery.id}`,
          {
            params: {
              page,
            },
          }
        );
        if (page === 1) {
          setPhotos(response.data.data);
        } else {
          setPhotos((state) => [...state, ...response.data.data]);
        }
      }
    },
    [gallery.id]
  );

  useEffect(() => {
    api
      .get(`galleries/${params.gallerySlug}`)
      .then(async (response) => {
        setGallery(response.data);
        await handleLoadPhotos(1);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [handleLoadPhotos, params.gallerySlug]);

  const photosStruture = useMemo(() => {
    let isOddRow = true;
    let index = 0;
    const data = photos.map((photo) => {
      let columnClass = '';

      if (isOddRow) {
        columnClass = index === 0 ? 'col-6' : 'col-6 col-lg-3';
      } else {
        columnClass = index === 2 ? 'col-6' : 'col-6 col-lg-3';
      }

      if (index === 2) {
        if (isOddRow) {
          isOddRow = false;
        } else {
          isOddRow = true;
        }
        index = 0;
      } else {
        index += 1;
      }

      return {
        ...photo,
        columnClass,
        src: photo.archive_url,
        alt: 'Foto',
        loading: 'lazy' as 'lazy' | 'auto' | 'eager' | undefined,
      };
    });

    return data;
  }, [photos]);

  const handleSelectPhoto = useCallback((index) => {
    setPhotoIndexSelected(index);
    setShow(true);
  }, []);

  const handleClose = useCallback(() => {
    setShow(false);
  }, []);

  return (
    <Container className="py-5">
      <div className="container">
        <div className="row justify-content-center justify-content-lg-between align-items-center">
          <div className="col-12 d-flex align-items-center mb-5">
            <Link
              to={`${process.env.PUBLIC_URL}/maternais/${params.slug}/galerias`}
              className="me-3"
            >
              <TiArrowLeft size={52} color="#707070" />
            </Link>
            <h1 className="h3 h1-lg text-secondary mb-0">
              {gallery.title} ({gallery.year})
            </h1>
          </div>
          {photosStruture.length > 0 ? (
            <>
              {photosStruture.map((photo, index) => (
                <div key={photo.id} className={`${photo.columnClass} my-2`}>
                  <Img
                    type="button"
                    src={photo.archive_url}
                    onClick={() => handleSelectPhoto(index)}
                  />
                </div>
              ))}
            </>
          ) : (
            <div className="col-12 mb-5">
              <NoData message="Nenhuma foto cadastrada" />
            </div>
          )}
        </div>
      </div>
      <Loading active={loading} color="#00a189" />
      <GalleryModal
        show={show}
        onClose={handleClose}
        photos={photosStruture.map((photo) => ({
          src: photo.src,
          alt: photo.alt,
          loading: photo.loading,
        }))}
        currentIndex={photoIndexSelected}
      />
    </Container>
  );
};

export default Gallery;
