import styled from 'styled-components';
import next from '~/assets/icons/next_arrow.svg';
import prev from '~/assets/icons/prev_arrow.svg';
import curriculum from '~/assets/defaults/join-us.png';
import curriculumMob from '~/assets/defaults/join-us-mob.png';

interface IBanner {
  src: string;
}

export const Container = styled.div`
  .slick-list {
    border-radius: 0 0 40px 40px;
  }
`;

export const Hero = styled.div`
  max-width: 100%;
  overflow: hidden;
`;

export const Banner = styled.div<IBanner>`
  background-image: url(${(props) => props.src});
  background-size: cover;
  background-position: 70%;
  min-height: 100vh;
  position: relative;

  border-radius: 0;

  .p-relative {
    position: relative;
    z-index: 1;
  }

  ::before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    //background-color: rgba(0, 0, 0, 0.3);
    top: 0;
    left: 0;
  }

  .logo {
    margin-bottom: 0px;
  }

  .logo img {
    width: 49px;
    height: 49px;
  }

  .logo span {
    span:nth-child(1) {
      margin-bottom: 20px;
      display: inline-block;
    }

    span:nth-child(3) {
      line-height: 5px;
      display: inline-flex;
      background: rgba(0, 0, 0, 0.35);
      -webkit-box-shadow: 0px 0px 31px 0px rgba(0, 0, 0, 0.35);
      -moz-box-shadow: 0px 0px 31px 0px rgba(0, 0, 0, 0.35);
      box-shadow: 0px 0px 31px 20px rgba(0, 0, 0, 0.35);
    }
  }

  @media screen and (min-width: 992px) {
    background-position: center;
    border-radius: 0 0 40px 40px;

    .logo img {
      width: 56px;
      height: 56px;
    }

    .logo {
      margin-bottom: 100px;
    }

    .logo span {
      span:nth-child(1) {
        margin-bottom: 30px;
      }

      span:nth-child(3) {
        line-height: 13px;
        box-shadow: 0px 0px 31px 35px rgba(0, 0, 0, 0.35);
      }
    }
  }
`;

export const Notices = styled.div`
  h2 {
    color: #202020;
    font-family: 'Poppins';
    font-size: 24px;
    font-weight: 400;
  }

  .slick-dots li button:before {
    font-family: 'slick';
    font-size: 13px;
    color: #fff;
  }

  .slick-dots {
    bottom: 25px;
  }

  .slick-list {
    border-radius: 16px;
  }

  .slick-next,
  .slick-prev {
    width: 54px;
    height: 54px;
    border-radius: 11px;
    background: #e7e7e7;
  }

  .slick-next {
    display: none !important;
    right: 26px;
    bottom: -16%;
    top: unset;
  }

  .slick-prev {
    display: none !important;
    top: unset;
    left: unset;
    bottom: -16%;
    right: 93px;
  }

  .slick-next:before {
    content: url(${next});
  }

  .slick-prev:before {
    content: url(${prev});
  }

  .notice {
    overflow: hidden;
    border-radius: 16px;

    p {
      bottom: 25px;
      left: 25px;
      z-index: 1;
      color: #fff;
    }

    ::before {
      content: '';
      background: linear-gradient(180deg, #00000000 16.15%, #2d2d2d 100%);
      position: absolute;
      width: 100%;
      height: 100%;
    }
  }
  @media screen and (min-width: 992px) {
    h2 {
      font-size: 40px;
    }
    .notice {
      border-radius: 40px;

      p {
        bottom: 50px;
        left: 50px;
      }
    }

    .slick-next,
    .slick-prev {
      display: block !important;
    }
  }
`;

export const Institute = styled.div`
  margin-top: 50px;
  .your-institute {
    border-radius: 16px;
  }

  h2 {
    color: #202020;
    font-family: 'Poppins';
    font-size: 24px;
    font-weight: 300;
    line-height: normal;
    span {
      font-weight: 500;
    }
  }

  p {
    color: #000;
    font-family: 'Poppins';
    font-size: 16px;
    font-weight: 300;
    line-height: normal;
  }

  @media screen and (min-width: 992px) {
    margin-top: 150px;
    h2 {
      font-size: 40px;
    }

    p {
      font-size: 24px;
    }

    .your-institute {
      border-radius: 27px;
    }
  }
`;

export const Curriculum = styled.div`
  margin: 130px 0;

  h2 {
    color: #202020;
    font-family: 'Poppins';
    font-size: 24px;
    font-weight: 400;
    line-height: normal;
    span {
      font-weight: 600;
    }
  }

  h3 {
    color: #707070;
    font-family: 'Poppins';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
  }

  a {
    color: #fff;
    font-family: 'Poppins';
    font-size: 16px;
    font-weight: 600;
    line-height: normal;
    border-radius: 29px;
    background: #db562b;
    width: 136px;
    height: 46px;
  }
  .bg-join {
    background: url(${curriculumMob});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    border-radius: 16px;
    min-height: 580px;
  }

  @media screen and (min-width: 992px) {
    h2 {
      font-size: 48px;
    }
    h3 {
      font-size: 24px;
    }
    a {
      font-size: 24px;
      width: 209px;
      height: 58px;
    }
    .bg-join {
      background: url(${curriculum});
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      border-radius: 46px;
      min-height: 500px;
    }
  }
`;

export const Values = styled.div`
  button {
    color: #707070;
    font-family: 'Poppins';
    font-size: 24px;
    font-weight: 400;
    border: none;
    width: 93px;
    hr {
      opacity: 0;
      margin: 10px auto;
    }
  }

  h2 {
    color: #202020;
    font-family: 'Poppins';
    font-size: 24px;
    font-weight: 500;
    letter-spacing: -1.2px;
  }

  h5 {
    color: #707070;
    font-family: 'Poppins';
    font-size: 16px;
    font-weight: 300;
  }

  .bg-heart {
    background: #f15a29;
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      width: 35px;
      height: 35px;
    }
  }

  .bg-pc {
    background: #c69741;
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      width: 35px;
      height: 35px;
    }
  }

  .bg-transparenci {
    background: #335697;
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      width: 35px;
      height: 35px;
    }
  }

  .active-mission {
    color: #007983;
    font-weight: 600;
    hr {
      height: 4px;
      opacity: 1;
      border-radius: 5px;
      margin: 10px auto;
    }
  }

  .active-vision {
    color: #db562b;
    font-weight: 600;
    hr {
      height: 4px;
      opacity: 1;
      border-radius: 5px;
      margin: 10px auto;
    }
  }

  .active-values {
    color: #00a189;
    font-weight: 600;
    hr {
      height: 4px;
      opacity: 1;
      border-radius: 5px;
      margin: 10px auto;
    }
  }

  .bg {
    border-radius: 24px;
  }

  .content {
    img {
      height: 338px !important;
    }

    p {
      height: 150px;
      overflow: auto;
    }
  }

  .bg-mission {
    background: #007983;
    border-radius: 24px;
  }

  .bg-vision {
    background: #db562b;
    border-radius: 24px;

    p {
      ::-webkit-scrollbar-thumb {
        background-color: #00a189;
      }
    }
  }

  .bg-values {
    background: #00a189;
    border-radius: 24px;
  }

  .bg-mission h3,
  .bg-vision h3,
  .bg-values h3 {
    color: #fff;
    font-family: 'Poppins';
    font-size: 24px;
    font-weight: 600;
  }

  .bg-mission p,
  .bg-vision p,
  .bg-values p {
    color: #fff;
    font-family: 'Poppins';
    font-size: 18px;
    font-weight: 300;
  }

  .bar {
    width: 36px;
    height: 5px;
    border-radius: 22px;
    background: #fff;
  }

  @media screen and (min-width: 992px) {
    .mt-170 {
      margin: 170px 0;
    }
    .bg-mission h3,
    .bg-vision h3,
    .bg-values h3 {
      font-size: 40px;
    }
    .bg {
      border-radius: 60px 0;
    }
    .bg-mission,
    .bg-vision,
    .bg-values {
      border-radius: 0 60px 0 60px;
    }

    .bg-heart,
    .bg-pc,
    .bg-transparenci {
      width: 146px;
      height: 146px;
      svg {
        width: 51px;
        height: 51px;
      }
    }
  }

  @media screen and (min-width: 1200px) {
    .content {
      img {
        height: 405px !important;
      }

      p {
        height: 200px;
      }
    }
  }

  @media screen and (min-width: 1400px) {
    .content {
      img {
        height: 100% !important;
      }

      p {
        height: 250px;
      }
    }
  }
`;

export const Secretary = styled.div`
  img {
    width: 197px;
    height: 41px;
    margin: 0 auto;
  }

  a {
    display: block;
    color: #707070;
    font-family: 'Poppins';
    font-size: 16px;
    font-weight: 400;
    letter-spacing: -1.2px;
  }

  .bg-secretary {
    display: flex;
    flex-direction: column;
    border-radius: 18px;
    border: 1px solid #d8d8d8;
    background: #fff;
    height: 178px;
    margin-bottom: -30px;
  }

  @media screen and (min-width: 992px) {
    img {
      width: 279px;
      height: 58px;
      margin: 0;
    }

    a {
      font-size: 24px;
    }

    .bg-secretary {
      height: 141px;
      margin-bottom: -70px;
      flex-direction: initial;
    }
  }
`;
