import styled from 'styled-components';
import { Modal as ModalComponent } from 'react-bootstrap';

export const Container = styled.div`
  min-height: calc(100vh - 70px);

  .btn-back {
    background-color: #e7e7e7;
    border-radius: 50%;
    width: 40px;
    height: 40px;
  }

  h1 {
    width: calc(100% - 48px);
  }

  .process-qtd {
    border-radius: 100px;
    border: 1px solid #e8e8e8;
    background: #eaeaea;
  }

  .box-process {
    border-radius: 32px;
    overflow: hidden;
    background: #4589da;

    .tag {
      background: #c9e2ff;
    }

    .see-process {
      background: #0d4485;

      > div {
        background: #5982b3;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .quotations {
    height: calc(100vh - 546px);
    overflow: auto;

    ::-webkit-scrollbar-thumb {
      background-color: #4589da;
    }

    .quotation-box {
      border-radius: 16px;
      border: 1px solid #d7d7d7;

      .detail {
        width: 5px;
        height: 56px;
        border-radius: 16px;
        background: #4589da;
      }

      .quotation-data {
        .title {
          width: 100%;
        }

        .tag-box {
          .tag {
            background: #c9e2ff;
            border-radius: 100px;
          }
        }

        .btn-see-more {
          border-radius: 40px;
          background: #0d4485;

          > div {
            background: transparent;
            width: 24px;
            height: 24px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }

      + .quotation-box {
        margin-top: 13px;
      }
    }
  }

  .btn-pagination {
    display: flex;
    width: 64px;
    height: 64px;
    padding: 20px;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    border: 1px solid transparent;
    font-size: 18px;
    font-weight: 700;

    + .btn-pagination {
      margin-left: 20px;
    }
  }

  .btn-pagination.arrow,
  .btn-pagination.selected,
  .btn-pagination:not(.ellipsis):hover {
    border: 1px solid #707070;
  }

  @media screen and (min-width: 992px) {
    .quotations {
      .quotation-box {
        .detail {
          width: 8px;
        }

        .quotation-data {
          width: 100%;

          .title {
            width: calc(100% - 600px);
          }

          .tag-box {
            width: 175px;
          }

          .btn-see-more {
            > div {
              background: #5982b3;
            }
          }
        }
      }
    }

    .btn-pagination {
      width: 64px;
      height: 64px;
      padding: 20px;
      font-size: 18px;

      + .btn-pagination {
        margin-left: 20px;
      }
    }
  }
`;

export const Modal = styled(ModalComponent)`
  .modal-content {
    border-radius: 32px;
    border: 1px solid #f0efef;
    background: #fff;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1);

    .btn-archive {
      border-radius: 8px;
      border: 1px solid #cacaca;
      background: #f4f4f4;
    }

    .input-file {
      position: relative;

      .error-box {
        position: absolute;
        bottom: -35px;
      }
    }
  }
`;
