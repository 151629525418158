/* eslint-disable react/no-danger */
import React, { useEffect, useState } from 'react';

import { Container, Banner } from './styles';

import { useNursery } from '~/hooks/Nursery';

interface INursery {
  banner_url: string;
  story: string;
  mission_url: string;
  mission: string;
  vision_url: string;
  vision: string;
  values_url: string;
  values: string;
  details: string;
}

const AboutUs: React.FC = () => {
  const { nursery: nuseryData } = useNursery();
  const [nursery, setNursery] = useState({} as INursery);

  useEffect(() => {
    if (Object.keys(nuseryData).length > 0) {
      const data = nuseryData.story.split('#*;');
      setNursery({
        banner_url: nuseryData.archives[0].archive_url,
        story: data[0],
        mission_url: nuseryData.archives[1].archive_url,
        mission: data[1],
        vision_url: nuseryData.archives[2].archive_url,
        vision: data[2],
        values_url: nuseryData.archives[3].archive_url,
        values: data[3],
        details: data[4],
      });
    }
  }, [nuseryData]);

  return (
    <Container>
      {Object.keys(nursery).length > 0 && (
        <div className="container pt-5">
          <div className="row">
            <div className="col-12">
              <h1 className="h4 h1-lg text-secondary mb-4">Sobre a Escola</h1>
              <Banner
                src={nursery.banner_url}
                className="d-flex align-items-end p-5 py-lg-0 mb-5"
              />
              <p
                className="mb-5 fs-lg-4"
                dangerouslySetInnerHTML={{ __html: nursery.story }}
              />
              <h2 className="h3 h1-lg text-secondary pt-5 mb-5">
                Filosofia da Escola
              </h2>
              <div className="row justify-content-between pt-4">
                <div className="col-lg-6">
                  <h3 className="h6 h4-lg text-secondary">Missão</h3>
                  <p
                    className="fs-lg-4"
                    dangerouslySetInnerHTML={{ __html: nursery.mission }}
                  />
                </div>
                <div className="col-lg-4">
                  <img
                    src={nursery.mission_url}
                    alt="Missão"
                    className="w-100"
                  />
                </div>
              </div>
              <div className="row justify-content-between my-5">
                <div className="col-lg-6">
                  <h3 className="h6 h4-lg text-secondary">Visão</h3>
                  <p
                    className="fs-lg-4"
                    dangerouslySetInnerHTML={{ __html: nursery.vision }}
                  />
                </div>
                <div className="col-lg-4">
                  <img src={nursery.vision_url} alt="Visão" className="w-100" />
                </div>
              </div>
              <div className="row justify-content-between">
                <div className="col-lg-6">
                  <h3 className="h6 h4-lg text-secondary">Valores</h3>
                  <p
                    className="fs-lg-4"
                    dangerouslySetInnerHTML={{ __html: nursery.values }}
                  />
                </div>
                <div className="col-lg-4">
                  <img
                    src={nursery.values_url}
                    alt="Valores"
                    className="w-100"
                  />
                </div>
              </div>
              {nursery.details && (
                <p className="my-5 py-5 fs-lg-4">{nursery.details}</p>
              )}
            </div>
          </div>
        </div>
      )}
    </Container>
  );
};

export default AboutUs;
