import styled, { css } from 'styled-components';

interface IContainer {
  direction?: 'horizontal' | 'vertical';
  reverse?: boolean;
}

export const Container = styled.div<IContainer>`
  ${(props) => {
    if (props.reverse && props.direction === 'vertical') {
      return css`
        transform: rotateX(180deg);

        > * {
          transform: rotateX(-180deg);
        }
      `;
    }
    if (props.reverse && props.direction === 'horizontal') {
      return css`
        direction: rtl;

        > * {
          direction: ltr;
        }
      `;
    }
    return css``;
  }}
`;
