import React, { useCallback, useRef, useState } from 'react';
import { BsBookmark, BsChat, BsHeart, BsSend } from 'react-icons/bs';
import SliderSlick from 'react-slick';

import { Container, Avatar, Img } from './styles';

import avatar from '~/assets/defaults/avatar-default.svg';

interface IInstagramMedia {
  id: string;
  username: string;
  avatar_url: string;
  caption: string;
  thumbnail_url: string;
  media_url: string;
  media_type: string;
  children: {
    id: string;
    thumbnail_url: string;
    media_url: string;
    media_type: string;
  }[];
}

interface IInstagramProps {
  data: IInstagramMedia;
}

const Instagram: React.FC<IInstagramProps> = ({ data }) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [videoPlay, setVideoPlay] = useState(false);

  const handleClickPlay = useCallback(() => {
    if (videoRef.current) {
      setVideoPlay(true);
      videoRef.current.volume = 0.5;
      videoRef.current.play();
    }
  }, []);

  return (
    <Container>
      <div className="d-flex align-items-center px-4 py-3">
        <Avatar src={data.avatar_url || avatar} />
        <p className="mb-0 ms-3">{data.username}</p>
      </div>
      {data.media_type === 'CAROUSEL_ALBUM' ? (
        <SliderSlick
          autoplay={false}
          arrows
          dots
          autoplaySpeed={3000}
          className="instagram-carousel"
        >
          {data.children.map((media) => (
            <Img src={media.thumbnail_url || media.media_url} />
          ))}
        </SliderSlick>
      ) : (
        <>
          {data.media_type === 'VIDEO' ? (
            <>
              <button
                type="button"
                onClick={handleClickPlay}
                className={`w-100 border-0 bg-transparent ${
                  videoPlay ? 'd-none' : ''
                }`}
              >
                <Img src={data.thumbnail_url || data.media_url} />
              </button>
              <video
                ref={videoRef}
                className={`w-100 ${!videoPlay ? 'd-none' : ''}`}
                controls
                controlsList="nodownload"
              >
                <source src={data.media_url} type="video/mp4" />
                <track default kind="captions" srcLang="en" />
                Sorry, your browser doesn't support embedded videos.
              </video>
            </>
          ) : (
            <Img src={data.thumbnail_url || data.media_url} />
          )}
        </>
      )}
      <div className="d-flex justify-content-between px-4 py-3">
        <div className="d-flex">
          <button
            type="button"
            className="border-0 bg-transparent p-2"
            disabled
          >
            <BsHeart size={24} color="#262626" />
          </button>
          <button
            type="button"
            className="border-0 bg-transparent p-2 btn-chat"
            disabled
          >
            <BsChat size={24} color="#262626" />
          </button>
          <button
            type="button"
            className="border-0 bg-transparent p-2"
            disabled
          >
            <BsSend size={24} color="#262626" />
          </button>
        </div>
        <button type="button" className="border-0 bg-transparent p-2" disabled>
          <BsBookmark size={24} color="#262626" />
        </button>
      </div>
    </Container>
  );
};

export default Instagram;
