import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { LuArrowUpRight } from 'react-icons/lu';

import { format, parseISO } from 'date-fns';
import { AxiosResponse } from 'axios';
import { Container, Thumbnail } from './styles';

import { useNursery } from '~/hooks/Nursery';
import api from '~/services/api';
import Loading from '~/components/Loading';
import NoData from '~/components/NoData';
import { useResize } from '~/hooks/Resize';

interface IParams {
  slug: string;
}

interface INotice {
  id: number;
  title: string;
  description: string;
  slug: string;
  created_at: string;
  nursery: {
    name: string;
  };
  thumbnail: {
    archive_url: string;
  };
}

interface INoticeResponse {
  data: INotice[];
  from: number;
  to: number;
  total: number;
}

const Notices: React.FC = () => {
  const params = useParams<IParams>();
  const { nursery } = useNursery();
  const { width } = useResize();
  const [recents, setRecents] = useState<INotice[]>([]);
  const [notices, setNotices] = useState<INotice[]>([]);
  const [loading, setLoading] = useState(true);

  const handleLoadNotices = useCallback(
    async (page) => {
      let response: AxiosResponse<INoticeResponse>;
      if (Object.keys(nursery).length > 0) {
        response = await api.get<INoticeResponse>(
          `notices/nurseries/${nursery.id}`,
          {
            params: {
              page,
            },
          }
        );
      } else {
        response = await api.get<INoticeResponse>(`notices`, {
          params: {
            page,
          },
        });
      }

      const data = response.data.data.map((notice) => ({
        id: notice.id,
        title: notice.title,
        description: notice.description,
        slug: notice.slug,
        created_at: format(parseISO(notice.created_at), 'dd MMM yyyy'),
        nursery: {
          name: notice.nursery.name,
        },
        thumbnail: {
          archive_url: notice.thumbnail?.archive_url || '',
        },
      }));

      if (page === 1) {
        setRecents(data.slice(0, 3));
        setNotices(data.slice(3));
      } else {
        setNotices((state) => [...state, ...data]);
      }
    },
    [nursery]
  );

  useEffect(() => {
    handleLoadNotices(1).finally(() => {
      setLoading(false);
    });
  }, [handleLoadNotices]);

  const url = useMemo(() => {
    if (params.slug) {
      return `/maternais/${params.slug}`;
    }

    return '';
  }, [params.slug]);

  return (
    <Container>
      <div className="container pb-5">
        <div className="row">
          <div className="col-12">
            <h1 className="h4 h1-lg mb-4 mb-lg-5 text-secondary">Notícias</h1>
          </div>
        </div>

        {recents.length > 0 ? (
          <>
            <div className="row px-2">
              <div className="col-12 bg-main px-3 px-sm-4 px-lg-5 py-5">
                <div className="row">
                  <div className="col-12 mb-4">
                    <h2 className="h4 text-secondary">Recentes</h2>
                  </div>
                  <div className="col-lg-6 mb-4">
                    <Link
                      to={`${process.env.PUBLIC_URL}${url}/noticias/${recents[0].slug}`}
                    >
                      <Thumbnail
                        src={recents[0].thumbnail.archive_url}
                        height={228}
                        className="mb-4"
                      />
                      <small className="fw-semibold">
                        {recents[0].created_at}
                      </small>
                      <div className="d-flex align-items-center justify-content-between my-3">
                        <h3 className="h5 h4-lg mb-0 text-secondary fw-semibold">
                          {recents[0].title}
                        </h3>
                        <LuArrowUpRight size={24} color="#202020" />
                      </div>
                      <p>{recents[0].description}</p>
                      <span className="tag">{recents[0].nursery.name}</span>
                    </Link>
                  </div>
                  <div className="col-lg-6 mb-4">
                    {recents.slice(1).map((notice) => (
                      <Link
                        key={notice.id}
                        to={`${process.env.PUBLIC_URL}${url}/noticias/${notice.slug}`}
                        className="row"
                      >
                        <div className="col-lg-6">
                          <Thumbnail
                            src={notice.thumbnail.archive_url}
                            height={200}
                            className="mb-4"
                          />
                        </div>
                        <div className="col-lg-6 mb-4 mb-lg-0">
                          <small className="fw-semibold">
                            {notice.created_at}
                          </small>
                          <div className="d-flex align-items-center justify-content-between my-3">
                            <h3 className="h5 mb-0 text-secondary fw-semibold">
                              {notice.title}
                            </h3>
                            <LuArrowUpRight size={24} color="#202020" />
                          </div>
                          <p>{notice.description}</p>
                          <span className="tag">{notice.nursery.name}</span>
                        </div>
                      </Link>
                    ))}
                  </div>
                </div>
                {notices.length > 0 && (
                  <div className="row mt-2 mt-lg-5 old-notices">
                    {notices.map((notice) => (
                      <div
                        key={notice.id}
                        className="col-lg-12 pt-4 mb-4 pt-lg-5 mb-lg-5"
                      >
                        <Link
                          to={`${process.env.PUBLIC_URL}${url}/noticias/${notice.slug}`}
                          className="row justify-content-between align-items-lg-center"
                        >
                          <div className="col-8 px-0 px-sm-3 order-1 order-lg-0">
                            <div className="d-lg-flex align-items-lg-center">
                              <span className="d-none d-lg-block tag me-3">
                                {notice.nursery.name}
                              </span>
                              <small className="fw-semibold">
                                {notice.created_at}
                              </small>
                            </div>
                            <div className="d-lg-flex align-items-center justify-content-between my-3">
                              <h3 className="h5 h3-lg mb-0 text-secondary fw-semibold">
                                {notice.title}
                              </h3>

                              <LuArrowUpRight
                                size={24}
                                color="#202020"
                                className="d-none d-lg-block"
                              />
                            </div>
                            <p className="fs-6 fs-lg-5 mb-lg-0">
                              {notice.description}
                            </p>
                            <span className="d-lg-none tag me-3">
                              {notice.nursery.name}
                            </span>
                          </div>
                          <div className="col-4 col-lg-2 order-0 order-lg-1">
                            <Thumbnail
                              src={notice.thumbnail.archive_url}
                              height={width > 991 ? 170 : 93}
                            />
                          </div>
                        </Link>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </>
        ) : (
          <div className="row">
            <div className="col-12 mb-5">
              <NoData message="Nenhuma notícia cadastrada" />
            </div>
          </div>
        )}
      </div>
      <Loading active={loading} color={params.slug ? '#00a189' : undefined} />
    </Container>
  );
};

export default Notices;
