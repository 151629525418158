import styled from 'styled-components';

interface IBanner {
  src: string;
}

export const Container = styled.div`
  margin-top: 76px;

  img {
    border-radius: 20px;
  }

  @media screen and (min-width: 992px) {
    margin-top: 0;
  }
`;

export const Banner = styled.div<IBanner>`
  border-radius: 20px;
  background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.5) 0%,
      rgba(0, 0, 0, 0) 100%
    ),
    url(${(props) => props.src}), lightgray 50% / cover no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  height: 249px;

  .p-relative {
    position: relative;
    z-index: 1;
  }

  .logo {
    margin-bottom: 100px;
  }

  .logo img {
    width: 56px;
    height: 56px;
  }

  .logo span {
    span:nth-child(1) {
      margin-bottom: 30px;
      display: inline-block;
    }

    span:nth-child(3) {
      line-height: 13px;
      display: inline-flex;
      background: rgba(0, 0, 0, 0.35);
      -webkit-box-shadow: 0px 0px 31px 0px rgba(0, 0, 0, 0.35);
      -moz-box-shadow: 0px 0px 31px 0px rgba(0, 0, 0, 0.35);
      box-shadow: 0px 0px 31px 35px rgba(0, 0, 0, 0.35);
    }
  }

  @media screen and (min-width: 992px) {
    height: 528px;
  }
`;
