import React, { useCallback, useEffect, useState } from 'react';

import { Container, Activity } from './styles';

import { useNursery } from '~/hooks/Nursery';
import api from '~/services/api';
import Loading from '~/components/Loading';
import NoData from '~/components/NoData';

interface IProjectResponse {
  id: number;
  title: string;
  content: string;
  activities?: string;
  archive: {
    archive_url: string;
  };
}

interface IActivity {
  name: string;
  color: string;
}

interface IProject {
  id: number;
  title: string;
  content: string;
  activities?: IActivity[];
  archive: string;
}

const Projects: React.FC = () => {
  const { nursery } = useNursery();
  const [projects, setProjects] = useState<IProject[]>([]);
  const [loading, setLoading] = useState(false);

  const linkActivitiesWithColors = useCallback((activities: string[]) => {
    const colors = ['#C5D8F9', '#B1E8D4', '#EAC7AA', '#EFE29C'];

    const activitiesColors: IActivity[] = [];

    activities.forEach((activity, index) => {
      const radomIndex = Math.floor(Math.random() * colors.length);
      if (
        !(
          activitiesColors[index - 1] &&
          activitiesColors[index - 1].color === colors[radomIndex]
        )
      ) {
        activitiesColors.push({
          name: activity,
          color: colors[radomIndex],
        });
      } else if (colors[radomIndex + 1]) {
        activitiesColors.push({
          name: activity,
          color: colors[radomIndex + 1],
        });
      } else if (colors[radomIndex - 1]) {
        activitiesColors.push({
          name: activity,
          color: colors[radomIndex - 1],
        });
      }
    });

    return activitiesColors;
  }, []);

  useEffect(() => {
    if (Object.keys(nursery).length > 0) {
      setLoading(true);
      api
        .get<IProjectResponse[]>(`projects/nurseries/${nursery.id}`)
        .then((response) => {
          const data = response.data.map<IProject>((project) => {
            let activities: IActivity[] | undefined;
            if (project.activities) {
              activities = linkActivitiesWithColors(
                project.activities.split('; ')
              );
            }

            return {
              id: project.id,
              title: project.title,
              content: project.content,
              activities,
              archive: project.archive.archive_url,
            };
          });

          setProjects(data);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [nursery, linkActivitiesWithColors]);

  return (
    <Container>
      <div className="container pt-5">
        <div className="row">
          <div className="col-12">
            <h1 className="text-secondary mb-5">Projetos</h1>
            {projects.length > 0 ? (
              <>
                {projects.map((project) => (
                  <div key={project.id} className="box py-5 px-3 p-lg-5 mb-4">
                    <div className="row justify-content-between align-items-center">
                      <div className="col-lg-6">
                        <p className="h5 h4-lg text-secondary">Projeto</p>
                        <h2 className="h4 h3-lg fw-semibold text-secondary mb-4 mt-2">
                          {project.title}
                        </h2>
                        <img
                          src={project.archive}
                          alt={project.title}
                          className="w-100 d-block d-lg-none mb-4"
                        />
                        <p className="mb-0 fs-lg-4">{project.content}</p>
                      </div>
                      <div className="col-lg-5 d-none d-lg-block">
                        <img
                          src={project.archive}
                          alt={project.title}
                          className="w-100"
                        />
                      </div>
                      {project.activities && (
                        <div className="col-12 mt-5">
                          <h3 className="h5 h3-lg mb-3 text-secondary">
                            Atividades
                          </h3>
                          <div className="d-flex flex-wrap activities">
                            {project.activities.map((activity, index) => (
                              <Activity
                                key={index.toString()}
                                bgcolor={activity.color}
                              >
                                {activity.name}
                              </Activity>
                            ))}
                            <button
                              type="button"
                              className="border-0 btn-see-more rounded-pill"
                            >
                              Ver mais
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </>
            ) : (
              <div className="mb-5">
                <NoData message="Nenhum projeto cadastrado" />
              </div>
            )}
          </div>
        </div>
      </div>
      <Loading active={loading} color="#00a189" />
    </Container>
  );
};

export default Projects;
