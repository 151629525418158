import styled from 'styled-components';

export const Container = styled.div``;

export const Content = styled.div`
  width: 100%;

  @media screen and (min-width: 992px) {
    width: 334px;
    width: calc(100% - 328px);
    padding: 0 1.5rem;

    .pages-supplier {
      position: absolute;
      bottom: 0px;
      left: 50%;
    }
  }
`;
