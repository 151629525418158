import styled from 'styled-components';

export const Container = styled.div`
  h2 {
    color: #202020;
  }

  p {
    color: #707070;
  }

  .bg-manual a {
    color: #4a4747;
  }

  .bg-manual {
    border-radius: 16px;
    border: 1px solid #cacaca;
    background: #eaeaea;
  }
`;
