import styled from 'styled-components';

interface IAvatar {
  src: string;
}

interface IImg {
  src: string;
}

export const Container = styled.div`
  border-radius: 25px;
  border: 1px solid #e9e9e9;
  background: #fff;
  box-shadow: 0px 4px 18px 0px rgba(0, 0, 0, 0.1);

  video {
    height: 290px;
  }

  .instagram-carousel {
    .slick-list {
      border-radius: 0;
    }

    .slick-prev {
      left: 10px;
      z-index: 1;
    }

    .slick-next {
      right: 10px;
      z-index: 1;
    }

    .slick-dots {
      bottom: 20px;
      li {
        margin: 0;

        button {
          padding: 0;

          :before {
            color: rgba(255, 255, 255);
          }
        }
      }

      .slick-active {
        button {
          :before {
            color: #fff;
          }
        }
      }
    }
  }

  .btn-chat {
    svg {
      transform: rotateY(180deg);
    }
  }
`;

export const Avatar = styled.div<IAvatar>`
  width: 32px;
  height: 32px;
  background-color: #d3d3d3;
  background-image: url(${(props) => props.src});
  background-position: center;
  background-size: cover;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
`;

export const Img = styled.div<IImg>`
  width: 100%;
  height: 290px;
  background-color: #d3d3d3;
  background-image: url(${(props) => props.src});
  background-position: center;
  background-size: cover;
`;
