import React, { useCallback, useMemo, useRef } from 'react';
import { Form } from '@unform/web';
import { Link, useLocation } from 'react-router-dom';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import Swal from 'sweetalert2';

import getValidationErros from '~/utils/getValidationsErrors';

import { Container } from './styles';
import Input from '~/components/Input';

import logo from '~/assets/logos/logo.svg';
import signinBackground from '~/assets/banners/signin-background.svg';
import { useAuth } from '~/hooks/Auth';

const SignIn: React.FC = () => {
  const location = useLocation();
  const formRef = useRef<FormHandles>(null);
  const { signIn } = useAuth();

  const supplier = useMemo(
    () => location.pathname.includes('fornecedor'),
    [location.pathname]
  );

  const handleSubmit = useCallback(
    async (data) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          email: Yup.string()
            .email('Insira um e-mail válido')
            .required('O e-mail é obrigatório'),
          password: Yup.string().required('A senha é obrigatória'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        signIn(data);
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          formRef.current?.setErrors(errors);
        } else {
          console.log('');
          console.log('SignIn');
          console.log(error);
          Swal.fire('Oops...', 'Ocorreu um erro tente novamente, por favor');
        }
      }
    },
    [signIn]
  );

  return (
    <Container>
      <div className="container py-5">
        <div className="row justify-content-between align-items-center">
          <div className="col-lg-5 px-4 px-lg-3">
            <Link
              to={`${process.env.PUBLIC_URL}/${
                supplier ? 'fornecedor' : 'prestador'
              }/login`}
              className="d-flex d-lg-none align-items-center logo mb-5"
            >
              <img src={logo} alt="logo" className="me-2" />
              <span className="text-secondary text-start d-block small mb-0 fw-bold">
                INSTITUTO <br />
                <span className="d-block h4 mb-0 fw-bold mt-n1">BARUERI</span>
              </span>
            </Link>
            <div className="">
              <p className="h5 fw-light text-secondary">Bem-vindo!</p>
              <h1 className="h4 h3-lg text-secondary">
                Para entrar, insira suas credênciais de{' '}
                {supplier ? 'fornecedor' : 'prestador'}
              </h1>
            </div>
            <Form ref={formRef} onSubmit={handleSubmit} className="mt-5">
              <label className="d-block w-100 mb-3">
                <span className="text-secondary fw-medium mb-2 d-block">
                  E-mail
                </span>
                <Input
                  type="email"
                  name="email"
                  placeholder="Insira seu e-mail"
                />
              </label>
              <label className="d-block w-100 mb-3">
                <span className="text-secondary fw-medium mb-2 d-block">
                  Senha
                </span>
                <Input
                  type="password"
                  name="password"
                  placeholder="Digite sua senha"
                />
              </label>
              <div className="d-flex justify-content-end">
                <Link
                  to={`${process.env.PUBLIC_URL}/${
                    supplier ? 'fornecedor' : 'prestador'
                  }/esqueci-minha-senha`}
                  className="text-primary"
                >
                  Esqueci minha senha
                </Link>
              </div>
              <button
                type="submit"
                className="btn btn-primary rounded-pill w-100 py-2 mt-5"
              >
                Entrar
              </button>
              <p className="text-center mt-5">
                Não possui cadastro?{' '}
                <Link
                  to={`${process.env.PUBLIC_URL}/${
                    supplier ? 'fornecedor' : 'prestador'
                  }/cadastro`}
                  className="fw-semibold text-primary"
                >
                  Cadastre-se
                </Link>
              </p>
            </Form>
          </div>
          <div className="col-lg-5 d-none d-lg-block">
            <img src={signinBackground} alt="" className="w-100" />
          </div>
        </div>
      </div>
    </Container>
  );
};

export default SignIn;
