import React, { useCallback, useEffect, useState } from 'react';
import { TiArrowRight } from 'react-icons/ti';
import { RxDashboard } from 'react-icons/rx';
import { CiBoxList } from 'react-icons/ci';
import { format, parseISO } from 'date-fns';

import { useParams } from 'react-router-dom';
import api from '~/services/api';

import { IProcess } from '..';

import { Container } from './styles';
import Loading from '~/components/Loading';
import NoData from '~/components/NoData';
import { useResize } from '~/hooks/Resize';
import InfiniteScroll from '~/components/InfiniteScroll';

interface IParams {
  type: string;
  nursery: string;
}

interface IProcessNursery {
  process: IProcess;
}

interface IProcessResponse {
  data: IProcessNursery[];
  from: number;
  to: number;
  total: number;
  last_page: number;
}

interface IProcessProgress {
  onSelectProcess(process_id: number): void;
}

const ProcessProgress: React.FC<IProcessProgress> = ({ onSelectProcess }) => {
  const params = useParams<IParams>();
  const [grad, setGrad] = useState<boolean>();
  const { width } = useResize();
  const [processes, setProcesses] = useState<IProcess[]>([]);
  const [pageSelected, setPageSelected] = useState(0);
  const [total, setTotal] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(true);
  const [nurseryId, setNurseryId] = useState(0);

  const handleLoadProcesses = useCallback(
    async (page, nursery) => {
      const response = await api.get<IProcessResponse>(
        `processes-nurseries/nurseries/${nursery}`,
        {
          params: {
            status: 'Em andamento',
            page,
            isSupplier: params.type === 'fornecedor',
          },
        }
      );

      const data = response.data.data.map((processNursery) => ({
        id: processNursery.process.id,
        title: processNursery.process.title,
        status: processNursery.process.status,
        created_at: format(
          parseISO(processNursery.process.created_at),
          'dd/MM/yyyy'
        ),
        archives: [],
      }));

      if (page === 1) {
        setProcesses(data);
      } else {
        setProcesses((state) => [...state, ...data]);
      }
      setPageSelected(page);
      setTotal(response.data.total);
      setTotalPages(response.data.last_page);
    },
    [params.type]
  );

  useEffect(() => {
    api
      .get(`nurseries/${params.nursery}`)
      .then(async (response) => {
        await handleLoadProcesses(1, response.data.id);
        setNurseryId(response.data.id);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [handleLoadProcesses, params.nursery]);

  useEffect(() => {
    if (width > 991) {
      setGrad(true);
    } else {
      setGrad(false);
    }
  }, [width]);

  const handleLoad = useCallback(async () => {
    if (pageSelected < totalPages) {
      await handleLoadProcesses(pageSelected + 1, nurseryId);
    }
  }, [handleLoadProcesses, nurseryId, pageSelected, totalPages]);

  return (
    <Container>
      <h2 className="h5 h3-lg fw-600 mb-4">Processos em Andamento</h2>

      <div className="d-flex justify-content-between align-items-center mb-5">
        <p className="process-qtd px-3 py-2 mb-0">
          Quantidade: <b>{total.toString().padStart(2, '0')}</b> Processos
        </p>
        <div className="d-none d-lg-flex align-items-center exibition">
          <p className="mb-0">Tipo de exibição:</p>
          <button
            type="button"
            title="Ícones"
            className={`${grad && 'grad-list-active'} btn-grad-list ms-3`}
            onClick={() => setGrad(true)}
          >
            <RxDashboard size={20} />
          </button>
          <button
            type="button"
            title="Lista"
            className={`${!grad && 'grad-list-active'} btn-grad-list ms-2`}
            onClick={() => setGrad(false)}
          >
            <CiBoxList size={20} />
          </button>
        </div>
      </div>
      {processes.length > 0 ? (
        <>
          {grad ? (
            <InfiniteScroll
              onInfiniteLoad={handleLoad}
              direction="horizontal"
              className="d-flex overflow-auto pb-2 progress-btns"
            >
              {processes.map((processData) => (
                <button
                  key={processData.id}
                  type="button"
                  className="border-0 bg-transparent text-start mx-2 btn-more"
                  onClick={() => onSelectProcess(processData.id)}
                >
                  <div className="box-process">
                    <div className="d-flex justify-content-end px-4 pt-4">
                      <p className="fw-medium text-secondary tag rounded-pill px-3 py-1">
                        Em andamento
                      </p>
                    </div>
                    <div className="pt-5 px-4 pb-4">
                      <p className="text-white h5 fw-semibold">
                        {processData.title}
                      </p>
                    </div>
                    <div className="d-flex align-items-center px-4 py-3 see-process">
                      <p className="text-white mb-0 me-2 fw-600">Ver mais</p>
                      <div>
                        <TiArrowRight size={20} color="#fff" />
                      </div>
                    </div>
                  </div>
                </button>
              ))}
            </InfiniteScroll>
          ) : (
            <InfiniteScroll
              onInfiniteLoad={handleLoad}
              className="mt-4 quotations pe-lg-2"
            >
              {processes.map((processData) => (
                <button
                  key={processData.id}
                  type="button"
                  className="w-100 d-flex flex-column flex-lg-row justify-content-between align-items-lg-center quotation-box py-3 px-3 px-lg-4"
                  onClick={() => onSelectProcess(processData.id)}
                >
                  <div className="d-flex title order-1 order-lg-0 my-4 my-lg-0">
                    <div className="d-none d-lg-flex detail me-2" />
                    <p className="h6 mb-0 fw-600 py-2 ">{processData.title}</p>
                  </div>
                  <div className="d-flex order-0 order-lg-1 justify-content-lg-end tag-box mx-lg-3">
                    <p className="mb-0 px-3 py-1 mb-0 text-secondary tag open">
                      Em andamento
                    </p>
                  </div>
                  <div className="btn order-2 rounded-pill btn-see-more py-2 px-4 d-flex justify-content-center align-items-center">
                    <p className="mb-0 me-2 text-white fw-semibold">Ver mais</p>
                    <div>
                      <TiArrowRight size={20} color="#fff" />
                    </div>
                  </div>
                </button>
              ))}
            </InfiniteScroll>
          )}
        </>
      ) : (
        <div className="mb-5 no-data">
          <NoData message="Nenhum processo em andamento" />
        </div>
      )}
      <Loading active={loading} />
    </Container>
  );
};

export default ProcessProgress;
