import styled from 'styled-components';
import { Link } from 'react-router-dom';

interface IBanner {
  src: string;
}

export const Container = styled.div`
  font-family: 'Poppins';

  h1 {
    margin-top: 122px;
    color: #202020;
  }

  p {
    color: #707070;
  }

  .arrow {
    border-radius: 33px;
    background: #e7e7e7;
    height: 32px;
    width: 32px;
    img {
      width: 15px;
      height: 12px;
    }
  }

  @media screen and (min-width: 992px) {
    .arrow {
      height: 38px;
      width: 38px;
    }

    h1 {
      margin-top: 22px;
    }
  }

  @media screen and (min-width: 1400px) {
    h1 {
      margin-top: 122px;
    }
  }
`;

export const BannerMaternal = styled(Link)<IBanner>`
  background: linear-gradient(
      180deg,
      rgba(152, 152, 152, 0) 0%,
      rgba(11, 40, 43, 0.9) 100%
    ),
    url(${(props) => props.src});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 27px;
  height: 70vw;
  max-height: 450px;

  @media screen and (min-width: 992px) {
    height: 26vw;
  }
`;
