import React from 'react';

import { BsFillTelephoneFill } from 'react-icons/bs';
import { TbMapPinFilled } from 'react-icons/tb';
import { Container } from './styles';

import { useNursery } from '~/hooks/Nursery';
import mask from '~/utils/mask';

const Notice: React.FC = () => {
  const { nursery } = useNursery();

  return (
    <Container className="py-5">
      {Object.keys(nursery).length > 0 && (
        <div className="container py-5">
          <div className="row">
            <div className="col-12">
              <div className="box px-3 py-4 p-md-5">
                <div className="row px-lg-5">
                  <div className="col-lg-6 col-xl-5 col-xxl-4 py-5">
                    <div className="">
                      <h1 className="h6 fw-normal">Contato</h1>
                      <h2 className="h1 fw-semibold text-secondary">
                        Fale <br />
                        conosco
                      </h2>
                    </div>
                    <div className="mt-5">
                      <p className="my-5">
                        <div className="d-flex align-items-center">
                          <BsFillTelephoneFill
                            size={24}
                            color="#202020"
                            className="me-2"
                          />
                          <span>
                            <a href={`tel:${nursery.primary_phone}`}>
                              {mask({
                                kind: 'cel-phone',
                                value: nursery.primary_phone.replace('+55', ''),
                              })}
                            </a>
                            {nursery.secondary_phone && (
                              <>
                                {' '}
                                -{' '}
                                <a href={`tel:${nursery.secondary_phone}`}>
                                  {mask({
                                    kind: 'cel-phone',
                                    value: nursery.secondary_phone.replace(
                                      '+55',
                                      ''
                                    ),
                                  })}
                                </a>
                              </>
                            )}
                          </span>
                        </div>
                      </p>
                      <p className="d-block">
                        <div className="d-flex align-items-center">
                          <TbMapPinFilled
                            size={24}
                            color="#202020"
                            className="me-2"
                          />
                          <span>
                            {nursery.address.street}, {nursery.address.number} -{' '}
                            {nursery.address.complement
                              ? `${nursery.address.complement} - `
                              : ''}
                            {nursery.address.neighborhood} -{' '}
                            {nursery.address.city}, {nursery.address.state}{' '}
                            <br className="d-none d-lg-block" /> CEP:{' '}
                            {nursery.address.zip_code}
                          </span>
                        </div>
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6 col-xl-7 col-xxl-8 mt-4 mt-lg-0">
                    <div className="img" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Container>
  );
};

export default Notice;
