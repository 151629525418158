import React, { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import api from '~/services/api';

import { BannerMaternal, Container } from './styles';

import next from '~/assets/icons/next_arrow.svg';
import NoData from '~/components/NoData';

interface INurseryResponse {
  id: number;
  name: string;
  slug: string;
  banner: {
    archive_url: string;
  };
}

interface INurseryData {
  data: INurseryResponse[];
  from: number;
  to: number;
  total: number;
  current_page: number;
}

export interface INursery {
  id: number;
  name: string;
  slug: string;
  banner: string;
}

interface INurseriesProps {
  page: string;
}

const Nurseries: React.FC<INurseriesProps> = ({ page }) => {
  const location = useLocation();
  const [nurseries, setNurseries] = useState<INursery[]>([]);
  const [loading, setLoading] = useState(true);

  const type = useMemo(
    () =>
      location.pathname.includes('fornecedor') ? 'fornecedor' : 'prestador',
    [location.pathname]
  );

  useEffect(() => {
    api
      .get<INurseryData>('nurseries')
      .then((response) => {
        const data = response.data.data.map((nursery) => ({
          id: nursery.id,
          name: nursery.name.replace('EMM', ''),
          slug: nursery.slug,
          banner: nursery.banner.archive_url,
        }));
        setNurseries(data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <Container>
      <div className="row">
        {nurseries.length > 0 ? (
          <>
            {nurseries.map((nursery) => (
              <div className="col-lg-6 mb-4 mb-lg-5">
                <BannerMaternal
                  src={nursery.banner}
                  to={`${process.env.PUBLIC_URL}/${type}/${page}/${nursery.slug}`}
                  className="d-flex flex-column justify-content-end p-4 p-sm-5"
                >
                  <h5 className="h5 fw-normal text-white mb-0">EMM</h5>
                  <div className="d-flex align-items-center justify-content-between">
                    <h2 className="h4 h3-lg text-white fw-bold mb-0">
                      {nursery.name}
                    </h2>
                    <div className="arrow d-flex align-items-center justify-content-center">
                      <img src={next} alt="arrow" />
                    </div>
                  </div>
                </BannerMaternal>
              </div>
            ))}
          </>
        ) : (
          <div className="col-12 mb-5">
            <NoData message="Nenhuma maternal cadastrada" />
          </div>
        )}
      </div>
    </Container>
  );
};

export default Nurseries;
