import styled from 'styled-components';

interface IImg {
  src: string;
}

export const Container = styled.div`
  margin-top: 76px;

  @media screen and (min-width: 992px) {
    margin-top: 0;
  }
`;

export const Img = styled.button<IImg>`
  background-image: url(${(props) => props.src});
  background-position: center;
  background-size: cover;
  border-radius: 20px;
  width: 100%;
  height: 130px;
  border: none;

  @media screen and (min-width: 992px) {
    height: 282px;
  }
`;
