import styled, { css } from 'styled-components';

interface IContainer {
  isHome: boolean;
}

interface IMenuProps {
  active: boolean;
  scroll: number;
}

export const Container = styled.header<IContainer>`
  top: 0;
  background: transparent;
  z-index: 100;

  a {
    font-family: 'Poppins';
    text-decoration: none;
    :hover {
      color: initial;
    }
  }

  .logo img {
    width: 45px;
    height: 45px;
  }

  .text-logo {
    color: #202020;
  }

  .menu-fix {
    width: 100%;
    height: 76px;
    position: fixed;
    z-index: 1000;
    transition-duration: 0.3s;
    top: 0;

    //border: 1px solid #f2f5fa;
  }

  .shadow-menu-desk {
    box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.1);
  }

  img {
    transition: 0.3s;
    height: 30px;
  }

  @media screen and (min-width: 992px) {
    height: ${(props) => (props.isHome ? 'unset' : '130px')};

    .menu-fix {
      width: 100%;
      height: 100px;
      border-radius: 0px 0px 16px 16px;
    }

    .logo img {
      width: 63px;
      height: 63px;
    }

    img {
      height: 50px;
    }
  }

  @media screen and (min-width: 1400px) {
    .menu-fix {
      height: 130px;
    }
  }
`;

export const Menu = styled.div<IMenuProps>`
  > button {
    width: 25px;
    height: 25px;

    span {
      display: block;
      width: 100%;
      height: 2px;
      background-color: #fff;
      border-radius: 30px;
      transition-duration: 0.5s;

      :nth-child(2) {
        margin: 6px 0px;
      }

      ${(props) =>
        props.active &&
        css`
          :nth-child(1) {
            transform: rotate(225deg);
          }

          :nth-child(2) {
            opacity: 0;
            transform: rotate(225deg);
          }

          :nth-child(3) {
            transform: rotate(315deg);
            margin-top: -16px;
          }
        `}
    }
  }

  .menu-group {
    transition-duration: 0.3s;
    width: 100%;
    position: absolute;
    left: ${(props) => (props.active ? 0 : 1000)}px;
    background-color: ${(props) => (props.active ? '#fff' : 'transparent')};
    min-height: calc(100vh - 65px);
    top: 65px;

    .menu {
      min-height: calc(100vh - 76px);

      .btn-menu {
        transition-duration: 0.3s;
        color: #4b4b4b;
        font-size: 22px;
        position: relative;
        -webkit-text-fill-color: #4b4b4b;
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: transparent;

        :after {
          content: '';
          position: absolute;
          width: 35px;
          height: 4px;
          border-radius: 2px;
          bottom: 0px;
          transition-duration: 0.3s;
        }
      }

      .btn-menu:hover,
      .btn-menu.active {
        color: #db562b;
        -webkit-text-fill-color: #db562b;
        -webkit-text-stroke-color: #db562b;
      }
    }

    .work-us {
      background-color: #998000;
      font-weight: 400;
      font-size: 22px;
    }

    .supplier {
      background-color: #db562b;
      font-weight: 400;
      font-size: 22px;
    }

    .provider {
      font-weight: 400;
      font-size: 22px;
    }
  }

  @media screen and (min-width: 992px) {
    .menu-group {
      width: auto;
      position: unset;
      background-color: transparent;
      min-height: unset;

      .menu {
        min-height: unset;

        .btn-menu {
          color: ${(props) => (props.scroll > 0 ? '#4b4b4b' : '#fff')};
          -webkit-text-fill-color: ${(props) =>
            props.scroll > 0 ? '#4b4b4b' : '#fff'};
          font-size: 12px;
        }

        .btn-menu:hover,
        .btn-menu.active {
          color: ${(props) => (props.scroll > 0 ? '#db562b' : '#fff')};
          -webkit-text-fill-color: ${(props) =>
            props.scroll > 0 ? '#db562b' : '#fff'};
          -webkit-text-stroke-color: ${(props) =>
            props.scroll > 0 ? '#db562b' : '#fff'};

          :after {
            background-color: ${(props) =>
              props.scroll > 0 ? '#db562b' : '#fff'};
          }
        }
      }

      .supplier,
      .provider,
      .work-us {
        font-size: 12px;
      }
    }
  }

  @media screen and (min-width: 1100px) {
    .menu-group .menu .btn-menu {
      font-size: 14px;
    }

    .menu-group .supplier,
    .menu-group .provider,
    .menu-group .work-us {
      font-size: 14px;
    }
  }

  @media screen and (min-width: 1300px) {
    .menu-group .menu .btn-menu {
      font-size: 16px;
    }

    .menu-group .supplier,
    .menu-group .provider,
    .menu-group .work-us {
      font-size: 16px;
    }
  }
`;
