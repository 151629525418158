import React, { useEffect, useMemo, useState } from 'react';
import { MdLogin } from 'react-icons/md';

import { Link, useLocation } from 'react-router-dom';
import api from '~/services/api';

import { BannerMaternal, Container } from './styles';
import Loading from '~/components/Loading';
import NoData from '~/components/NoData';

import next from '~/assets/icons/next_arrow.svg';

interface INurseryResponse {
  id: number;
  name: string;
  slug: string;
  banner: {
    archive_url: string;
  };
}

interface INurseryData {
  data: INurseryResponse[];
  from: number;
  to: number;
  total: number;
  current_page: number;
}

interface INursery {
  id: number;
  name: string;
  slug: string;
  banner: string;
}

interface ITableData {
  from: number;
  to: number;
  total: number;
  current_page: number;
}

const SupplierProvider: React.FC = () => {
  const location = useLocation();
  const [nurseries, setNurseries] = useState<INursery[]>([]);
  const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState<ITableData>({
    from: 0,
    to: 0,
    total: 0,
    current_page: 1,
  });

  const supplier = useMemo(
    () => location.pathname.includes('fornecedor'),
    [location.pathname]
  );

  useEffect(() => {
    api
      .get<INurseryData>('nurseries')
      .then((response) => {
        const data = response.data.data.map((nursery) => ({
          id: nursery.id,
          name: nursery.name.replace('EMM', ''),
          slug: nursery.slug,
          banner: nursery.banner.archive_url,
        }));
        setNurseries(data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <Container>
      <div className="container">
        <div className="row">
          <div className="col-12 px-4 px-lg-3">
            <div className="d-flex justify-content-between align-items-center mb-4 mb-lg-5 mt-5 pt-5">
              <h1 className="h4 h1-lg mb-0">
                Canal do {supplier ? 'Fornecedor' : 'Prestador'}
              </h1>
              <Link
                to={`${process.env.PUBLIC_URL}/${
                  supplier ? 'fornecedor' : 'prestador'
                }/login`}
                className="h6 fw-600 border-0 rounded-pill btn-login mb-0 mx-2 d-flex align-items-center justify-content-center px-3"
              >
                <MdLogin size={24} color="#fff" className="me-2" /> Entrar
              </Link>
            </div>
            <p className="h6 h4-lg fw-light mb-4 mb-lg-5 pb-lg-5">
              Escolha maternal abaixo, analise os processos e envie-nos o seu
              orçamento. Vamos trabalhar juntos para proporcionar a melhor
              experiência aos alunos da escola.
            </p>
          </div>
          {nurseries.length > 0 ? (
            <>
              {nurseries.map((nursery) => (
                <div className="col-lg-6 mb-4 mb-lg-5">
                  <BannerMaternal
                    src={nursery.banner}
                    to={`${process.env.PUBLIC_URL}/canal/${
                      supplier ? 'fornecedor' : 'prestador'
                    }/${nursery.slug}`}
                    className="d-flex flex-column justify-content-end p-4 p-sm-5"
                  >
                    <h5 className="h5 fw-normal text-white mb-0">EMM</h5>
                    <div className="d-flex align-items-center justify-content-between">
                      <h2 className="h4 h3-lg text-white fw-bold mb-0">
                        {nursery.name}
                      </h2>
                      <div className="arrow d-flex align-items-center justify-content-center">
                        <img src={next} alt="arrow" />
                      </div>
                    </div>
                  </BannerMaternal>
                </div>
              ))}
            </>
          ) : (
            <div className="col-12 mb-5">
              <NoData message="Nenhuma maternal cadastrada" />
            </div>
          )}
        </div>
      </div>
      <Loading active={loading} />
    </Container>
  );
};

export default SupplierProvider;
