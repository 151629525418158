import styled from 'styled-components';

interface IActivity {
  bgcolor: string;
}

export const Container = styled.div`
  margin-top: 76px;

  .box {
    border-radius: 20px;
    background: #f9f9f9;

    img {
      border-radius: 20px;
    }

    .activities {
      margin-left: -7.5px;
      margin-right: -7.5px;
    }

    .btn-see-more {
      background-color: #d1d1d0;
      color: #202020;
      padding: 10px 20px;
      margin: 7.5px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
    }
  }

  @media screen and (min-width: 992px) {
    margin-top: 0;

    .btn-see-more {
      font-size: 18px;
    }
  }
`;

export const Activity = styled.p<IActivity>`
  background-color: ${(props) => props.bgcolor};
  color: #202020;
  border-radius: 100px;
  padding: 10px 20px;
  margin: 7.5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;

  @media screen and (min-width: 992px) {
    font-size: 18px;
  }
`;
