import React, { useEffect, useMemo, useState } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { FiHome } from 'react-icons/fi';
import { IoLockOpenOutline } from 'react-icons/io5';
import { TbClockHour12, TbLogout, TbUser } from 'react-icons/tb';
import { RxCheckCircled } from 'react-icons/rx';

import { WhiteSpace, Container, Menu, Avatar } from './styles';

import logo from '~/assets/logos/logo.svg';
import { useAuth } from '~/hooks/Auth';

const SideNavbar: React.FC = () => {
  const location = useLocation();
  const { user, signOut } = useAuth();
  const [active, setActive] = useState(false);

  useEffect(() => {
    setActive(false);
  }, [location.pathname]);

  const type = useMemo(
    () =>
      location.pathname.includes('fornecedor') ? 'fornecedor' : 'prestador',
    [location.pathname]
  );

  return (
    <>
      <WhiteSpace className="d-block d-lg-none" />
      <Container className="px-lg-4 py-lg-5 d-flex flex-column">
        <div className="px-4 py-3 p-lg-0 d-flex align-items-center justify-content-between justify-content-lg-center">
          <Link
            to={`${process.env.PUBLIC_URL}/${type}/dashboard`}
            className="d-flex align-items-center justify-content-center logo"
          >
            <img src={logo} alt="logo" className="me-2" />
            <span className="text-secondary text-start d-block h6 mb-0 fw-bold">
              INSTITUTO <br />
              <span className="d-block h4 mb-0 fw-bold">BARUERI</span>
            </span>
          </Link>

          <button
            type="button"
            className={`bg-transparent d-lg-none border-0 mx-3 btn-menu ${
              active ? 'active' : ''
            }`}
            onClick={() => setActive(!active)}
          >
            <span />
            <span />
            <span />
          </button>
        </div>
        <hr className="d-none d-lg-block my-lg-4" />
        <Menu active={active} className="p-3 p-lg-0">
          <div>
            <NavLink
              to={`${process.env.PUBLIC_URL}/${type}/dashboard`}
              className="d-flex align-items-center p-3 mb-4 item-menu"
              activeClassName="active"
            >
              <FiHome size={20} className="me-3" /> <span>Dashboard</span>
            </NavLink>
            <NavLink
              to={`${process.env.PUBLIC_URL}/${type}/processos-abertos`}
              className="d-flex align-items-center p-3 mb-4 item-menu"
              activeClassName="active"
            >
              <IoLockOpenOutline size={20} className="me-3" />{' '}
              <span>Processos Abertos</span>
            </NavLink>
            <NavLink
              to={`${process.env.PUBLIC_URL}/${type}/processos-em-andamento`}
              className="d-flex align-items-center p-3 mb-4 item-menu"
              activeClassName="active"
            >
              <TbClockHour12 size={20} className="me-3" />{' '}
              <span>Processos em Andamento</span>
            </NavLink>
            <NavLink
              to={`${process.env.PUBLIC_URL}/${type}/processos-finalizados`}
              className="d-flex align-items-center p-3 mb-4 item-menu"
              activeClassName="active"
            >
              <RxCheckCircled size={20} className="me-3" />{' '}
              <span>Processos Finalizados</span>
            </NavLink>
            <NavLink
              to={`${process.env.PUBLIC_URL}/${type}/perfil`}
              className="d-flex align-items-center p-3 mb-4 item-menu"
              activeClassName="active"
            >
              <TbUser size={20} className="me-3" /> <span>Perfil</span>
            </NavLink>
          </div>
          <div className="mb-4">
            <div className="box-user d-flex flex-column align-items-center py-4">
              <Avatar src={user.avatar.avatar_url} />
              <p className="h4 text-secondary mt-3 mb-2 fw-semibold">
                {user.president_name.split(' ')[0]}
              </p>
              <p className="mb-0">{user.company}</p>
            </div>
          </div>
          <div>
            <button
              type="button"
              className="btn text-start d-flex align-items-center p-3 item-menu w-100"
              onClick={() => signOut()}
            >
              <TbLogout size={20} className="me-3" /> <span>Sair</span>
            </button>
          </div>
        </Menu>
      </Container>
    </>
  );
};

export default SideNavbar;
