import React, { createContext, useState, useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import api from '../services/api';

import Loading from '~/components/Loading';

interface IAddress {
  street: string;
  number: string;
  neighborhood: string;
  city: string;
  state: string;
  zip_code: string;
  complement: string;
}

interface INursery {
  id: string;
  name: string;
  email: string;
  primary_phone: string;
  secondary_phone: string;
  story: string;
  slug: string;
  archives: {
    archive_url: string;
  }[];
  address: IAddress;
}

interface NurseryContextData {
  nursery: INursery;
}

export const NurseryContext = createContext<NurseryContextData>(
  {} as NurseryContextData
);

export const NurseryProvider: React.FC = ({ children }) => {
  const location = useLocation();
  const [nursery, setNursery] = useState({} as INursery);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const pathParts = location.pathname.split('/');
    const slug = pathParts[1] === 'novo-site' ? pathParts[3] : pathParts[2];
    if (
      location.pathname.includes('maternais') &&
      slug &&
      (Object.keys(nursery).length === 0 || nursery.slug !== slug)
    ) {
      setLoading(true);
      api
        .get<INursery>(`nurseries/${slug}`, {
          params: {
            type: 'aboutUs',
          },
        })
        .then((response) => {
          setNursery(response.data);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [location.pathname, nursery]);

  return (
    <NurseryContext.Provider value={{ nursery }}>
      {children}
      <Loading active={loading} />
    </NurseryContext.Provider>
  );
};

export function useNursery(): NurseryContextData {
  const context = useContext(NurseryContext);

  if (!context) {
    throw new Error('useNursery must be used within an NurseryProvider');
  }

  return context;
}
