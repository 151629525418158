import React, {
  createContext,
  useCallback,
  useState,
  useContext,
  useEffect,
} from 'react';
import Swal from 'sweetalert2';
import { useHistory, useLocation } from 'react-router-dom';

import { fi } from 'date-fns/locale';
import api from '../services/api';

import defaultAvatar from '~/assets/defaults/avatar-default.svg';

interface IAddress {
  id: number;
  street: string;
  number: string;
  neighborhood: string;
  city: string;
  state: string;
  zip_code: string;
  complement?: string;
}

interface IArchives {
  id: number;
  type: string;
  name: string;
  archive_url: string;
}

export interface User {
  id: number;
  president_name: string;
  phone: string;
  email: string;
  rg: string;
  cpf: string;
  company: string;
  cnpj: string;
  cnae: string;
  is_provider: boolean;
  avatar: {
    id?: number;
    avatar_url: string;
  };
  address: IAddress;
  archives: IArchives[];
}

interface AuthStateResponse {
  token: string;
  supplier: User;
}

interface AuthState {
  token: string;
  user: User;
}

interface SignInCredentials {
  email: string;
  password: string;
}

interface AuthContextData {
  user: User;
  signIn(credentials: SignInCredentials): Promise<void>;
  signOut(): void;
  updateUser(user: User): void;
}

export const AuthContext = createContext<AuthContextData>(
  {} as AuthContextData
);

export const AuthProvider: React.FC = ({ children }) => {
  const history = useHistory();
  const location = useLocation();
  const [data, setData] = useState<AuthState>(() => {
    const token = localStorage.getItem('@IntitutoBarueri:token');
    const user = localStorage.getItem('@IntitutoBarueri:user');

    if (token && user) {
      api.defaults.headers.authorization = `Bearer ${token}`;
      return { token, user: JSON.parse(user) };
    }

    return {} as AuthState;
  });

  useEffect(() => {
    if (data.user) {
      const path = location.pathname.split('/');
      const restPath = path.slice(2).join('/');
      data.user.is_provider &&
        path[1] === 'fornecedor' &&
        history.push(`${process.env.PUBLIC_URL}/prestador/${restPath}`);
      !data.user.is_provider &&
        path[1] === 'prestador' &&
        history.push(`${process.env.PUBLIC_URL}/fornecedor/${restPath}`);
    }
  }, [data, history, location.pathname]);

  const signIn = useCallback(
    async ({ email, password }: SignInCredentials) => {
      try {
        const response = await api.post<AuthStateResponse>(
          `suppliers/sessions`,
          {
            email,
            password,
            isProvider: location.pathname.includes('prestador'),
          }
        );

        const { token, supplier } = response.data;

        const user = {
          ...supplier,
          avatar: supplier.avatar || {
            avatar_url: defaultAvatar,
          },
        };

        localStorage.setItem('@IntitutoBarueri:token', token);
        localStorage.setItem('@IntitutoBarueri:user', JSON.stringify(user));

        api.defaults.headers.authorization = `Bearer ${token}`;

        setData({
          token,
          user: {
            ...supplier,
            avatar: supplier.avatar || {
              avatar_url: defaultAvatar,
            },
          },
        });
      } catch (error) {
        Swal.fire('Opss...', 'Código de autenticação inválido', 'error');
      }
    },
    [location.pathname]
  );

  const signOut = useCallback(async () => {
    localStorage.removeItem('@IntitutoBarueri:token');
    localStorage.removeItem('@IntitutoBarueri:user');

    setData({} as AuthState);

    const type = location.pathname.includes('fornecedor')
      ? 'fornecedor'
      : 'prestador';

    history.push(`${process.env.PUBLIC_URL}/${type}/login`);
  }, [history, location.pathname]);

  const updateUser = useCallback(
    (user) => {
      localStorage.setItem('@IntitutoBarueri:user', JSON.stringify(user));

      setData({
        token: data.token,
        user,
      });
    },
    [setData, data.token]
  );

  return (
    <AuthContext.Provider
      value={{ user: data.user, signIn, signOut, updateUser }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}
