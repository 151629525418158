import styled from 'styled-components';

interface IBanner {
  src: string;
}

export const Container = styled.div`
  margin-top: 122px;
  h1 {
    span {
      color: #707070;
    }
  }
  .box {
    border-radius: 16px;
    border: 1px solid #e8e8e8;
    background: #f9f9f9;

    p {
      color: #707070;
      font-size: 16px;
      font-weight: 300;
      margin-bottom: 16px;
      margin-top: 10px;
    }

    img {
      width: 30%;
      border-radius: 8px;
    }
  }

  @media screen and (min-width: 992px) {
    margin-top: 22px;
    .box p {
      font-size: 24px;
      margin-bottom: 62px;
      margin-top: 20px;
    }
  }

  @media screen and (min-width: 1400px) {
    margin-top: 122px;
  }
`;

export const Banner = styled.div<IBanner>`
  background-image: url(${(props) => props.src});
  background-position: center;
  background-size: cover;
  border-radius: 8px;
  width: 100%;
  height: 350px;
  border: none;

  @media screen and (min-width: 992px) {
    height: 440px;
  }
`;
