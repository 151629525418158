import styled from 'styled-components';

export const Container = styled.div`
  h2 {
    color: #202020;
  }

  button.btn-more {
    min-width: 335px;
    max-width: 335px;
  }

  p {
    color: #4a4747;
  }

  .btn-grad-list {
    border-radius: 100px;
    border: 1px solid #d2d1d1;
    background: #eaeaea;
    width: 40px;
    height: 40px;
    svg {
      color: #707070;
    }
  }

  .grad-list-active {
    border: 1px solid #202020;
    background: #3f3f3f;
    svg {
      color: #fff;
    }
  }

  .process-qtd {
    border-radius: 100px;
    border: 1px solid #e8e8e8;
    background: #eaeaea;
  }

  .box-process {
    border-radius: 32px;
    overflow: hidden;
    background: #00a189;

    .tag {
      background: #c5f0ea;
    }

    .see-process {
      background: #066456;

      > div {
        background: #489489;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .quotations {
    height: 300px;
    overflow: auto;

    ::-webkit-scrollbar-thumb {
      background-color: #c6c6c6 !important;
      border-radius: 4px;
    }
    ::-webkit-scrollbar {
      background: #f3f3f3;
      width: 5px;
      height: 5px;
    }

    .quotation-box {
      border-radius: 13px;
      border: 1px solid #d7d7d7;

      .detail {
        width: 8px;

        border-radius: 16px;
        background: #00a189;
      }

      .title {
        width: auto;
      }

      .tag-box {
        white-space: nowrap;

        .tag {
          background: #c5f0ea;
          border-radius: 100px;
        }
      }

      .btn-see-more {
        white-space: nowrap;
        border-radius: 40px;
        background: #066456;
        width: fit-content;

        > div {
          background: #489489;
          width: 24px;
          height: 24px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      + .quotation-box {
        margin-top: 13px;
      }
    }
  }

  .btn-pagination {
    display: flex;
    width: 64px;
    height: 64px;
    padding: 20px;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    border: 1px solid transparent;
    font-size: 18px;
    font-weight: 700;

    + .btn-pagination {
      margin-left: 20px;
    }
  }

  .btn-pagination.arrow,
  .btn-pagination.selected,
  .btn-pagination:not(.ellipsis):hover {
    border: 1px solid #707070;
  }

  .progress-btns {
    ::-webkit-scrollbar-thumb {
      background-color: #c6c6c6 !important;
      border-radius: 4px;
    }
    ::-webkit-scrollbar {
      background: #f3f3f3;
      width: 5px;
      height: 5px;
    }
  }

  .no-data {
    > div {
      padding: 50px 0;

      h2 {
        color: #b4bccc;
      }
    }
  }

  @media screen and (min-width: 992px) {
    .quotations .quotation-box .title {
      width: 320px;
    }
  }
`;
