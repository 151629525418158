import React from 'react';

import { IoDocumentText } from 'react-icons/io5';
import { Container } from './styles';

const PurchaseManual: React.FC = () => {
  return (
    <Container>
      <h2 className="h3 fw-600 mb-4">Manual de compras</h2>
      {/* <p className="h4 fw-light lh-base mb-5 pb-4">
        Lorem Ipsum is simply dummy text of the printing and typesetting
        industry.{' '}
      </p> */}
      <div className="bg-manual py-3 px-4">
        <a href="/" className="h4 fw-normal">
          <IoDocumentText size={24} color="#4A4747" className="mb-1 me-2" />
          Abrir manual
        </a>
      </div>
    </Container>
  );
};

export default PurchaseManual;
