import styled from 'styled-components';
import transparency from '~/assets/defaults/bg-transparency.png';

interface IBanner {
  color: string;
}

export const Container = styled.div`
  font-family: 'Poppins';
  h1 {
    margin-top: 122px;
    color: #202020;
  }
  h2,
  p {
    color: #707070;
  }

  .bg-name {
    border-radius: 16px;
    border: 1px solid #e8e8e8;
    background: #f9f9f9;
  }

  .all::-webkit-scrollbar-thumb {
    background-color: transparent !important;
    border-radius: 4px;
  }

  @media screen and (min-width: 992px) {
    h1 {
      margin-top: 22px;
    }
  }

  @media screen and (min-width: 1400px) {
    h1 {
      margin-top: 122px;
    }
  }
`;

export const BannerTrasparency = styled.div<IBanner>`
  background: url(${transparency}),
    linear-gradient(
      180deg,
      ${(props) => props.color} 0%,
      ${(props) => props.color} 100%
    );
  /* background: ${(props) => props.color}; */
  background-size: cover;
  background-position: bottom center;
  background-repeat: no-repeat;
  border-radius: 8px;
  height: 26vw;
  width: 100%;
  min-height: 205px;
  max-height: 225px;
  margin-left: 10px;
  margin-right: 10px;

  @media screen and (min-width: 992px) {
    width: 31%;
    margin-left: 10px;
    margin-right: 10px;
  }
`;
