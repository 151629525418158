import React, { useCallback, useEffect, useState } from 'react';
import { ImagesListType } from 'react-spring-lightbox';
import { BiLeftArrowAlt, BiRightArrowAlt } from 'react-icons/bi';
import { AiOutlineClose } from 'react-icons/ai';

import { Container, Header, BoxIcon } from './styles';

interface IGallery {
  show: boolean;
  photos: ImagesListType;
  onClose(): void;
  currentIndex?: number;
}

const GalleryModal: React.FC<IGallery> = ({
  show,
  photos,
  onClose,
  currentIndex,
}) => {
  const [index, setIndex] = useState(0);

  useEffect(() => {
    if (currentIndex) {
      setIndex(currentIndex);
    }
  }, [currentIndex]);

  const handlePrev = useCallback(() => {
    index > 0 ? setIndex(index - 1) : setIndex(photos.length - 1);
  }, [index, photos.length]);

  const handleNext = useCallback(() => {
    index + 1 < photos.length ? setIndex(index + 1) : setIndex(0);
  }, [index, photos.length]);

  return (
    <Container
      isOpen={show}
      onPrev={handlePrev}
      onNext={handleNext}
      images={photos}
      currentIndex={index}
      onClose={onClose}
      renderHeader={() => (
        <Header>
          <button
            type="button"
            onClick={onClose}
            className="border-0 bg-transparent"
          >
            <AiOutlineClose size={32} color="#fff" />
          </button>
        </Header>
      )}
      renderPrevButton={() => (
        <BoxIcon onClick={handlePrev}>
          <BiLeftArrowAlt size={24} color="#fff" />
        </BoxIcon>
      )}
      renderNextButton={() => (
        <BoxIcon onClick={handleNext}>
          <BiRightArrowAlt size={24} color="#fff" />
        </BoxIcon>
      )}
    />
  );
};

export default GalleryModal;
