import styled from 'styled-components';
import contact from '~/assets/defaults/contact-new.png';

export const Container = styled.div`
  margin-top: 30px;

  .box {
    border-radius: 22px;
    background: #f9f9f9;

    h2 {
      color: #707070;
    }

    a,
    p {
      > div {
        span,
        a {
          font-weight: 500;
          color: #202020;
          width: calc(100% - 35px);
          overflow-wrap: break-word;
          font-size: 20px;
        }
      }
    }

    p {
    }

    .img {
      width: 100%;
      height: 100%;
      background-image: url(${contact});
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      border-radius: 16px;
    }
  }

  @media screen and (min-width: 992px) {
    margin-top: 0;
  }
`;
