import styled from 'styled-components';

interface ITimeline {
  opened: boolean;
}

export const Container = styled.div`
  min-height: calc(100vh - 110px);

  .logo img {
    width: 42px;
    height: 42px;
  }

  .box {
    border-radius: 16px;
    border: 1px solid #e8e8e8;
    background: #f9f9f9;

    h1 {
      color: #202020;
    }

    > p {
      width: 55%;
    }

    form.white-box {
      border-radius: 21px;
      border: 1px solid #d7d7d7;
      background: #fff;

      .form-box {
        width: 80%;
        margin: 0 auto;

        .input-file {
          position: relative;

          .error-box {
            position: absolute;
            bottom: -30px;

            .error {
              font-size: 12px;
            }
          }
        }

        .small-input-file {
          position: relative;

          .error-box {
            position: absolute;
            bottom: -40px;
            height: 36px;

            .error {
              font-size: 12px;
            }
          }
        }
      }
    }

    .btn-gray {
      background: #707070;
      color: #fff;
    }

    .btn-submit {
      background: #ad2d03;
      color: #fff;
    }
  }

  @media screen and (min-width: 991px) {
    min-height: calc(100vh - 70px);

    .logo img {
      width: 63px;
      height: 63px;
    }

    .box {
      form.white-box {
        .form-box {
          width: 60%;

          .input-file {
            .error-box {
              .error {
                font-size: 90%;
              }
            }
          }

          .small-input-file {
            .error-box {
              bottom: -45px;
            }
          }
        }
      }
    }
  }
`;

export const Timeline = styled.div<ITimeline>`
  position: relative;
  width: 80%;
  margin: 0 auto;

  .group-steps {
    transition-duration: 0.3s;
    height: ${(props) => (props.opened ? 305 : 0)}px;
    overflow: hidden;
  }

  .step {
    width: 68px;
    height: 68px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    position: relative;
    border: 11px solid #fff;

    > div {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #ccc;
      background-color: #eee;
      color: #202020;
      transition-duration: 0.3s;
      font-weight: 600;
    }

    .active {
      background-color: #fa764b;
      border-color: #fa764b;
      color: #fff;
    }
  }

  @media screen and (min-width: 768px) {
    width: 75%;

    .group-steps {
      height: unset;
      overflow: auto;
    }

    ::before {
      content: '';
      position: absolute;
      width: 90%;
      height: 1px;
      background: #d9d9d9;
      top: 35%;
      left: 50%;
      transform: translateX(-50%);
      z-index: 0;
    }
  }
`;

export const StepsButton = styled.button`
  border: none;
  background-color: transparent;
`;
