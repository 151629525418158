import styled from 'styled-components';

export const Container = styled.div`
  //height: calc(100vh - 110px);

  .logo img {
    width: 42px;
    height: 42px;
  }

  @media screen and (min-width: 992px) {
    height: unset;
  }
`;
