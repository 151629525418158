/* eslint-disable react/no-danger */
import React, { useEffect, useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import { TiArrowLeft } from 'react-icons/ti';
import { Container, Banner } from './styles';

import api from '~/services/api';
import Loading from '~/components/Loading';

interface IParams {
  slug: string;
  noticeSlug: string;
}

interface INotice {
  id: number;
  title: string;
  body: string;
  created_at: string;
  nursery: {
    name: string;
  };
  thumbnail: {
    archive_url: string;
  };
}

const Notice: React.FC = () => {
  const params = useParams<IParams>();
  const [notice, setNotice] = useState({} as INotice);
  const [loading, setLoading] = useState(true);

  const url = useMemo(() => {
    if (params.slug) {
      return `/maternais/${params.slug}`;
    }

    return '';
  }, [params.slug]);

  useEffect(() => {
    api
      .get<INotice>(`notices/${params.noticeSlug}`)
      .then((response) => {
        setNotice(response.data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [params.noticeSlug]);

  return (
    <Container className="pb-5">
      {Object.keys(notice).length > 0 && (
        <div className="container">
          <div className="box px-3 px-sm-4 py-4 p-lg-5">
            <div className="row justify-content-between align-items-center py-lg-4">
              <div className="col-12 d-lg-flex align-items-center mb-4 mb-lg-5">
                <Link
                  to={`${process.env.PUBLIC_URL}${url}/noticias`}
                  className="me-3"
                >
                  <TiArrowLeft size={52} color="#707070" />
                </Link>
                <h1 className="h4 h2-lg w-100 d-lg-flex align-items-end justify-content-between text-secondary mt-3 mt-lg-0 mb-0">
                  {notice.title}{' '}
                  <span className="h6 mb-1 fw-semibold d-none d-lg-block">
                    {format(parseISO(notice.created_at), 'dd MMM yyyy')}
                  </span>
                </h1>
              </div>
              <div className="col-12 my-2">
                <Banner src={notice.thumbnail.archive_url} className="mb-5" />
                <span className="h6 mb-1 fw-semibold d-lg-none">
                  {format(parseISO(notice.created_at), 'dd MMM yyyy')}
                </span>
                <div dangerouslySetInnerHTML={{ __html: notice.body }} />
              </div>
            </div>
          </div>
        </div>
      )}
      <Loading active={loading} color={params.slug ? '#00a189' : undefined} />
    </Container>
  );
};

export default Notice;
