import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Container, Thumbnail } from './styles';

import { useNursery } from '~/hooks/Nursery';
import api from '~/services/api';
import Loading from '~/components/Loading';
import NoData from '~/components/NoData';

interface IParams {
  slug: string;
}

interface IGallery {
  id: number;
  title: string;
  year: string;
  slug: string;
  thumbnail?: {
    archive_url: string;
  };
}

const Galleries: React.FC = () => {
  const params = useParams<IParams>();
  const { nursery } = useNursery();
  const [galleries, setGalleries] = useState<IGallery[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (Object.keys(nursery).length > 0) {
      setLoading(true);
      api
        .get<IGallery[]>(`galleries/nurseries/${nursery.id}`)
        .then((response) => {
          setGalleries(response.data);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [nursery]);

  return (
    <Container>
      <div className="container py-5">
        <div className="row">
          <div className="col-12">
            <h1 className="text-secondary mb-5">Galerias</h1>
          </div>
          {galleries.length > 0 ? (
            <>
              {galleries.map((gallery) => (
                <div key={gallery.id} className="col-lg-4 mb-4">
                  <Thumbnail
                    to={`${process.env.PUBLIC_URL}/maternais/${params.slug}/galerias/${gallery.slug}`}
                    src={gallery.thumbnail?.archive_url || ''}
                  >
                    <h2 className="h4">
                      {gallery.title} <b className="d-block">{gallery.year}</b>
                    </h2>
                  </Thumbnail>
                </div>
              ))}
            </>
          ) : (
            <div className="col-12 mb-5">
              <NoData message="Nenhuma galeria cadastrada" />
            </div>
          )}
        </div>
      </div>
      <Loading active={loading} color="#00a189" />
    </Container>
  );
};

export default Galleries;
