import styled from 'styled-components';

import bgMenu from '~/assets/defaults/bg-menu.svg';

interface IMenu {
  bgcolor: string;
}

export const Container = styled.div`
  margin-top: 76px;

  .btn-select {
    background: #eee;
    padding: 10px 21px;
  }

  @media screen and (min-width: 992px) {
    margin-top: 0;
  }
`;

export const Menu = styled.div<IMenu>`
  border-radius: 20px;
  background-image: url(${bgMenu});
  background-color: ${(props) => props.bgcolor};
  background-position: center;
  background-size: cover;
  height: 294px;

  p {
    color: #fff;
  }
`;
