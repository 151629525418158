import React, { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

import Header from '~/components/Header';
import HeaderNursery from '~/components/HeaderNursery';
import Footer from '~/components/Footer';

const AuthLayout: React.FC = ({ children }) => {
  const location = useLocation();
  const [currentPage, setCurrentPage] = useState('');

  useEffect(() => {
    if (location.hash) {
      setTimeout(() => {
        const offset = currentPage === location.pathname ? 200 : 0;
        const element = document.getElementById(location.hash.replace('#', ''));

        if (element) {
          window.scrollTo({
            top: element.offsetTop - offset,
            behavior: 'smooth',
          });
        }
      }, 300);
    } else {
      window.scrollTo(0, 0);
    }

    if (currentPage !== location.pathname) {
      setCurrentPage(location.pathname);
    }
  }, [currentPage, location]);

  const showHeader = useMemo(() => {
    return (
      (!location.pathname.includes('/fornecedor/') &&
        !location.pathname.includes('/prestador/')) ||
      location.pathname.includes('/canal/')
    );
  }, [location.pathname]);

  const showHeaderNursery = useMemo(() => {
    return location.pathname.includes('maternais/');
  }, [location.pathname]);

  return (
    <>
      {showHeaderNursery && <HeaderNursery />}
      {!showHeaderNursery && showHeader && <Header />}
      {children}
      <Footer />
    </>
  );
};

export default AuthLayout;
