import React, { useCallback, useEffect, useMemo, useState } from 'react';
import SliderSlick from 'react-slick';
import { Link, useParams } from 'react-router-dom';

import api from '~/services/api';
import { useNursery } from '~/hooks/Nursery';

import {
  Container,
  Hero,
  Banner,
  Notices,
  Gallery,
  Img,
  Informatives,
  SocialMedia,
  Notice,
  Newsletter,
} from './styles';

import Instagram from '~/components/Instagram';
import Loading from '~/components/Loading';
import NoData from '~/components/NoData';
import GalleryModal from '~/components/GalleryModal';

interface IParams {
  slug: string;
}

interface INotice {
  id: number;
  title: string;
  slug: string;
  thumbnail: {
    archive_url: string;
  };
}

interface INoticeResponse {
  data: INotice[];
  from: number;
  to: number;
  total: number;
}

interface IArchive {
  id: number;
  archive_url: string;
}

interface INewsletter {
  id: number;
  title: string;
  archive: {
    archive_url: string;
  };
}

interface IInstagramMedia {
  id: string;
  username: string;
  avatar_url: string;
  caption: string;
  thumbnail_url: string;
  media_url: string;
  media_type: string;
  children: {
    id: string;
    thumbnail_url: string;
    media_url: string;
    media_type: string;
  }[];
}

const Home: React.FC = () => {
  const params = useParams<IParams>();
  const { nursery } = useNursery();
  const [notices, setNotices] = useState<INotice[]>([]);
  const [photos, setPhotos] = useState<IArchive[]>([]);
  const [newsletters, setNewsletters] = useState<INewsletter[]>([]);
  const [instagramMedias, setInstagramMedias] = useState<IInstagramMedia[]>([]);
  const [photoIndexSelected, setPhotoIndexSelected] = useState(0);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (Object.keys(nursery).length > 0) {
      setLoading(true);
      api
        .get<INoticeResponse>(`notices/nurseries/${nursery.id}`)
        .then(async (response) => {
          const responseGalleries = await api.get<IArchive[]>(
            `galleries/nurseries/${nursery.id}/photos`
          );
          setPhotos(responseGalleries.data);

          const responseNewsletters = await api.get<INewsletter[]>(
            `newsletters/nurseries/${nursery.id}`
          );
          setNewsletters(responseNewsletters.data);

          const responseInstagrem = await api.get<IInstagramMedia[]>(
            `instagram/nurseries/${nursery.id}`
          );
          setInstagramMedias(responseInstagrem.data);

          const data = response.data.data.slice(0, 3).map((notice) => ({
            id: notice.id,
            title: notice.title,
            slug: notice.slug,
            thumbnail: {
              archive_url: notice.thumbnail?.archive_url || '',
            },
          }));
          setNotices(data);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [nursery]);

  const photosStruture = useMemo(() => {
    let isOddRow = true;
    let index = 0;
    const data = photos.map((photo) => {
      let columnClass = '';

      if (isOddRow) {
        columnClass = index === 0 ? 'col-6' : 'col-6 col-lg-3';
      } else {
        columnClass = index === 2 ? 'col-6' : 'col-6 col-lg-3';
      }

      if (index === 2) {
        if (isOddRow) {
          isOddRow = false;
        } else {
          isOddRow = true;
        }
        index = 0;
      } else {
        index += 1;
      }

      return {
        ...photo,
        columnClass,
        src: photo.archive_url,
        alt: 'Foto',
        loading: 'lazy' as 'lazy' | 'auto' | 'eager' | undefined,
      };
    });

    return data;
  }, [photos]);

  const handleSelectPhoto = useCallback((index) => {
    setPhotoIndexSelected(index);
    setShow(true);
  }, []);

  const handleClose = useCallback(() => {
    setShow(false);
  }, []);

  return (
    <>
      {Object.keys(nursery).length > 0 && (
        <Container>
          <Hero>
            <div className="container pt-5">
              <div className="row">
                <div className="col-12">
                  <Banner
                    src={nursery.archives[0].archive_url}
                    className="d-flex align-items-end p-4 px-lg-5 py-lg-0"
                  >
                    <div className="logo">
                      <h1 className="h5 h2-lg text-white text-start d-block mb-0 fw-normal">
                        <span>Maternal</span> <br />
                        <span className="h3 display-lg-4 mb-0 fw-semibold">
                          {nursery.name.replace('EMM ', '')}
                        </span>
                      </h1>
                    </div>
                  </Banner>
                </div>
              </div>
            </div>
          </Hero>
          <Notices className="mb-5 mb-lg-0">
            <div className="container">
              <div className="row">
                <div className="col-12 mt-5 pt-lg-5">
                  <div className="d-flex justify-content-between align-items-center mb-4">
                    <h2 className="h3 h1-lg fw-normal text-secondary mb-0">
                      Notícias
                    </h2>
                    <Link
                      to={`${process.env.PUBLIC_URL}/maternais/${params.slug}/noticias`}
                      className="h4-lg fw-semibold mb-0"
                    >
                      Ver mais
                    </Link>
                  </div>
                  {notices.length > 0 ? (
                    <SliderSlick
                      autoplay={false}
                      dots
                      arrows
                      autoplaySpeed={3000}
                    >
                      {notices.map((notice) => (
                        <Notice
                          src={notice.thumbnail.archive_url}
                          to={`${process.env.PUBLIC_URL}/maternais/${params.slug}/noticias/${notice.slug}`}
                          className="position-relative notice d-flex align-items-end"
                        >
                          <p className="h5 h3-lg title p-4 mb-4 p-lg-5 w-100">
                            {notice.title}
                          </p>
                        </Notice>
                      ))}
                    </SliderSlick>
                  ) : (
                    <div>
                      <NoData message="Nenhuma notícia cadastrada" />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Notices>
          <Gallery>
            <div className="container">
              <div className="row justify-content-center justify-content-lg-between align-items-center pt-lg-5">
                <div className="col-12 pt-lg-5 mt-5 mb-4 d-flex align-items-center justify-content-between">
                  <h2 className="h3 h1-lg fw-normal text-secondary mb-0">
                    Galeria
                  </h2>
                  <Link
                    to={`${process.env.PUBLIC_URL}/maternais/${params.slug}/galerias`}
                    className="h4-lg fw-semibold mb-0"
                  >
                    Ver mais
                  </Link>
                </div>
                {photosStruture.length > 0 ? (
                  <>
                    {photosStruture.map((photo, index) => (
                      <div
                        key={photo.id}
                        className={`${photo.columnClass} my-2`}
                      >
                        <Img
                          type="button"
                          src={photo.archive_url}
                          onClick={() => handleSelectPhoto(index)}
                        />
                      </div>
                    ))}
                  </>
                ) : (
                  <div className="col-12">
                    <NoData message="Nenhuma foto cadastrada" />
                  </div>
                )}
              </div>
            </div>
          </Gallery>
          <Informatives className="mb-5 pb-lg-5">
            <div className="container">
              <div className="row">
                <div className="col-12 mt-4 mt-lg-5 pt-lg-5">
                  <div className="d-flex justify-content-between align-items-center mb-4">
                    <h2 className="h3 h1-lg fw-normal text-secondary mb-0">
                      Informativos
                    </h2>
                  </div>
                  {newsletters.length > 0 ? (
                    <SliderSlick
                      autoplay={false}
                      dots
                      arrows
                      autoplaySpeed={3000}
                    >
                      {newsletters.map((newsletter) => (
                        <Newsletter src={newsletter.archive.archive_url} />
                      ))}
                    </SliderSlick>
                  ) : (
                    <div>
                      <NoData message="Nenhum informativo cadastrado" />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Informatives>
          {instagramMedias.length > 0 && (
            <SocialMedia className="mb-5 pb-4 pt-4">
              <div className="container">
                <div className="row">
                  <div className="col-12 d-flex justify-content-between align-items-center mb-4">
                    <h2 className="h3 h1-lg fw-normal text-secondary mb-0">
                      Nossas redes
                    </h2>
                    <a
                      href={`https://www.instagram.com/${instagramMedias[0].username}`}
                      className="h4-lg fw-semibold mb-0"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Ver mais
                    </a>
                  </div>
                  {instagramMedias.map((instagramMedia) => (
                    <div
                      key={instagramMedia.id}
                      className="col-lg-4 mb-3 mb-lg-0"
                    >
                      <Instagram data={instagramMedia} />
                    </div>
                  ))}
                </div>
              </div>
            </SocialMedia>
          )}
        </Container>
      )}
      <Loading active={loading} color="#00a189" />
      <GalleryModal
        show={show}
        onClose={handleClose}
        photos={photosStruture.map((photo) => ({
          src: photo.src,
          alt: photo.alt,
          loading: photo.loading,
        }))}
        currentIndex={photoIndexSelected}
      />
    </>
  );
};

export default Home;
