import { Link } from 'react-router-dom';
import styled from 'styled-components';
import next from '~/assets/icons/next_arrow.svg';
import prev from '~/assets/icons/prev_arrow.svg';

interface IBanner {
  src: string;
}

interface INotice {
  src: string;
}

interface IImg {
  src: string;
}

interface INewsletter {
  src: string;
}

export const Container = styled.div`
  margin-top: 76px;

  .slick-list {
    border-radius: 0;
  }

  @media screen and (min-width: 991px) {
    .slick-list {
      border-radius: 0 0 40px 40px;
    }
  }
`;

export const Hero = styled.div`
  max-width: 100%;
  overflow: hidden;
`;

export const Banner = styled.div<IBanner>`
  border-radius: 20px;
  background: linear-gradient(
      180deg,
      rgba(0, 201, 218, 0) 0%,
      rgba(13, 83, 89, 0.7) 50%,
      rgba(13, 83, 89, 1) 100%
    ),
    url(${(props) => props.src}), lightgray 50% / cover no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  height: 250px;

  .p-relative {
    position: relative;
    z-index: 1;
  }

  .logo {
    margin-bottom: 25px;
  }

  .logo img {
    width: 56px;
    height: 56px;
  }

  .logo span {
    span:nth-child(1) {
      margin-bottom: 30px;
      display: inline-block;
    }

    span:nth-child(3) {
      line-height: 13px;
      display: inline-flex;
      background: rgba(0, 0, 0, 0.35);
      -webkit-box-shadow: 0px 0px 31px 0px rgba(0, 0, 0, 0.35);
      -moz-box-shadow: 0px 0px 31px 0px rgba(0, 0, 0, 0.35);
      box-shadow: 0px 0px 31px 35px rgba(0, 0, 0, 0.35);
    }
  }

  @media screen and (min-width: 992px) {
    height: 528px;

    .logo {
      margin-bottom: 100px;
    }
  }
`;

export const Notices = styled.div`
  .slick-dots li button:before {
    font-family: 'slick';
    font-size: 13px;
    color: #fff;
  }

  .slick-dots {
    bottom: 25px;
  }

  .slick-list {
    border-radius: 0;
  }

  .slick-next,
  .slick-prev {
    width: 34px;
    height: 34px;
    border-radius: 11px;
    background: #e7e7e7;
    bottom: -30%;
    top: unset;

    :before {
      content: '';
      width: 16px;
      height: 16px;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      display: block;
      margin: auto;
      opacity: 1;
    }
  }

  .slick-next {
    right: 35%;

    :before {
      background-image: url(${next});
    }
  }

  .slick-prev {
    left: 35%;

    :before {
      background-image: url(${prev});
    }
  }

  @media screen and (min-width: 992px) {
    height: unset;

    .slick-list {
      border-radius: 40px;
    }

    .slick-next,
    .slick-prev {
      bottom: -20%;
      width: 54px;
      height: 54px;

      :before {
        width: 20px;
        height: 20px;
      }
    }

    .slick-next {
      right: 26px;
    }

    .slick-prev {
      left: unset;
      right: 93px;
    }
  }
`;

export const Notice = styled(Link)<INotice>`
  overflow: hidden;
  border-radius: 16px;
  height: 212px;
  background-image: url(${(props) => props.src});
  background-position: center;
  background-size: cover;

  p {
    bottom: 50px;
    left: 50px;
    z-index: 1;
    color: #fff;
  }

  ::before {
    content: '';
    background: linear-gradient(180deg, #00000000 16.15%, #2d2d2d 100%);
    position: absolute;
    width: 100%;
    height: 100%;
  }

  @media screen and (min-width: 992px) {
    border-radius: 40px;
    height: 582px;
  }
`;

export const Gallery = styled.div`
  .your-institute {
    border-radius: 27px;
  }

  p {
    color: #000;
    text-align: right;
    font-family: 'Poppins';
    font-size: 24px;
    font-weight: 300;
    line-height: normal;
  }
`;

export const Img = styled.button<IImg>`
  background-image: url(${(props) => props.src});
  background-position: center;
  background-size: cover;
  border-radius: 20px;
  width: 100%;
  height: 130px;
  border: none;

  @media screen and (min-width: 992px) {
    height: 282px;
  }
`;

export const Informatives = styled.div`
  .slick-dots li button:before {
    font-family: 'slick';
    font-size: 13px;
    color: #fff;
  }

  .slick-dots {
    bottom: 25px;
  }

  .slick-list {
    border-radius: 0;
  }

  .slick-next,
  .slick-prev {
    width: 34px;
    height: 34px;
    border-radius: 11px;
    background: #e7e7e7;
    bottom: -25%;
    top: unset;

    :before {
      content: '';
      width: 16px;
      height: 16px;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      display: block;
      margin: auto;
      opacity: 1;
    }
  }

  .slick-next {
    right: 35%;

    :before {
      background-image: url(${next});
    }
  }

  .slick-prev {
    left: 35%;

    :before {
      background-image: url(${prev});
    }
  }

  @media screen and (min-width: 992px) {
    .slick-list {
      border-radius: 40px;
    }

    .slick-next,
    .slick-prev {
      bottom: -20%;
      width: 54px;
      height: 54px;

      :before {
        width: 20px;
        height: 20px;
      }
    }

    .slick-next {
      right: 26px;
    }

    .slick-prev {
      left: unset;
      right: 93px;
    }
  }
`;

export const Newsletter = styled.div<INewsletter>`
  overflow: hidden;
  border-radius: 16px;
  height: 212px;
  background-image: url(${(props) => props.src});
  background-position: center;
  background-size: cover;

  @media screen and (min-width: 992px) {
    border-radius: 40px;
    height: 582px;
  }
`;

export const SocialMedia = styled.div``;
