import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { TiArrowLeft, TiArrowRight } from 'react-icons/ti';
import { format, parseISO } from 'date-fns';
import List from 'react-chatview';
import { MdClose } from 'react-icons/md';
import { IoDocumentText } from 'react-icons/io5';
import { Link, useLocation, useParams } from 'react-router-dom';

import api from '~/services/api';

import { Container, Modal } from './styles';
import Nurseries, { INursery } from '../Nurseries';
import { useAuth } from '~/hooks/Auth';

interface IArchive {
  id: string;
  title: string;
  archive_url: string;
}

interface IQuotation {
  id: string;
  archive: IArchive;
}

interface IProcess {
  id: number;
  title: string;
  status: 'Em andamento' | 'Em andamento' | 'Finalizado';
  created_at: string;
  archives: IArchive[];
  quotation?: IQuotation;
}

interface IProcessNursery {
  process: IProcess;
}

interface IProcessResponse {
  data: IProcessNursery[];
  from: number;
  to: number;
  total: number;
  last_page: number;
}

interface IParams {
  nursery?: string;
}

const ProcessesInProgress: React.FC = () => {
  const { user } = useAuth();
  const location = useLocation();
  const params = useParams<IParams>();
  const [recents, setRecents] = useState<IProcess[]>([]);
  const [processes, setProcesses] = useState<IProcess[]>([]);
  const [pageSelected, setPageSelected] = useState(1);
  const [total, setTotal] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [processSelected, setProcessSelected] = useState({} as IProcess);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [nursery, setNursery] = useState({} as INursery);

  const type = useMemo(
    () =>
      location.pathname.includes('fornecedor') ? 'fornecedor' : 'prestador',
    [location.pathname]
  );

  const handleLoadProcesses = useCallback(
    async (page, nurseryId) => {
      const response = await api.get<IProcessResponse>(
        `processes-nurseries/nurseries/${nurseryId}`,
        {
          params: {
            status: 'Em andamento',
            page,
            isSupplier: location.pathname.includes('fornecedor'),
            supplier_id: user.id,
          },
        }
      );
      const data = response.data.data.map((processNursery) => ({
        id: processNursery.process.id,
        title: processNursery.process.title,
        status: processNursery.process.status,
        created_at: format(
          parseISO(processNursery.process.created_at),
          'dd/MM/yyyy'
        ),
        archives: [],
        quotations: [],
      }));

      if (page === 1) {
        setRecents(data.slice(0, 3));
        setProcesses(data.slice(3));
      } else {
        setProcesses((state) => [...state, ...data]);
      }
      setTotal(response.data.total);
      setTotalPages(response.data.last_page);
    },
    [location.pathname, user.id]
  );

  useEffect(() => {
    if (params.nursery) {
      api
        .get(`nurseries/${params.nursery}`)
        .then(async (response) => {
          await handleLoadProcesses(1, response.data.id);
          setNursery(response.data);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setNursery({} as INursery);
      setProcesses([]);
    }
  }, [handleLoadProcesses, params.nursery]);

  const handleLoad = useCallback(async () => {
    if (pageSelected < totalPages) {
      await handleLoadProcesses(pageSelected + 1, nursery.id);
      setPageSelected(pageSelected + 1);
    }
  }, [handleLoadProcesses, nursery.id, pageSelected, totalPages]);

  const handleClickProcess = useCallback(async (process_id) => {
    const response = await api.get(`processes/suppliers/${process_id}`);
    setProcessSelected({
      ...response.data,
      archives: response.data.archives.map((archive: IArchive) => {
        if (archive.title === 'Documento') {
          return {
            ...archive,
            title: 'Clique para visualizar a Solicitação de Orçamento',
          };
        }
        return archive;
      }),
    });
    setShow(true);
  }, []);

  const handleClose = useCallback(() => {
    setShow(false);
  }, []);

  return (
    <Container>
      <div className="container">
        {params.nursery ? (
          <>
            <div className="row mb-3 mb-lg-5">
              <div className="col-12 px-4 px-lg-3 mt-5 mb-3 my-lg-5 d-lg-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center mb-4 mb-lg-0">
                  <Link
                    to={`${process.env.PUBLIC_URL}/${type}/processos-em-andamento`}
                    className="btn-back d-flex align-items-center justify-content-center me-2"
                  >
                    <TiArrowLeft size={28} color="#202020" />
                  </Link>
                  <h1 className="h4 h2-lg text-secondary fw-semibold mb-0">
                    Processos em Andamento: {nursery.name}
                  </h1>
                </div>
                <p className="process-qtd px-3 py-2 text-center">
                  Quantidade: <b>{total.toString().padStart(2, '0')}</b>{' '}
                  Processos
                </p>
              </div>
              <div className="col-12 px-4 px-lg-3">
                <p className="h4 mb-3">Recentes</p>
              </div>
              {recents.map((processData) => (
                <button
                  key={processData.id}
                  type="button"
                  className="col-lg-4 px-4 px-lg-3 border-0 bg-transparent text-start"
                  onClick={() => handleClickProcess(processData.id)}
                >
                  <div className="box-process">
                    <div className="d-flex justify-content-lg-end px-4 pt-4">
                      <p className="fw-medium text-secondary tag rounded-pill px-3 py-1">
                        Em andamento
                      </p>
                    </div>
                    <div className="pt-lg-5 px-4 pb-4">
                      <p className="text-white h5 fw-semibold">
                        {processData.title}
                      </p>
                    </div>
                    <div className="d-flex align-items-center justify-content-end px-4 py-3 see-process">
                      <p className="text-white mb-0 me-2">Ver mais</p>
                      <div>
                        <TiArrowRight size={20} color="#fff" />
                      </div>
                    </div>
                  </div>
                </button>
              ))}
            </div>
            <div className="row">
              <div className="col-12 px-4 px-lg-3">
                <p className="h4 mt-5 mb-4">Lista dos processos</p>
              </div>
              <List
                className="col-12 px-4 px-lg-3 mt-4 mb-5 quotations"
                scrollLoadThreshold={100}
                onInfiniteLoad={handleLoad}
              >
                {processes.map((processData) => (
                  <div
                    key={processData.id}
                    className="d-flex align-items-center quotation-box py-2 px-4"
                  >
                    <div className="detail me-3" />
                    <div className="d-flex flex-wrap justify-content-between align-items-center quotation-data">
                      <p className="h6 h5-lg mb-0 text-secondary fw-semibold title mb-3 mb-lg-0 py-2">
                        {processData.title}
                      </p>
                      <div className="d-flex justify-content-lg-end tag-box mx-lg-3">
                        <p className="fw-medium mb-0 px-3 px-lg-4 py-1 py-lg-2 mb-0 text-secondary tag open">
                          Em andamento
                        </p>
                      </div>
                      <button
                        type="button"
                        className="btn rounded-pill btn-see-more p-0 py-lg-2 px-lg-4 d-flex justify-content-center align-items-center"
                        onClick={() => handleClickProcess(processData.id)}
                      >
                        <p className="mb-0 me-lg-2 text-white fw-semibold d-none d-lg-block">
                          Ver mais
                        </p>
                        <div>
                          <TiArrowRight size={20} color="#fff" />
                        </div>
                      </button>
                    </div>
                  </div>
                ))}
              </List>
            </div>
          </>
        ) : (
          <div className="row">
            <div className="col-12 px-4 px-lg-3 mt-5 mb-3 my-lg-5 d-lg-flex justify-content-between align-items-center">
              <h1 className="h4 h2-lg text-secondary fw-semibold mb-4 mb-lg-0">
                Processos em Andamento
              </h1>
            </div>
            <Nurseries page="processos-em-andamento" />
          </div>
        )}
      </div>
      <Modal show={show} onHide={handleClose} size="lg">
        {Object.keys(processSelected).length > 0 && (
          <>
            <Modal.Header className="border-0 pt-4 px-5 mt-2 align-items-center justify-content-between">
              <Modal.Title className="text-secondary fw-semibold mb-0">
                {processSelected.title}
              </Modal.Title>
              <button
                type="button"
                className="bg-transparent border-0"
                onClick={handleClose}
              >
                <MdClose size={40} color="#202020" />
              </button>
            </Modal.Header>
            <Modal.Body className="px-5 my-3">
              {processSelected.archives.map((archive) => (
                <a
                  href={archive.archive_url}
                  target="_blank"
                  rel="noreferrer"
                  className="btn btn-archive mb-3 d-flex align-items-center p-3"
                >
                  <IoDocumentText size={24} color="#4A4747" className="me-2" />{' '}
                  {archive.title}
                </a>
              ))}
              {processSelected.quotation && (
                <a
                  href={processSelected.quotation.archive.archive_url}
                  target="_blank"
                  rel="noreferrer"
                  className="btn btn-archive mb-3 d-flex align-items-center p-3"
                >
                  <IoDocumentText size={24} color="#4A4747" className="me-2" />{' '}
                  Orçamento
                </a>
              )}
            </Modal.Body>
            <Modal.Footer className="border-0 pb-4 px-5 mb-2" />
          </>
        )}
      </Modal>
    </Container>
  );
};

export default ProcessesInProgress;
