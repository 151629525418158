import styled from 'styled-components';
import { Modal as ModalComponent } from 'react-bootstrap';
import { darken } from 'polished';

export const Container = styled.div`
  font-family: 'Poppins';

  h1 {
    color: #202020;
  }

  p {
    color: #707070;
  }

  .bg-process {
    border-radius: 16px;
    border: 1px solid #e8e8e8;
    background: #f9f9f9;
  }

  .fw-600 {
    font-weight: 600;
  }

  .bg-login {
    border-radius: 16px;
    background: #fa764b;
  }

  .btn-signup {
    background: #ad2d03;
    width: 225px;
    height: 47px;
  }

  .btn-login {
    background: #707070;
    color: #fff;
    font-weight: 600;
    height: 47px;
    transition-duration: 0.3s;

    :hover {
      background: ${darken(0.05, '#707070')};
    }
  }

  .btn-process {
    margin-top: 8px;
    margin-bottom: 8px;
    color: #202020;
    font-size: 18px;
    background: transparent;
    border-radius: 8px;
    transition-duration: 0.2s;
    :hover {
      color: #fff;
      font-weight: 600;
      background: #626262;
    }
  }

  .bd-all-buttons {
    padding: 0 20px;
  }

  .btn-select {
    height: 82px;
    border: none;
    border-radius: 16px 16px 0px 0px;
    background: #eaeaea;
  }

  .active {
    color: #fff;
    font-weight: 600;
    background: #626262;
  }

  .loading-text-white {
    p {
      color: #fff;
      margin-top: 10px;
    }
  }

  @media screen and (min-width: 992px) {
    .btn-process {
      margin-top: 0px;
      margin-bottom: 30px;
    }
    .bd-all-buttons {
      border: none;
      background: transparent;
      padding: 0;
    }
  }
`;

export const PurchaseManual = styled.div`
  h2 {
    color: #202020;
  }

  .bg-manual a {
    color: #4a4747;
  }

  .bg-manual {
    border-radius: 16px;
    border: 1px solid #cacaca;
    background: #eaeaea;
  }
`;

export const Modal = styled(ModalComponent)`
  .modal-content {
    border-radius: 32px;
    border: 1px solid #f0efef;
    background: #fff;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1);

    .btn-archive {
      border-radius: 8px;
      border: 1px solid #cacaca;
      background: #f4f4f4;
    }

    .input-file {
      position: relative;

      .error-box {
        position: absolute;
        bottom: -35px;
      }
    }

    .btn-signup {
      background: #ad2d03;
      width: 225px;
      height: 47px;
    }

    .btn-login {
      background: #ffbaa4;
      color: #b12c00;
      font-weight: 600;
      height: 47px;
      width: 98px;
    }
  }
`;
