import React, { useCallback, useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { Form } from '@unform/web';
import { NavLink, useHistory, useLocation, useParams } from 'react-router-dom';
import { RiQuestionLine } from 'react-icons/ri';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import Swal from 'sweetalert2';

import { TbCameraPlus } from 'react-icons/tb';
import { IoIosArrowDown } from 'react-icons/io';
import getValidationErros from '~/utils/getValidationsErrors';

import { Container, Avatar, Tabs, TabsButton, TabsGroup } from './styles';
import Input from '~/components/Input';
import InputMask from '~/components/InputMask';
import InputFile from '~/components/InputFile';
import { useAuth } from '~/hooks/Auth';
import api from '~/services/api';
import Toast from '~/utils/toast';

interface ICompanyResponse {
  razao_social: string;
  qualificacao_do_responsavel: {
    id: number;
  };
  socios: {
    nome: string;
    qualificacao_socio: {
      id: number;
    };
  }[];
  estabelecimento: {
    cep: string;
    tipo_logradouro: string;
    logradouro: string;
    numero: string;
    bairro: string;
    complemento: string;
    email: string;
    ddd1: string;
    telefone1: string;
    atividade_principal: {
      subclasse: string;
      descricao: string;
    };
    estado: {
      nome: string;
    };
    cidade: {
      nome: string;
    };
  };
}

interface IParams {
  step: string;
}

interface IFormData {
  cnpj: string;
  companyName: string;
  cnae: string;
  presidentName: string;
  rg: string;
  cpf: string;
  zipcode: string;
  street: string;
  number: string;
  state: string;
  city: string;
  neighborhood: string;
  complement: string;
  email: string;
  phone: string;
  password: string;
}

const Profile: React.FC = () => {
  const { user, updateUser } = useAuth();
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const location = useLocation();
  const params = useParams<IParams>();
  const [cnpj, setCnpj] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [cnae, setCnae] = useState('');
  const [presidentName, setPresidentName] = useState('');
  const [rg, setRg] = useState('');
  const [cpf, setCpf] = useState('');
  const [zipcode, setZipcode] = useState('');
  const [street, setStreet] = useState('');
  const [number, setNumber] = useState('');
  const [neighborhood, setNeighborhood] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [complement, setComplement] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [cnpjCard, setCnpjCard] = useState<File | undefined>();
  const [cnpjCardName, setCnpjCardName] = useState('');
  const [cnpjCardError, setCnpjCardError] = useState('');
  const [socialContract, setSocialContract] = useState<File | undefined>();
  const [socialContractName, setSocialContractName] = useState('');
  const [socialContractError, setSocialContractError] = useState('');
  const [mei, setMei] = useState<File | undefined>();
  const [meiName, setMeiName] = useState('');
  const [meiError, setMeiError] = useState('');
  const [fgtsNegative, setFgtsNegative] = useState<File | undefined>();
  const [fgtsNegativeName, setFgtsNegativeName] = useState('');
  const [fgtsNegativeError, setFgtsNegativeError] = useState('');
  const [federal, setFederal] = useState<File | undefined>();
  const [federalName, setFederalName] = useState('');
  const [federalError, setFederalError] = useState('');
  const [labor, setLabor] = useState<File | undefined>();
  const [laborName, setLaborName] = useState('');
  const [laborError, setLaborError] = useState('');
  const [fgtsProof, setFgtsProof] = useState<File | undefined>();
  const [fgtsProofName, setFgtsProofName] = useState('');
  const [fgtsProofError, setFgtsProofError] = useState('');
  const [avatar, setAvatar] = useState<File | undefined>();
  const [previewAvatar, setPreviewAvatar] = useState('');
  const [tabSelected, setTabSelected] = useState('empresa');
  const [openTabs, setOpenTabs] = useState(false);

  useEffect(() => {
    if (location.pathname === '/fornecedor/perfil') {
      history.push(`${process.env.PUBLIC_URL}/fornecedor/perfil/empresa`);
    }
  }, [history, location.pathname]);

  useEffect(() => {
    setCnpj(user.cnpj || '');
    setCompanyName(user.company || '');
    setCnae(user.cnae || '');
    setPresidentName(user.president_name || '');
    setRg(user.rg || '');
    setCpf(user.cpf || '');
    setZipcode(user.address?.zip_code || '');
    setStreet(user.address?.street || '');
    setNumber(user.address?.number || '');
    setNeighborhood(user.address?.neighborhood || '');
    setCity(user.address?.city || '');
    setState(user.address?.state || '');
    setComplement(user.address?.complement || '');
    setEmail(user.email || '');
    setPhone(user.phone || '');
    user.archives.forEach((document) => {
      if (document.type === 'cnpjCard') {
        setCnpjCardName(document.name);
      }
      if (document.type === 'socialContract') {
        setSocialContractName(document.name);
      }
      if (document.type === 'mei') {
        setMeiName(document.name);
      }
      if (document.type === 'fgtsNegative') {
        setFgtsNegativeName(document.name);
      }
      if (document.type === 'federal') {
        setFederalName(document.name);
      }
      if (document.type === 'labor') {
        setLaborName(document.name);
      }
      if (document.type === 'fgtsProof') {
        setFgtsProofName(document.name);
      }
    });
  }, [user]);

  useEffect(() => {
    if (params.step) {
      setTabSelected(params.step);
      setOpenTabs(false);
    }
  }, [params.step]);

  const handleChangeCnpj = useCallback(async (e) => {
    try {
      if (e.target.value.length === 18) {
        const cnpjDoc = e.target.value
          .replaceAll('.', '')
          .replace('/', '')
          .replace('-', '');
        const response = await axios.get<ICompanyResponse>(
          `https://publica.cnpj.ws/cnpj/${cnpjDoc}`
        );
        setCompanyName(response.data.razao_social);
        setCnae(
          `${response.data.estabelecimento.atividade_principal.subclasse} ${response.data.estabelecimento.atividade_principal.descricao}`
        );
        const socioData = response.data.socios.find(
          (socio) =>
            socio.qualificacao_socio.id ===
            response.data.qualificacao_do_responsavel.id
        );
        setPresidentName(socioData?.nome || '');
        setZipcode(response.data.estabelecimento.cep);
        setStreet(
          `${response.data.estabelecimento.tipo_logradouro} ${response.data.estabelecimento.logradouro}`
        );
        setNumber(response.data.estabelecimento.numero);
        setNeighborhood(response.data.estabelecimento.bairro);
        setCity(response.data.estabelecimento.cidade.nome);
        setState(response.data.estabelecimento.estado.nome);
        setComplement(response.data.estabelecimento.complemento);
        setPhone(
          `${response.data.estabelecimento.ddd1}${response.data.estabelecimento.telefone1}`
        );
      }

      setCnpj(e.target.value);
    } catch (error) {
      console.log(error);
    }
  }, []);

  const handleChangeZipCode = useCallback(async (e) => {
    try {
      if (e.target.value.length === 9) {
        const zipcodeDoc = e.target.value.replace('-', '');
        const response = await axios.get(
          `https://viacep.com.br/ws/${zipcodeDoc}/json/`
        );
        setStreet(response.data.logradouro);
        setNeighborhood(response.data.bairro);
        setCity(response.data.localidade);
        setState(response.data.uf);
        setComplement(response.data.complemento);
      }

      setZipcode(e.target.value);
    } catch (error) {
      console.log(error);
    }
  }, []);

  const handleChangeCnpjCard = useCallback((files: File[]) => {
    setCnpjCard(files[0]);
  }, []);

  const handleChangeSocialContract = useCallback((files: File[]) => {
    setSocialContract(files[0]);
  }, []);

  const handleChangeMei = useCallback((files: File[]) => {
    setMei(files[0]);
  }, []);

  const handleChangeFgtsNegative = useCallback((files: File[]) => {
    setFgtsNegative(files[0]);
  }, []);

  const handleChangeFederal = useCallback((files: File[]) => {
    setFederal(files[0]);
  }, []);

  const handleChangeLabor = useCallback((files: File[]) => {
    setLabor(files[0]);
  }, []);

  const handleChangeFgtsProof = useCallback((files: File[]) => {
    setFgtsProof(files[0]);
  }, []);

  const handleChangeAvatar = useCallback((files: File[]) => {
    setAvatar(files[0]);
    setPreviewAvatar(URL.createObjectURL(files[0]));
  }, []);

  const handleSubmit = useCallback(
    async (data: IFormData) => {
      try {
        formRef.current?.setErrors({});
        setCnpjCardError('');
        setSocialContractError('');
        setMeiError('');
        setFgtsNegativeError('');
        setFederalError('');
        setLaborError('');
        setFgtsProofError('');

        const schema = Yup.object().shape({
          cnpj: Yup.string().required('O CNPJ é obrigatório'),
          companyName: Yup.string().required('A razão social é obrigatória'),
          cnae: Yup.string().required('O CNAE é obrigatório'),
          presidentName: Yup.string().required(
            'O representante legal é obrigatório'
          ),
          rg: Yup.string().required('O RG é obrigatório'),
          cpf: Yup.string().required('O CPF é obrigatório'),
          zipcode: Yup.string().required('O CEP é obrigatório'),
          street: Yup.string().required('A rua é obrigatória'),
          number: Yup.string().required('O número é obrigatório'),
          state: Yup.string().required('O estado é obrigatório'),
          city: Yup.string().required('A cidade é obrigatória'),
          neighborhood: Yup.string().required('O bairro é obrigatório'),
          complement: Yup.string(),
          email: Yup.string().required('O e-mail é obrigatório'),
          phone: Yup.string().required('A telefone é obrigatória'),
          password: Yup.string(),
          confirmPassword: Yup.string().oneOf(
            [Yup.ref('password'), null],
            'As senhas devem ser iguais'
          ),
          cnpjCard: Yup.string().when('$cnpjCardCheck', {
            is: (cnpjCardCheck: boolean) => cnpjCardCheck,
            then: Yup.string().required('O cartão CNPJ é obrigatório'),
            otherwise: Yup.string(),
          }),
          socialContract: Yup.string().when('$socialContractCheck', {
            is: (socialContractCheck: boolean) => socialContractCheck,
            then: Yup.string().required('O contrato social é obrigatório'),
            otherwise: Yup.string(),
          }),
          fgtsNegative: Yup.string().when('$fgtsNegativeCheck', {
            is: (fgtsNegativeCheck: boolean) => fgtsNegativeCheck,
            then: Yup.string().required(
              'A certidão negativa FGTS é obrigatória'
            ),
            otherwise: Yup.string(),
          }),
          federal: Yup.string().when('$federalCheck', {
            is: (federalCheck: boolean) => federalCheck,
            then: Yup.string().required('A certidão federal é obrigatória'),
            otherwise: Yup.string(),
          }),
          labor: Yup.string().when('$laborCheck', {
            is: (laborCheck: boolean) => laborCheck,
            then: Yup.string().required('A certidão trabalhista é obrigatória'),
            otherwise: Yup.string(),
          }),
        });

        await schema.validate(data, {
          abortEarly: false,
          context: {
            cnpjCard: !cnpjCard && !cnpjCardName,
            socialContract: !socialContract && !socialContractName,
            mei: !mei && !meiName,
            fgtsNegative: !fgtsNegative && !fgtsNegativeName,
            federal: !federal && !federalName,
            labor: !labor && !laborName,
            fgtsProof: !fgtsProof && !fgtsProofName,
          },
        });

        const formData = {
          cnpj: data.cnpj,
          company: data.companyName,
          cnae: data.cnae,
          president_name: data.presidentName,
          rg: data.rg,
          cpf: data.cpf,
          email: data.email,
          phone: data.phone,
          password: data.password,
          is_provider: location.pathname.includes('prestador'),
        };

        const response = await api.put(`suppliers/${user.id}`, formData);

        const addressFormData = {
          supplier_id: response.data.id,
          street: data.street,
          number: data.number,
          neighborhood: data.neighborhood,
          city: data.city,
          state: data.state,
          zip_code: data.zipcode,
          complement: data.complement,
        };

        if (user.address?.id) {
          await api.put(`addresses/${user.address.id}`, addressFormData);
        } else {
          await api.post('addresses', addressFormData);
        }

        const documents = [
          cnpjCard,
          socialContract,
          mei,
          fgtsNegative,
          federal,
          labor,
          fgtsProof,
        ];

        const documentsType = [
          'cnpjCard',
          'socialContract',
          'mei',
          'fgtsNegative',
          'federal',
          'labor',
          'fgtsProof',
        ];

        const archives = user.archives.slice();
        if (documents.length > 0) {
          await new Promise<void>((resolve) => {
            documents.forEach(async (document, index) => {
              if (document) {
                const documentFormData = new FormData();
                documentFormData.append('supplier_id', response.data.id);
                documentFormData.append('document', document);
                documentFormData.append('type', documentsType[index]);

                const documentIndex = archives.findIndex(
                  (documentData) => documentData.type === documentsType[index]
                );

                if (documentIndex >= 0) {
                  const responseDocument = await api.post(
                    `archives/${archives[documentIndex].id}`,
                    documentFormData
                  );
                  archives[documentIndex] = responseDocument.data;
                } else {
                  const responseDocument = await api.post(
                    `archives`,
                    documentFormData
                  );
                  archives.push(responseDocument.data);
                }
              }

              if (documents.length === index + 1) {
                resolve();
              }
            });
          });
        }

        let avatarData = user.avatar;
        if (avatar) {
          const avatarFormData = new FormData();
          avatarFormData.append('supplier_id', response.data.id);
          avatarFormData.append('avatar', avatar);

          if (user.avatar.id) {
            const responseAvatar = await api.post(
              `avatars/${user.avatar.id}`,
              avatarFormData
            );
            avatarData = responseAvatar.data;
          } else {
            const responseAvatar = await api.post('avatars', avatarFormData);
            avatarData = responseAvatar.data;
          }
        }

        updateUser({
          ...user,
          cnpj: data.cnpj,
          company: data.companyName,
          cnae: data.cnae,
          president_name: data.presidentName,
          rg: data.rg,
          cpf: data.cnpj,
          email: data.email,
          phone: data.phone,
          address: {
            ...user.address,
            street: data.street,
            number: data.number,
            neighborhood: data.neighborhood,
            city: data.city,
            state: data.state,
            zip_code: data.zipcode,
            complement: data.complement,
          },
          archives,
          avatar: avatarData,
        });

        Toast.fire({
          icon: 'success',
          title: 'Informações atualizadas!',
        });
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          formRef.current?.setErrors(errors);

          if (errors.cnpjCard) {
            setCnpjCardError(errors.cnpjCard);
          }

          if (errors.socialContract) {
            setSocialContractError(errors.socialContract);
          }

          if (errors.mei) {
            setMeiError(errors.mei);
          }

          if (errors.fgtsNegative) {
            setFgtsNegativeError(errors.fgtsNegative);
          }

          if (errors.federal) {
            setFederalError(errors.federal);
          }

          if (errors.labor) {
            setLaborError(errors.labor);
          }

          if (errors.fgtsProof) {
            setFgtsProofError(errors.fgtsProof);
          }
        } else {
          console.log('');
          console.log('Profile');
          console.log(error);
          Swal.fire('Oops...', 'Ocorreu um erro tente novamente, por favor');
        }
      }
    },
    [
      avatar,
      cnpjCard,
      cnpjCardName,
      federal,
      federalName,
      fgtsNegative,
      fgtsNegativeName,
      fgtsProof,
      fgtsProofName,
      labor,
      laborName,
      location.pathname,
      mei,
      meiName,
      socialContract,
      socialContractName,
      updateUser,
      user,
    ]
  );

  const handleClickTabsButton = useCallback(() => {
    setOpenTabs((oldState) => !oldState);
  }, []);

  return (
    <Container>
      <Form ref={formRef} onSubmit={handleSubmit} className="container">
        <div className="row mb-5">
          <div className="col-12 mt-5 mb-0 my-lg-5">
            <h1 className="h3 h2-lg text-secondary fw-semibold mb-4 mb-lg-0">
              Perfil
            </h1>
          </div>
          <div className="col-12 mb-5">
            <div className="box p-3 p-lg-4">
              <div className="row align-items-center">
                <div className="col-lg-4 col-xl-3 d-flex justify-content-center mb-3 mb-lg-0">
                  <div className="bg-avatar d-flex justify-content-center">
                    <label className="box-avatar py-4">
                      <Avatar
                        src={previewAvatar || user.avatar.avatar_url}
                        className="mx-auto mb-3"
                      >
                        <div className="icon">
                          <TbCameraPlus size={18} color="#fff" />
                        </div>
                      </Avatar>
                      <span className="text-secondary fw-medium">
                        Foto de perfil
                      </span>
                      <InputFile
                        name="avatar"
                        className="d-none"
                        onChange={handleChangeAvatar}
                      />
                    </label>
                  </div>
                </div>
                <div className="col-lg-4 ps-xl-5">
                  <label className="mb-3 w-100">
                    <span className="d-block mb-2 text-secondary fw-medium">
                      Representante legal
                    </span>
                    <Input
                      name="presidentName"
                      placeholder="Digite aqui"
                      onChange={(e) => setPresidentName(e.target.value)}
                      value={presidentName}
                    />
                  </label>
                </div>
                <div className="col-lg-4 ps-xl-5">
                  <label className="mb-3 w-100">
                    <span className="d-block mb-2 text-secondary fw-medium">
                      CNPJ
                    </span>
                    <InputMask
                      kind="cnpj"
                      name="cnpj"
                      placeholder="Digite aqui"
                      onChange={handleChangeCnpj}
                      value={cnpj}
                    />
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="box py-4 px-3 p-lg-5">
              <div className="row align-items-center">
                <div className="col-lg-6 mb-lg-5">
                  <h2 className="h5 h4-lg text-secondary fw-semibold mb-4 mb-lg-0">
                    Dados da empresa
                  </h2>
                </div>
                <div className="col-lg-6 mb-3 mb-lg-5 ps-lg-5">
                  <Tabs className="p-3 p-lg-0 w-100">
                    <TabsButton
                      type="button"
                      onClick={handleClickTabsButton}
                      className="d-flex d-md-none justify-content-between align-items-center w-100 border-0 bg-transparent"
                    >
                      {tabSelected === 'empresa' && 'Empresa'}
                      {tabSelected === 'endereco' && 'Endereço'}
                      {tabSelected === 'contato' && 'Contato'}
                      {tabSelected === 'documentos' && 'Documentos'}
                      <IoIosArrowDown
                        size={24}
                        color="#707070"
                        className="ms-2"
                      />
                    </TabsButton>
                    <TabsGroup
                      opened={openTabs}
                      className="ps-lg-5 d-flex flex-column flex-md-row justify-content-md-between align-items-center"
                    >
                      <NavLink
                        to={`${process.env.PUBLIC_URL}/fornecedor/perfil/empresa`}
                        activeClassName="active"
                      >
                        Empresa
                      </NavLink>
                      <NavLink
                        to={`${process.env.PUBLIC_URL}/fornecedor/perfil/endereco`}
                        activeClassName="active"
                      >
                        Endereço
                      </NavLink>
                      <NavLink
                        to={`${process.env.PUBLIC_URL}/fornecedor/perfil/contato`}
                        activeClassName="active"
                      >
                        Contato
                      </NavLink>
                      <NavLink
                        to={`${process.env.PUBLIC_URL}/fornecedor/perfil/documentos`}
                        activeClassName="active"
                      >
                        Documentos
                      </NavLink>
                    </TabsGroup>
                  </Tabs>
                </div>
              </div>
              <div
                className={`row ${params.step !== 'empresa' ? 'd-none' : ''}`}
              >
                <div className="col-lg-6">
                  <label className="mb-3 w-100">
                    <span className="d-block mb-2 text-secondary fw-medium">
                      Razão social
                    </span>
                    <Input
                      name="companyName"
                      placeholder="Digite aqui"
                      onChange={(e) => setCompanyName(e.target.value)}
                      value={companyName}
                    />
                  </label>
                </div>
                <div className="col-lg-6">
                  <label className="mb-3 w-100">
                    <span className="d-block mb-2 text-secondary fw-medium">
                      CNAE
                    </span>
                    <Input
                      name="cnae"
                      placeholder="Digite aqui"
                      onChange={(e) => setCnae(e.target.value)}
                      value={cnae}
                    />
                  </label>
                </div>
                <div className="col-lg-6">
                  <label className="mb-3 w-100">
                    <span className="d-block mb-2 text-secondary fw-medium">
                      RG
                    </span>
                    <InputMask
                      kind="custom"
                      options={{
                        mask: '99.999.999-S',
                      }}
                      name="rg"
                      placeholder="Digite aqui"
                      onChange={(e) => setRg(e.target.value)}
                      value={rg}
                    />
                  </label>
                </div>
                <div className="col-lg-6">
                  <label className="mb-3 w-100">
                    <span className="d-block mb-2 text-secondary fw-medium">
                      CPF
                    </span>
                    <InputMask
                      kind="cpf"
                      name="cpf"
                      placeholder="Digite aqui"
                      onChange={(e) => setCpf(e.target.value)}
                      value={cpf}
                    />
                  </label>
                </div>
              </div>
              <div
                className={`row ${params.step !== 'endereco' ? 'd-none' : ''}`}
              >
                <div className="col-lg-3">
                  <label className="mb-3 w-100">
                    <span className="d-block mb-2 text-secondary fw-medium">
                      CEP
                    </span>
                    <InputMask
                      kind="zip-code"
                      name="zipcode"
                      placeholder="Digite aqui"
                      onChange={handleChangeZipCode}
                      value={zipcode}
                    />
                  </label>
                </div>
                <div className="col-lg-9">
                  <label className="mb-3 w-100">
                    <span className="d-block mb-2 text-secondary fw-medium">
                      Rua
                    </span>
                    <Input
                      name="street"
                      placeholder="Digite aqui"
                      onChange={(e) => setStreet(e.target.value)}
                      value={street}
                    />
                  </label>
                </div>
                <div className="col-lg-3">
                  <label className="mb-3 w-100">
                    <span className="d-block mb-2 text-secondary fw-medium">
                      Número
                    </span>
                    <Input
                      name="number"
                      placeholder="Digite aqui"
                      onChange={(e) => setNumber(e.target.value)}
                      value={number}
                    />
                  </label>
                </div>
                <div className="col-lg-4">
                  <label className="mb-3 w-100">
                    <span className="d-block mb-2 text-secondary fw-medium">
                      Estado
                    </span>
                    <Input
                      name="state"
                      placeholder="Digite aqui"
                      onChange={(e) => setState(e.target.value)}
                      value={state}
                    />
                  </label>
                </div>
                <div className="col-lg-5">
                  <label className="mb-3 w-100">
                    <span className="d-block mb-2 text-secondary fw-medium">
                      Cidade
                    </span>
                    <Input
                      name="city"
                      placeholder="Digite aqui"
                      onChange={(e) => setCity(e.target.value)}
                      value={city}
                    />
                  </label>
                </div>
                <div className="col-lg-6">
                  <label className="mb-3 w-100">
                    <span className="d-block mb-2 text-secondary fw-medium">
                      Bairro
                    </span>
                    <Input
                      name="neighborhood"
                      placeholder="Digite aqui"
                      onChange={(e) => setNeighborhood(e.target.value)}
                      value={neighborhood}
                    />
                  </label>
                </div>
                <div className="col-lg-6">
                  <label className="mb-3 w-100">
                    <span className="d-block mb-2 text-secondary fw-medium">
                      Complemento
                    </span>
                    <Input
                      name="complement"
                      placeholder="Digite aqui"
                      onChange={(e) => setComplement(e.target.value)}
                      value={complement}
                    />
                  </label>
                </div>
              </div>
              <div
                className={`row ${params.step !== 'contato' ? 'd-none' : ''}`}
              >
                <div className="col-12">
                  <label className="mb-3 w-100">
                    <span className="d-block mb-2 text-secondary fw-medium">
                      E-mail
                    </span>
                    <Input
                      type="email"
                      name="email"
                      placeholder="Digite aqui"
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                    />
                  </label>
                </div>
                <div className="col-12">
                  <label className="mb-3 w-100">
                    <span className="d-block mb-2 text-secondary fw-medium">
                      Telefone
                    </span>
                    <InputMask
                      kind="cel-phone"
                      name="phone"
                      placeholder="Digite aqui"
                      onChange={(e) => setPhone(e.target.value)}
                      value={phone}
                    />
                  </label>
                </div>
                <div className="col-12 mt-3 mb-2">
                  <h3 className="h5 text-secondary">Mudar senha</h3>
                </div>
                <div className="col-lg-6">
                  <label className="mb-3 w-100">
                    <span className="d-block mb-2 text-secondary fw-medium">
                      Digite uma senha
                    </span>
                    <Input
                      type="password"
                      name="password"
                      placeholder="Digite aqui"
                    />
                  </label>
                </div>
                <div className="col-lg-6">
                  <label className="mb-3 w-100">
                    <span className="d-block mb-2 text-secondary fw-medium">
                      Confirmar senha
                    </span>
                    <Input
                      type="password"
                      name="confirmPassword"
                      placeholder="Digite aqui"
                    />
                  </label>
                </div>
              </div>
              <div
                className={`row ${
                  params.step !== 'documentos' ? 'd-none' : ''
                }`}
              >
                <div className="col-12 mb-3">
                  <label className="d-flex align-items-center w-100 mb-2">
                    <span className="text-secondary fw-medium me-1">
                      Cartão CNPJ
                    </span>
                    <a href="/#">
                      <RiQuestionLine size={20} color="#707070" />
                    </a>
                  </label>
                  <InputFile
                    name="cnpjCard"
                    onChange={handleChangeCnpjCard}
                    value={cnpjCardName}
                    className={`input-file justify-content-center ${
                      cnpjCardError ? 'mb-3' : ''
                    }`}
                  />
                </div>
                <div className="col-12 mb-3">
                  <label className="d-flex align-items-center w-100 mb-2">
                    <span className="text-secondary fw-medium me-1">
                      Contrato social
                    </span>
                    <a href="/#">
                      <RiQuestionLine size={20} color="#707070" />
                    </a>
                  </label>
                  <InputFile
                    name="socialContract"
                    onChange={handleChangeSocialContract}
                    value={socialContractName}
                    className={`input-file justify-content-center ${
                      socialContractError ? 'mb-3' : ''
                    }`}
                  />
                </div>
                <div className="col-12 mb-3">
                  <label className="d-flex align-items-center w-100 mb-2">
                    <span className="text-secondary fw-medium me-1">
                      MEI - Cadastro de Micro Empreendedor
                    </span>
                    <a href="/#">
                      <RiQuestionLine size={20} color="#707070" />
                    </a>
                  </label>
                  <InputFile
                    name="mei"
                    onChange={handleChangeMei}
                    value={meiName}
                    className={`input-file justify-content-center ${
                      meiError ? 'mb-3' : ''
                    }`}
                  />
                </div>
                <div className="col-12 mb-3">
                  <h3 className="h4 text-secondary my-3">Certidões</h3>
                  <div className="row">
                    <div className="col-lg-4 mb-3 mb-lg-0">
                      <label className="d-flex align-items-center w-100 mb-2">
                        <span className="text-secondary fw-medium me-1">
                          Negativa FGTS
                        </span>
                        <a href="/#">
                          <RiQuestionLine size={20} color="#707070" />
                        </a>
                      </label>
                      <InputFile
                        name="fgtsNegative"
                        onChange={handleChangeFgtsNegative}
                        value={fgtsNegativeName}
                        className={`small-input-file justify-content-center ${
                          fgtsNegativeError ? 'mb-5' : ''
                        }`}
                      />
                    </div>
                    <div className="col-lg-4 mb-3 mb-lg-0">
                      <label className="d-flex align-items-center w-100 mb-2">
                        <span className="text-secondary fw-medium me-1">
                          Federal
                        </span>
                        <a href="/#">
                          <RiQuestionLine size={20} color="#707070" />
                        </a>
                      </label>
                      <InputFile
                        name="federal"
                        onChange={handleChangeFederal}
                        value={federalName}
                        className={`small-input-file justify-content-center ${
                          federalError ? 'mb-4' : ''
                        }`}
                      />
                    </div>
                    <div className="col-lg-4 mb-3 mb-lg-0">
                      <label className="d-flex align-items-center w-100 mb-2">
                        <span className="text-secondary fw-medium me-1">
                          Trabalhista
                        </span>
                        <a href="/#">
                          <RiQuestionLine size={20} color="#707070" />
                        </a>
                      </label>
                      <InputFile
                        name="labor"
                        onChange={handleChangeLabor}
                        value={laborName}
                        className={`small-input-file justify-content-center ${
                          laborError ? 'mb-4' : ''
                        }`}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-12 mb-3">
                  <label className="d-flex align-items-center w-100 mb-2">
                    <span className="text-secondary fw-medium me-1">
                      Comprovante de FGTS (Caso possua vinculo empregatício)
                    </span>
                    <a href="/#">
                      <RiQuestionLine size={20} color="#707070" />
                    </a>
                  </label>
                  <InputFile
                    name="fgtsProof"
                    onChange={handleChangeFgtsProof}
                    value={fgtsProofName}
                    className={`input-file justify-content-center ${
                      fgtsProofError ? 'mb-3' : ''
                    }`}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 d-flex justify-content-end mt-3">
            <button
              type="submit"
              className="btn btn-primary rounded-pill px-5 py-2 w-100 w-lg-auto"
            >
              Salvar
            </button>
          </div>
        </div>
      </Form>
    </Container>
  );
};

export default Profile;
