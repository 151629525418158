import React from 'react';

import { AuthProvider } from './Auth';
import { NurseryProvider } from './Nursery';
import { ResizeProvider } from './Resize';

const AppProvider: React.FC = ({ children }) => (
  <ResizeProvider>
    <NurseryProvider>
      <AuthProvider>{children}</AuthProvider>
    </NurseryProvider>
  </ResizeProvider>
);

export default AppProvider;
