import styled, { css } from 'styled-components';

interface ContainerProps {
  isFilled: boolean;
  isFocuses: boolean;
  isErrored: boolean;
  active: boolean;
  multiple?: boolean;
}

export const Container = styled.div<ContainerProps>`
  background: #f9f9f9;
  border-radius: 10px;
  border: 1px solid #e4e4e4;
  padding: 5px 10px 5px 0;
  width: 100%;
  display: flex;
  align-items: center;
  height: 46px;
  cursor: pointer;

  label {
    cursor: pointer;
    width: ${(props) => (props.multiple ? '100%' : 'calc(100% - 24px)')};
    overflow: hidden;

    p {
      color: #a3a3a3;
      font-weight: 400;
      font-size: 14px;
      text-overflow: ellipsis;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  > div {
    width: 100%;
    height: 80px;
    overflow-y: auto;
    overflow-x: hidden;

    p {
      text-overflow: ellipsis;
      white-space: nowrap;
      display: block;
      overflow: hidden;
    }
  }

  p {
    width: ${(props) => (props.multiple ? 'calc(100% - 24px)' : '100%')};
    color: #8692a6;
    font-weight: 500;
  }

  svg {
    margin-right: 16px;
    color: #8692a6;
    transition-duration: 0.2s;
  }

  ${(props) =>
    props.isErrored &&
    css`
      border-color: #ff1a50;
    `}

  ${(props) => props.isFilled && css``}

  ${(props) =>
    props.isFocuses &&
    css`
      color: #606060;
      border-color: #606060;
    `}
`;

export const Dropzone = styled.div``;
