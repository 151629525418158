/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect } from 'react';
import { Switch, useLocation } from 'react-router-dom';

import Route from './Route';

import Home from '~/pages/Home';
import Notices from '~/pages/Notices';
import Notice from '~/pages/Notice';
import WorkUs from '~/pages/WorkUs';

import SupplierRoutes from './SupplierRoutes';
import NurseryRoutes from './NurseryRoutes';
import Maternal from '~/pages/Maternal';
import TransparencyPortal from '~/pages/TransparencyPortal';
import Regulations from '~/pages/Regulations';
import SupplierChannel from '~/pages/SupplierChannel';
import SupplierProvider from '~/pages/SupplierProvider';
import ProviderRoutes from './ProviderRoutes';
import OpenVacancies from '~/pages/OpenVacancies';

interface IRoutes {
  setPathname: React.Dispatch<React.SetStateAction<string>>;
}

const routes: React.FC<IRoutes> = ({ setPathname }) => {
  const location = useLocation();

  useEffect(() => {
    setPathname(location.pathname);
  }, [location.pathname, setPathname]);

  return (
    <Switch>
      <Route path={`${process.env.PUBLIC_URL}/`} exact component={Home} />
      <Route
        path={`${process.env.PUBLIC_URL}/noticias`}
        exact
        component={Notices}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/noticias/:noticeSlug`}
        exact
        component={Notice}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/faca-parte-da-equipe`}
        exact
        component={WorkUs}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/maternais`}
        exact
        component={Maternal}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/portal-da-transparencia`}
        exact
        component={TransparencyPortal}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/regulamentos`}
        exact
        component={Regulations}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/trabalhe-conosco`}
        exact
        component={OpenVacancies}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/canal/:slug`}
        exact
        component={SupplierProvider}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/canal/:type/:nursery`}
        component={SupplierChannel}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/maternais/:slug`}
        component={NurseryRoutes}
        subRoutes
      />
      <Route
        path={`${process.env.PUBLIC_URL}/fornecedor`}
        component={SupplierRoutes}
        subRoutes
      />

      <Route
        path={`${process.env.PUBLIC_URL}/prestador`}
        component={ProviderRoutes}
        subRoutes
      />
    </Switch>
  );
};

export default routes;
