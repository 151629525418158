import styled from 'styled-components';
import Lightbox from 'react-spring-lightbox';

export const Container = styled(Lightbox)`
  z-index: 1000;
  padding: 50px;
  background: rgba(0, 0, 0, 0.5);

  > div {
    > div,
    > button {
      position: relative;
    }

    > button {
      z-index: 2;
    }
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const BoxIcon = styled.button`
  background-color: #202020;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  border: 0;
`;
