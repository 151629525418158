import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { MdClose } from 'react-icons/md';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import Lottie from 'react-lottie';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import { RiArrowLeftSLine, RiArrowRightSLine } from 'react-icons/ri';

import api from '~/services/api';

import { Container, Modal } from './styles';
import Loading from '~/components/Loading';
import NoData from '~/components/NoData';

import next from '~/assets/icons/next_arrow_white.svg';
import Input from '~/components/Input';
import InputMask from '~/components/InputMask';
import InputCheckbox, { IOption } from '~/components/InputCheckbox';
import InputFile from '~/components/InputFile';
import emailSent from '~/assets/animations/email-sent.json';
import getValidationErros from '~/utils/getValidationsErrors';
import InputRadio from '~/components/InputRadio';
import { useResize } from '~/hooks/Resize';

interface IFormData {
  name: string;
  email: string;
  phone: string;
  interestArea: string;
  interestUnits: string;
}

interface INursery {
  id: number;
  name: string;
  slug: string;
  address: {
    street: string;
    number: number;
    neighborhood: string;
    city: string;
    state: string;
    zip_code: string;
  };
}

interface IJobNursery {
  id: number;
  nursery: INursery;
}

interface IJob {
  id: number;
  title: string;
  requirements: string;
  description: string;
  status: string;
  created_at: string;
  jobs_nurseries: IJobNursery[];
}

interface IJobResponse {
  data: IJob[];
  from: number;
  to: number;
  total: number;
  last_page: number;
}

interface IVagancy {
  id: number;
  title: string;
  requirements: string;
  description: string;
}

interface INurseryOption {
  id: number;
  name: string;
  label: string;
  value: number | string;
  selected: boolean;
  slug: string;
}

const OpenVacancies: React.FC = () => {
  const { width } = useResize();
  const formRef = useRef<FormHandles>(null);
  const [vagancies, setVagancies] = useState<IVagancy[]>([]);
  const [vagancySelected, setVagancySelected] = useState({} as IVagancy);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [curriculum, setCurriculum] = useState<File | undefined>();
  const [nurseries, setNurseries] = useState<INurseryOption[]>([]);
  const [curriculumSent, setCurriculumSent] = useState(false);
  const [participate, setParticipate] = useState(false);
  const [interestUnits, setInterestUnits] = useState('');
  const [pageSelected, setPageSelected] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [firstJob, setFirstJob] = useState(false);

  const pages = useMemo(() => {
    const qtd = width > 992 ? 7 : 5;
    const pageArray = Array.from(
      { length: totalPages < qtd ? totalPages : qtd },
      (e, index) => (totalPages < qtd ? `${index + 1}`.padStart(2, '0') : e)
    );

    const data = pageArray.map((page, index) => {
      if (page) {
        return page;
      }

      if (width > 992) {
        if (index === 0) {
          return '01';
        }
        if (index === 1) {
          if (pageSelected > 3 && pageSelected - 3 !== 1) {
            return '...';
          }
          return (2).toString().padStart(2, '0');
        }
        if (index === 2) {
          if (pageSelected > 3) {
            if (pageSelected + 3 > totalPages) {
              return (totalPages - 4).toString().padStart(2, '0');
            }
            return (pageSelected - 1).toString().padStart(2, '0');
          }
          return (3).toString().padStart(2, '0');
        }
        if (index === 3) {
          if (pageSelected > 4) {
            if (pageSelected + 3 > totalPages) {
              return (totalPages - 3).toString().padStart(2, '0');
            }
            return pageSelected.toString().padStart(2, '0');
          }
          return (4).toString().padStart(2, '0');
        }
        if (index === 4) {
          if (pageSelected >= 5) {
            if (pageSelected + 3 > totalPages) {
              return (totalPages - 2).toString().padStart(2, '0');
            }
            return (pageSelected + 1).toString().padStart(2, '0');
          }
          return (5).toString().padStart(2, '0');
        }
        if (index === 5) {
          if (pageSelected + 3 >= totalPages) {
            return (totalPages - 1).toString().padStart(2, '0');
          }
          return '...';
        }
        if (index === 6) {
          return totalPages.toString().padStart(2, '0');
        }
      } else {
        if (index === 0) {
          return (1).toString().padStart(2, '0');
        }
        if (index === 1) {
          if (pageSelected > 3) {
            return '...';
          }
          return (2).toString().padStart(2, '0');
        }
        if (index === 2) {
          if (pageSelected > 3 && pageSelected + 2 < totalPages) {
            return pageSelected.toString().padStart(2, '0');
          }
          if (pageSelected + 2 >= totalPages) {
            return (totalPages - 2).toString().padStart(2, '0');
          }
          return (3).toString().padStart(2, '0');
        }
        if (index === 3) {
          if (pageSelected + 2 >= totalPages) {
            return (totalPages - 1).toString().padStart(2, '0');
          }
          return '...';
        }
        if (index === 4) {
          return totalPages.toString().padStart(2, '0');
        }
      }

      return '';
    });

    return data as string[];
  }, [pageSelected, totalPages, width]);

  const handleLoadJobs = useCallback(async (page) => {
    const response = await api.get<IJobResponse>('jobs', {
      params: {
        page,
        status: 'Em andamento',
        limit: 8,
      },
    });

    const data = response.data.data.map<IVagancy>((job) => ({
      id: job.id,
      title: job.title,
      requirements: job.requirements,
      description: job.description,
    }));

    setVagancies(data);
    setTotalPages(response.data.last_page);
  }, []);

  useEffect(() => {
    handleLoadJobs(pageSelected);
  }, [handleLoadJobs, pageSelected]);

  useEffect(() => {
    api
      .get<INursery[]>('nurseries', {
        params: { noPaginate: true },
      })
      .then((response) => {
        const data = response.data.map<INurseryOption>((nursery) => ({
          id: nursery.id,
          name: nursery.name,
          value: nursery.name,
          label: `${nursery.name}<br><span>${nursery.address.street}, ${nursery.address.number} - ${nursery.address.neighborhood}, ${nursery.address.city} - ${nursery.address.state}, ${nursery.address.zip_code}</span>`,
          selected: false,
          slug: nursery.slug,
        }));
        setNurseries([
          ...data,
          {
            id: 0,
            name: '',
            label: 'Não tenho preferência',
            value: 'Não tenho preferência',
            selected: false,
            slug: '',
          },
        ]);
      });
  }, []);

  const handleFocus = useCallback((e) => {
    if (e.target.parentNode.parentNode.parentNode.tagName === 'LABEL') {
      e.target.parentNode.parentNode.parentNode.classList.add('focused');
    } else if (e.target.parentNode.parentNode.tagName === 'LABEL') {
      e.target.parentNode.parentNode.classList.add('focused');
    }
  }, []);

  const handleBlur = useCallback((e) => {
    if (e.target.parentNode.parentNode.parentNode.tagName === 'LABEL') {
      const type =
        e.target.parentNode.parentNode.parentNode.getAttribute('aria-details');
      if (type === 'select') {
        setTimeout(() => {
          e.target.parentNode.parentNode.parentNode.classList.remove('focused');
        }, 300);
      } else {
        e.target.parentNode.parentNode.parentNode.classList.remove('focused');
      }
    } else if (e.target.parentNode.parentNode.tagName === 'LABEL') {
      e.target.parentNode.parentNode.classList.remove('focused');
    }
  }, []);

  const handleVagancySelected = useCallback((vagancy) => {
    setVagancySelected(vagancy);
    setShow(true);
  }, []);

  const handleClose = useCallback(() => {
    setShow(false);
    setParticipate(false);
    setFirstJob(false);
    setCurriculumSent(false);
  }, []);

  const handleSubmit = useCallback(
    async (data: IFormData) => {
      try {
        setLoading(true);
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required('O nome é obrigatório'),
          email: Yup.string().required('O e-mail é obrigatória'),
          phone: Yup.string().required('O telefone/celular é obrigatória'),
          curriculum: Yup.string().when('$curriculumCheck', {
            is: (curriculumCheck: boolean) => !curriculumCheck,
            then: Yup.string().required('O currículo é obrigatório'),
            otherwise: Yup.string(),
          }),
        });

        await schema.validate(data, {
          abortEarly: false,
          context: {
            curriculum: !curriculum,
          },
        });

        const archiveFormData = new FormData();
        archiveFormData.append('curriculum', curriculum as File);
        archiveFormData.append('job_id', vagancySelected.id.toString());
        archiveFormData.append('type', 'Resume');

        const response = await api.post('archives', archiveFormData);

        const nurseriesSelected = nurseries
          .filter((nursery) => nursery.selected)
          .map((nursery) => nursery.value)
          .join(', ');

        const formData = {
          job_id: vagancySelected.id,
          archive_id: response.data.id,
          name: data.name,
          email: data.email,
          phone: data.phone,
          nurseries: nurseriesSelected,
          first_job: firstJob,
        };

        await api.post('jobs-resumes', formData);

        window.scrollTo(0, 0);
        setCurriculumSent(true);
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          formRef.current?.setErrors(errors);
        } else {
          console.log('');
          console.log('WorkUs');
          console.log(error);
          Swal.fire('Oops...', 'Ocorreu um erro tente novamente, por favor');
        }
      } finally {
        setLoading(false);
      }
    },
    [curriculum, firstJob, nurseries, vagancySelected.id]
  );

  const handleChangeInterestUnits = useCallback((options: IOption[]) => {
    if (
      options.some(
        (option) => option.label === 'Não tenho preferência' && option.selected
      )
    ) {
      setNurseries((prevNurseries) =>
        prevNurseries.map((nursery) => {
          console.log(nursery.value === 'Não tenho preferência');
          return {
            ...nursery,
            selected: nursery.value === 'Não tenho preferência',
          };
        })
      );
    }
  }, []);

  const handleChangeCurriculum = useCallback((files: File[]) => {
    setCurriculum(files[0]);
  }, []);

  const handleClickPrev = useCallback(() => {
    setPageSelected((state) => (state - 1 <= 1 ? 1 : state - 1));
  }, []);

  const handleClickPage = useCallback((page) => {
    setPageSelected(page);
  }, []);

  const handleClickNext = useCallback(() => {
    setPageSelected((state) =>
      state + 1 >= totalPages ? totalPages : state + 1
    );
  }, [totalPages]);

  const handleChangeFirstJob = useCallback((options: IOption[]) => {
    const optionSelected = options.find((option) => option.selected);
    if (optionSelected) {
      setFirstJob(optionSelected.value === 'yes');
    }
  }, []);

  return (
    <Container>
      <div className="container">
        <div className="row">
          <div className="col-12 px-4 px-lg-3">
            <h1 className="h4 h1-lg mb-4 mb-lg-5">Trabalhe conosco</h1>
            <p className="h6 h4-lg fw-light mb-4 mb-lg-5 pb-lg-5">
              Acreditamos que cuidar e educar as futuras gerações é uma missão
              <br className="d-none d-lg-block" />
              de amor, dedicação e respeito.
            </p>
          </div>
          {vagancies.length > 0 ? (
            <div className="bg-vagancies col-12 mb-5">
              <h2 className="h5 h2-lg fw-semibold mb-5">Vagas em aberto:</h2>

              <div className="row">
                {vagancies.map((vagancy) => (
                  <div className="col-md-6 col-lg-3 mb-3">
                    <div className="bg-card d-flex flex-column justify-content-end">
                      <h5 className="h6 fw-normal px-4">Vaga:</h5>
                      <span className="h6 fw-semibold d-block mb-3 px-4">
                        {vagancy.title}
                      </span>

                      <div className="bg-see-more py-3 px-4">
                        <button
                          type="button"
                          onClick={() => handleVagancySelected(vagancy)}
                          className="h6 mb-0 fw-semibold border-0 bg-transparent text-white"
                        >
                          Ver mais
                          <img src={next} alt="arrow" className="ms-2" />
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <div className="col-12 mb-5">
              <NoData message="Nenhuma vaga em aberto" />
            </div>
          )}
          <div className="col-12 px-4 px-lg-3 d-flex justify-content-center mb-5 mt-n4">
            <div className="d-flex">
              <button
                type="button"
                className="btn btn-pagination arrow"
                onClick={handleClickPrev}
              >
                <RiArrowLeftSLine size={24} color="#707070" />
              </button>
              {pages.map((page, index) => (
                <button
                  key={index.toString()}
                  type="button"
                  className={`btn btn-pagination ${
                    page === pageSelected.toString().padStart(2, '0')
                      ? 'selected'
                      : ''
                  } ${page === '...' ? 'ellipsis' : ''}`}
                  onClick={() => handleClickPage(parseInt(page, 10))}
                  disabled={page === '...'}
                >
                  {page}
                </button>
              ))}
              <button
                type="button"
                className="btn btn-pagination arrow"
                onClick={handleClickNext}
              >
                <RiArrowRightSLine size={24} color="#707070" />
              </button>
            </div>
          </div>
        </div>
      </div>
      <Loading active={loading} />
      <Modal show={show} onHide={handleClose} size="lg">
        {Object.keys(vagancySelected).length > 0 && (
          <>
            <Modal.Header className="border-0 pt-lg-4 px-3 px-lg-5 mt-2 pb-0">
              <button
                type="button"
                className="ms-auto bg-transparent border-0"
                onClick={handleClose}
              >
                <MdClose size={40} color="#202020" />
              </button>
            </Modal.Header>
            <Modal.Body className="px-3 px-lg-5 pt-0 mb-3">
              {!participate ? (
                <>
                  <h2 className="h4 text-secondary fw-semibold mb-5">
                    Vaga:
                    <br />
                    {vagancySelected.title}
                  </h2>
                  <h3 className="h6 text-secondary fw-semibold">
                    Exigências para o cargo:
                  </h3>
                  <p className="mb-4">{vagancySelected.requirements}</p>
                  <h3 className="h6 text-secondary fw-semibold">
                    Descrição da vaga:
                  </h3>
                  <p className="mb-5">{vagancySelected.description}</p>
                  <div className="d-flex">
                    <button
                      type="button"
                      onClick={() => setParticipate(true)}
                      className="btn btn-secondary rounded-pill py-2 px-4 ms-auto"
                    >
                      Participar
                    </button>
                  </div>
                </>
              ) : (
                <>
                  {!curriculumSent ? (
                    <Form
                      ref={formRef}
                      onSubmit={handleSubmit}
                      className="box d-flex flex-wrap"
                    >
                      <div className="w-100 px-3">
                        <h2 className="h4 text-secondary fw-semibold mb-4 mb-lg-5">
                          Para prosseguir, preencha os campos abaixo:
                        </h2>
                      </div>
                      <div className="w-100 mb-4 px-3">
                        <label className="d-block w-100">
                          <span>Nome completo</span>
                          <Input
                            name="name"
                            placeholder="ex: João Silva"
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                          />
                        </label>
                      </div>
                      <div className="w-100 w-lg-50 mb-4">
                        <label className="d-block w-100 px-3">
                          <span>E-mail</span>
                          <Input
                            type="email"
                            name="email"
                            placeholder="exemplo@gmail.com"
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                          />
                        </label>
                      </div>
                      <div className="w-100 w-lg-50 mb-4 px-3">
                        <label className="d-block w-100">
                          <span>Celular pessoal</span>
                          <InputMask
                            kind="cel-phone"
                            name="phone"
                            placeholder="Digite o telefone/celular"
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                          />
                        </label>
                      </div>

                      <div className="w-100 mb-4 px-3">
                        <label className="d-block w-100" aria-details="select">
                          <span>
                            Selecione as unidades que deseja enviar o seu
                            currículo:
                          </span>
                        </label>
                        <InputCheckbox
                          type="checkbox"
                          name="interestUnits"
                          options={nurseries}
                          className="mt-3 input-checkbox"
                          isHtml
                          onChange={handleChangeInterestUnits}
                        />
                      </div>
                      <div className="w-100 w-lg-50 mb-4 mb-lg-5 px-3">
                        <label className="d-block w-100" aria-details="select">
                          <span>Primeiro emprego?</span>
                        </label>
                        <InputCheckbox
                          type="radio"
                          name="firstJob"
                          className="input-radio justify-content-start"
                          options={[
                            {
                              value: 'yes',
                              label: 'Sim',
                              selected: firstJob,
                            },
                            {
                              value: 'no',
                              label: 'Não',
                              selected: !firstJob,
                            },
                          ]}
                          onChange={handleChangeFirstJob}
                        />
                      </div>
                      <div className="w-100 w-lg-50 mb-5 px-3">
                        <label className="d-block w-100" aria-details="select">
                          <span>Currículo</span>
                        </label>
                        <InputFile
                          name="curriculum"
                          onChange={handleChangeCurriculum}
                          className="input-file px-2 px-lg-0"
                        />
                      </div>
                      <div className="w-100 px-3">
                        <button
                          type="submit"
                          className="btn btn-secondary rounded-pill px-5 py-2 ms-auto d-block w-100 w-lg-auto"
                        >
                          Enviar
                        </button>
                      </div>
                    </Form>
                  ) : (
                    <div>
                      <div className="my-5 pt-4 pb-0 pe-none">
                        <Lottie
                          options={{
                            animationData: emailSent,
                            autoplay: true,
                            loop: true,
                            rendererSettings: {
                              preserveAspectRatio: 'xMidYMid slice',
                            },
                          }}
                          width={206}
                          height={176}
                        />
                      </div>
                      <h2 className="h4 fw-semibold text-center text-secondary">
                        Currículo enviado com sucesso!
                      </h2>
                      <p className="text-center fw-light mb-5">
                        Entraremos em contato em breve
                      </p>
                    </div>
                  )}
                </>
              )}
            </Modal.Body>
            <Modal.Footer className="border-0 pb-0 pt-0 px-5 mb-2" />
          </>
        )}
      </Modal>
    </Container>
  );
};

export default OpenVacancies;
