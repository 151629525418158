import { Link } from 'react-router-dom';
import styled from 'styled-components';

interface IThumbnail {
  src: string;
}

export const Container = styled.div`
  margin-top: 76px;

  @media screen and (min-width: 992px) {
    margin-top: 0;
  }
`;

export const Thumbnail = styled(Link)<IThumbnail>`
  border-radius: 20px;
  background: linear-gradient(
      180deg,
      rgba(152, 152, 152, 0) 0%,
      rgba(11, 40, 43, 0.9) 100%
    ),
    url(${(props) => props.src}), lightgray 50% / cover no-repeat;
  background-position: center;
  width: 100%;
  height: 288px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 24px;

  h2 {
    color: #fff;
  }

  @media screen and (min-width: 992px) {
    height: 370px;
  }
`;
