import React, {
  useState,
  useCallback,
  useEffect,
  useRef,
  useMemo,
} from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { MdClose, MdLogin } from 'react-icons/md';
import { IoDocumentText } from 'react-icons/io5';
import { Form } from '@unform/web';
import { RiArrowDownSLine } from 'react-icons/ri';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import axios from 'axios';
import Lottie from 'react-lottie';

import api from '~/services/api';
import { useResize } from '~/hooks/Resize';
import getValidationErros from '~/utils/getValidationsErrors';
import Toast from '~/utils/toast';

import { Container, Modal } from './styles';
import PurchaseManual from './PurchaseManual';
import ProcessProgress from './ProcessProgress';
import OpenProcess from './OpenProcess';
import FinalizedProcesses from './FinalizedProcesses';
import InputMask from '~/components/InputMask';
import Input from '~/components/Input';
import InputFile from '~/components/InputFile';
import Loading from '~/components/Loading';

import profile from '~/assets/animations/profile.json';

interface ICompanyResponse {
  razao_social: string;
  qualificacao_do_responsavel: {
    id: number;
  };
  socios: {
    nome: string;
    qualificacao_socio: {
      id: number;
    };
  }[];
  estabelecimento: {
    email: string;
    ddd1: string;
    telefone1: string;
  };
}

interface IArchive {
  id: number;
  title: string;
  archive_url: string;
}

interface IParams {
  nursery: string;
}

export interface IProcess {
  id: number;
  title: string;
  status: 'Em aberto' | 'Em andamento' | 'Finalizado';
  created_at: string;
  archives: IArchive[];
  quotation?: {
    archive: IArchive;
  };
}

interface IFormData {
  cnpj: string;
  companyName: string;
  presidentName: string;
  email: string;
  phone: string;
}

const SupplierChannel: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [btnProcess, setBtnProcess] = useState('open');
  const location = useLocation();
  const params = useParams<IParams>();
  const { width } = useResize();
  const [show, setShow] = useState(false);
  const [processSelected, setProcessSelected] = useState({} as IProcess);
  const [openProcess, setOpenProcess] = useState(false);
  const [showBudget, setShowBudget] = useState(false);
  const [textBtnProcess, setTextBtnProcess] = useState('Processos em Aberto');
  const [cnpj, setCnpj] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [presidentName, setPresidentName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [quotation, setQuotation] = useState<File | undefined>();
  const [loading, setLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('');
  const [url, setUrl] = useState('');
  const [showCompleteRegister, setShowCompleteRegister] = useState(false);
  const [supplierName, setSupplierName] = useState('');

  const supplier = useMemo(
    () => location.pathname.includes('fornecedor'),
    [location.pathname]
  );

  const handleBtnProgress = useCallback((active) => {
    setBtnProcess(active);
  }, []);

  const handleSelectProcess = useCallback(async (process_id) => {
    const response = await api.get(`processes/${process_id}`);
    console.log(response.data);
    setProcessSelected({
      ...response.data,
      archives: response.data.archives.map((archive: IArchive) => {
        if (archive.title === 'Documento') {
          return {
            ...archive,
            title: 'Clique para visualizar a Solicitação de Orçamento',
          };
        }
        return archive;
      }),
    });
    setShow(true);
  }, []);

  const handleMobileProcess = useCallback(() => {
    setOpenProcess(!openProcess);
  }, [openProcess]);

  const handletextBtn = useCallback(
    (text) => {
      if (width > 991) {
        setOpenProcess(true);
      } else {
        setOpenProcess(false);
      }
      setTextBtnProcess(text);
    },
    [width]
  );

  const handleClose = useCallback(() => {
    setShow(false);
  }, []);

  useEffect(() => {
    if (width > 991) {
      setOpenProcess(true);
    }
  }, [width]);

  const handleChangeCnpj = useCallback(async (e) => {
    try {
      if (e.target.value.length === 18) {
        const cnpjDoc = e.target.value
          .replaceAll('.', '')
          .replace('/', '')
          .replace('-', '');
        const response = await axios.get<ICompanyResponse>(
          `https://publica.cnpj.ws/cnpj/${cnpjDoc}`
        );
        setCompanyName(response.data.razao_social);
        const socioData = response.data.socios.find(
          (socio) =>
            socio.qualificacao_socio.id ===
            response.data.qualificacao_do_responsavel.id
        );
        setPresidentName(socioData?.nome || '');
        setPhone(
          `${response.data.estabelecimento.ddd1}${response.data.estabelecimento.telefone1}`
        );
      }

      setCnpj(e.target.value);
    } catch (error) {
      console.log(error);
    }
  }, []);

  const handleChangeQuotation = useCallback((files: File[]) => {
    setQuotation(files[0]);
  }, []);

  const handleSubmit = useCallback(
    async (data: IFormData) => {
      try {
        setLoading(true);
        setLoadingMessage('Enviando seu orçamento...');
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          cnpj: Yup.string().required('O CNPJ é obrigatório'),
          companyName: Yup.string().required('A razão social é obrigatória'),
          presidentName: Yup.string().required(
            'O responsavel legal é obrigatório'
          ),
          email: Yup.string().required('O e-mail é obrigatório'),
          phone: Yup.string().required('O telefone é obrigatório'),
          quotation: Yup.string().when('$quotationCheck', {
            is: (quotationCheck: boolean) => !quotationCheck,
            then: Yup.string().required(
              'O documento do orçamento é obrigatório'
            ),
            otherwise: Yup.string(),
          }),
        });

        await schema.validate(data, {
          abortEarly: false,
          context: {
            quotation: !quotation,
          },
        });

        const formData = {
          cnpj: data.cnpj,
          company: data.companyName,
          president_name: data.presidentName,
          phone: data.phone,
          email: data.email,
          is_provider: !supplier,
        };

        const response = await api.post(`suppliers`, formData);

        const responseNursery = await api.get(`nurseries/${params.nursery}`);

        const formDataQuotation = new FormData();
        formDataQuotation.append('quotation', quotation as File);
        formDataQuotation.append('process_id', processSelected.id.toString());
        formDataQuotation.append('supplier_id', response.data.supplier.id);
        formDataQuotation.append('nursery_id', responseNursery.data.id);
        formDataQuotation.append(
          'title',
          `Orçamento: ${response.data.supplier.company}`
        );

        await api.post('quotations', formDataQuotation);

        if (response.data.token) {
          const type = supplier ? 'forncedor' : 'prestador';
          setUrl(
            `${process.env.PUBLIC_URL}/${type}/cadastro/${response.data.token.content}/inicio`
          );
          const nameParts = response.data.supplier.president_name.split(' ');
          setSupplierName(nameParts[0]);
          setShowCompleteRegister(true);
        }

        Toast.fire({
          icon: 'success',
          title: 'Orçamento enviado!',
        });

        setShowBudget(false);
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          formRef.current?.setErrors(errors);
        } else {
          console.log('');
          console.log('SupplierChannel');
          console.log(error);
          Swal.fire('Oops...', 'Ocorreu um erro tente novamente, por favor');
        }
      } finally {
        setLoading(false);
        setLoadingMessage('');
      }
    },
    [params.nursery, processSelected.id, quotation, supplier]
  );

  return (
    <Container>
      <div className="container">
        <div className="row">
          <div className="col-12 d-flex justify-content-between align-items-center mb-4 mb-lg-5 mt-5 pt-5">
            <h1 className="h4 h1-lg mb-0">
              Canal do {supplier ? 'Fornecedor' : 'Prestador'}
            </h1>
            <Link
              to={`${process.env.PUBLIC_URL}/${
                supplier ? 'fornecedor' : 'prestador'
              }/login`}
              className="h6 fw-600 border-0 rounded-pill btn-login mb-0 mx-2 d-flex align-items-center justify-content-center px-3"
            >
              <MdLogin size={24} color="#fff" className="me-2" /> Entrar
            </Link>
          </div>
        </div>
        <div className="row px-3">
          <div className="col-12 bg-process py-0 py-lg-4 py-xxl-5 mb-5">
            <div className="row justify-content-evenly pb-4 py-lg-4">
              <div className="col-lg-4 col-xxl-3 px-0 px-lg-3">
                <button
                  type="button"
                  onClick={handleMobileProcess}
                  className="d-lg-none mb-0 btn-select h6 d-flex px-4 align-items-center justify-content-between w-100"
                >
                  <span>{textBtnProcess}</span>
                  <RiArrowDownSLine
                    size={24}
                    color="#202020"
                    className="ms-auto"
                  />
                </button>
                {openProcess && (
                  <div className="bd-all-buttons">
                    {/* <button
                  type="button"
                  className={`${
                    btnProcess === 'purchase' && 'active'
                  } w-100 border-0 btn-process px-3 py-2 text-start`}
                  onClick={() => handleBtnProgress('purchase')}
                >
                  Manual de compras
                </button> */}
                    <button
                      type="button"
                      className={`${
                        btnProcess === 'open' && 'active'
                      } w-100 border-0 btn-process px-3 py-2 text-start`}
                      onClick={() => {
                        handleBtnProgress('open');

                        handletextBtn('Processos em Aberto');
                      }}
                    >
                      Processos em Aberto
                    </button>
                    <button
                      type="button"
                      className={`${
                        btnProcess === 'progress' && 'active'
                      } w-100 border-0 btn-process px-3 py-2 text-start`}
                      onClick={() => {
                        handleBtnProgress('progress');

                        handletextBtn('Processos em Andamento');
                      }}
                    >
                      Processos em Andamento
                    </button>
                    <button
                      type="button"
                      className={`${
                        btnProcess === 'finalized' && 'active'
                      } w-100 border-0 btn-process px-3 py-2 text-start`}
                      onClick={() => {
                        handleBtnProgress('finalized');

                        handletextBtn('Processos Finalizados');
                      }}
                    >
                      Processos Finalizados
                    </button>
                    <hr className="d-lg-none" />
                  </div>
                )}
              </div>
              <div className="col-lg-8 col-xl-7 px-3 mt-4 mt-lg-0">
                {btnProcess === 'purchase' && <PurchaseManual />}
                {btnProcess === 'open' && (
                  <OpenProcess onSelectProcess={handleSelectProcess} />
                )}
                {btnProcess === 'progress' && (
                  <ProcessProgress onSelectProcess={handleSelectProcess} />
                )}
                {btnProcess === 'finalized' && (
                  <FinalizedProcesses onSelectProcess={handleSelectProcess} />
                )}
              </div>
              <Modal show={show} onHide={handleClose} size="lg">
                {Object.keys(processSelected).length > 0 && (
                  <>
                    <Modal.Header className="border-0 pt-4 px-5 mt-2 align-items-center justify-content-between">
                      <Modal.Title className="text-secondary fw-semibold mb-0">
                        {processSelected.title} -{' '}
                        <span className="text-uppercase">
                          {params.nursery.replaceAll('-', ' ')}
                        </span>
                      </Modal.Title>
                      <button
                        type="button"
                        className="bg-transparent border-0"
                        onClick={handleClose}
                      >
                        <MdClose size={40} color="#202020" />
                      </button>
                    </Modal.Header>
                    <Modal.Body className="px-5 my-3">
                      {processSelected.archives.map((archive) => (
                        <a
                          href={archive.archive_url}
                          target="_blank"
                          rel="noreferrer"
                          className="btn btn-archive mb-3 d-flex align-items-center p-3"
                        >
                          <IoDocumentText
                            size={24}
                            color="#4A4747"
                            className="me-2"
                          />{' '}
                          {archive.title}
                        </a>
                      ))}
                    </Modal.Body>
                    <Modal.Footer className="border-0 pb-4 px-5 mb-2 justify-content-end">
                      {btnProcess === 'open' && (
                        <button
                          type="button"
                          className="h6 text-white fw-normal border-0 rounded-pill btn-signup mb-0 mx-2 d-flex align-items-center justify-content-center"
                          onClick={() => setShowBudget(true)}
                        >
                          Enviar orçamento
                        </button>
                      )}
                      <Link
                        to={`${process.env.PUBLIC_URL}/${
                          supplier ? 'fornecedor' : 'prestador'
                        }/login`}
                        className="h6 fw-600 border-0 rounded-pill btn-login  mb-0 mx-2 d-flex align-items-center justify-content-center"
                      >
                        Entrar
                      </Link>
                    </Modal.Footer>
                  </>
                )}
              </Modal>
              <Modal
                show={showBudget}
                onHide={() => setShowBudget(false)}
                size="lg"
              >
                {Object.keys(processSelected).length > 0 && (
                  <>
                    <Form ref={formRef} onSubmit={handleSubmit}>
                      <Modal.Header className="border-0 pt-4 px-5 mt-2 align-items-center justify-content-between">
                        <div className="container">
                          <div className="row">
                            <div className="col-12">
                              <div className="d-flex align-items-start justify-content-between">
                                <Modal.Title className="text-secondary fw-semibold mb-0">
                                  {processSelected.title} -{' '}
                                  <span className="text-uppercase">
                                    {params.nursery.replaceAll('-', ' ')}
                                  </span>
                                </Modal.Title>
                                <button
                                  type="button"
                                  className="bg-transparent border-0"
                                  onClick={() => setShowBudget(false)}
                                >
                                  <MdClose size={30} color="#202020" />
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Modal.Header>
                      <Modal.Body className="px-5 my-3">
                        <div className="container">
                          <div className="row">
                            <div className="col-12">
                              <label className="mb-3 w-100">
                                <span className="d-block mb-2 text-secondary fw-medium small normal-sm">
                                  CNPJ
                                </span>
                                <InputMask
                                  kind="cnpj"
                                  name="cnpj"
                                  placeholder="Digite aqui"
                                  onChange={handleChangeCnpj}
                                />
                              </label>
                            </div>
                            <div className="col-12">
                              <label className="mb-3 w-100">
                                <span className="d-block mb-2 text-secondary fw-medium small normal-sm">
                                  Razão social
                                </span>
                                <Input
                                  name="companyName"
                                  placeholder="Digite aqui"
                                  onChange={(e) =>
                                    setCompanyName(e.target.value)
                                  }
                                  value={companyName}
                                />
                              </label>
                            </div>
                            <div className="col-12">
                              <label className="mb-3 w-100">
                                <span className="d-block mb-2 text-secondary fw-medium small normal-sm">
                                  Representante legal
                                </span>
                                <Input
                                  name="presidentName"
                                  placeholder="Digite aqui"
                                  onChange={(e) =>
                                    setPresidentName(e.target.value)
                                  }
                                  value={presidentName}
                                />
                              </label>
                            </div>
                            <div className="col-12">
                              <label className="mb-3 w-100">
                                <span className="d-block mb-2 text-secondary fw-medium small normal-sm">
                                  E-mail
                                </span>
                                <Input
                                  type="email"
                                  name="email"
                                  placeholder="Digite aqui"
                                  onChange={(e) => setEmail(e.target.value)}
                                  value={email}
                                />
                              </label>
                            </div>
                            <div className="col-12">
                              <label className="mb-3 w-100">
                                <span className="d-block mb-2 text-secondary fw-medium small normal-sm">
                                  Telefone
                                </span>
                                <InputMask
                                  kind="custom"
                                  options={{
                                    mask: '(99)99999-9999',
                                  }}
                                  name="phone"
                                  placeholder="Digite aqui"
                                  onChange={(e) => setPhone(e.target.value)}
                                  value={phone}
                                />
                              </label>
                            </div>
                            <div className="col-12">
                              <label className="mb-3 w-100">
                                <span className="d-block mb-2 text-secondary fw-medium small normal-sm">
                                  Documento
                                </span>
                                <InputFile
                                  name="quotation"
                                  onChange={handleChangeQuotation}
                                  className="input-file"
                                />
                              </label>
                            </div>
                          </div>
                        </div>
                      </Modal.Body>
                      <Modal.Footer className="border-0 pb-4 px-5 mb-2 justify-content-end">
                        <button
                          type="submit"
                          className="h6 text-white fw-normal border-0 rounded-pill btn-signup mb-0 mx-2 d-flex align-items-center justify-content-center"
                        >
                          Enviar
                        </button>
                      </Modal.Footer>
                    </Form>
                  </>
                )}
              </Modal>
              <Modal
                show={showCompleteRegister}
                size="lg"
                onHide={() => setShowCompleteRegister(false)}
              >
                <Modal.Header className="justify-content-center border-0 px-3 px-sm-5 pb-0 pb-0 pt-4">
                  <div className="animation pe-none mt-4 d-flex flex-column align-items-center overflow-hidden w-100 px-sm-4">
                    <Lottie
                      options={{
                        animationData: profile,
                        autoplay: true,
                        loop: true,
                        rendererSettings: {
                          preserveAspectRatio: 'xMidYMid slice',
                        },
                      }}
                      width={164}
                    />
                    <Modal.Title className="modal-cache-title mt-4">
                      Espere um pouco {supplierName}!
                    </Modal.Title>
                  </div>
                </Modal.Header>
                <Modal.Body className="pt-0 pt-sm-4 px-3 px-sm-5">
                  <p className="text-center">
                    Complete o seu cadastro clicando no botão abaixo para ter
                    acesso a nossa plataforma e ver o andamento de seus
                    processos
                  </p>
                </Modal.Body>
                <Modal.Footer className="border-0 px-3 px-sm-5 py-4 mb-2 mb-sm-4 justify-content-around">
                  <Link to={url} className="btn btn-primary px-5 py-3">
                    COMPLETAR CADASTRO
                  </Link>
                </Modal.Footer>
              </Modal>
            </div>
          </div>
        </div>
      </div>
      <Loading
        active={loading}
        text={loadingMessage}
        className="loading-text-white"
      />
    </Container>
  );
};

export default SupplierChannel;
