import styled from 'styled-components';
import banner from '~/assets/defaults/bg-transparency-rh.png';

export const Container = styled.div`
  font-family: 'Poppins';
  h1 {
    margin-top: 122px;
    color: #202020;
  }

  p {
    color: #707070;
  }
  h2 {
    color: #202020;
    border-bottom: 2px solid #e9e8e8;
  }

  a {
    line-height: 45px;
    :hover {
      color: #fff;
    }
  }

  .bg-buy {
    background: url(${banner}),
      linear-gradient(180deg, #224b8f 0%, #224b8f 100%);
    background-size: cover;
    background-position: bottom center;
    background-repeat: no-repeat;
    border-radius: 8px;
    height: 26vw;
    min-width: 290px;
    max-height: 225px;
    min-height: 190px;
    margin-left: 10px;
    margin-right: 10px;
  }

  .bg-rh {
    background: url(${banner}),
      linear-gradient(180deg, #0c7a6a 0%, #0c7a6a 100%);
    background-size: cover;
    background-position: bottom center;
    background-repeat: no-repeat;
    border-radius: 8px;
    height: 26vw;
    min-width: 290px;
    max-height: 225px;
    min-height: 190px;
    margin-left: 10px;
    margin-right: 10px;
  }

  .bg-practices {
    background: url(${banner}),
      linear-gradient(180deg, #b76522 0%, #b76522 100%);
    background-size: cover;
    background-position: bottom center;
    background-repeat: no-repeat;
    border-radius: 8px;
    height: 26vw;
    min-width: 290px;
    max-height: 225px;
    min-height: 190px;
    margin-left: 10px;
    margin-right: 10px;
  }

  .all::-webkit-scrollbar-thumb {
    background-color: transparent !important;
    border-radius: 4px;
  }

  @media screen and (min-width: 992px) {
    .bg-buy,
    .bg-rh {
      min-width: auto;
      width: 31%;
      margin-left: 10px;
      margin-right: 10px;
    }

    h1 {
      margin-top: 22px;
    }
  }

  @media screen and (min-width: 1400px) {
    h1 {
      margin-top: 122px;
    }
  }
`;
