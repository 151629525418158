import styled from 'styled-components';

interface IThumbnail {
  src: string;
  height: number;
}

export const Container = styled.div`
  h1 {
    margin-top: 122px;
  }

  .bg-main {
    border-radius: 16px;
    border: 1px solid #e8e8e8;

    background: #f9f9f9;
  }
  .tag {
    color: #6941c6;
    font-size: 14px;
    font-weight: 500;
    padding: 2px 10px;
    border-radius: 16px;
    background: #f9f5ff;
    width: fit-content;
  }

  @media screen and (min-width: 992px) {
    .old-notices {
      > div {
        + div {
          border-top: 1px solid #d4d4d4;
        }
      }
    }

    h1 {
      margin-top: 22px;
    }
  }

  @media screen and (min-width: 1400px) {
    h1 {
      margin-top: 122px;
    }
  }
`;

export const Thumbnail = styled.div<IThumbnail>`
  border-radius: 8px;
  background-image: url(${(props) => props.src});
  background-position: center;
  background-size: cover;
  width: 100%;
  height: ${(props) => props.height}px;
`;
