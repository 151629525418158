import React from 'react';
import { Switch } from 'react-router-dom';

import Route from './Route';

import Home from '~/pages/Nursery/Home';
import AboutUs from '~/pages/Nursery/AboutUs';
import Projects from '~/pages/Nursery/Projects';
import Galleries from '~/pages/Nursery/Galleries';
import Gallery from '~/pages/Nursery/Gallery';
import Notices from '~/pages/Notices';
import Notice from '~/pages/Notice';
import Menus from '~/pages/Nursery/Menus';
import Contact from '~/pages/Nursery/Contact';
import WorkUs from '~/pages/WorkUs';

const NurseryRoutes: React.FC = () => {
  return (
    <Switch>
      <Route
        path={`${process.env.PUBLIC_URL}/maternais/:slug`}
        exact
        component={Home}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/maternais/:slug/sobre`}
        exact
        component={AboutUs}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/maternais/:slug/projetos`}
        exact
        component={Projects}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/maternais/:slug/galerias`}
        exact
        component={Galleries}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/maternais/:slug/galerias/:gallerySlug`}
        exact
        component={Gallery}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/maternais/:slug/noticias`}
        exact
        component={Notices}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/maternais/:slug/noticias/:noticeSlug`}
        exact
        component={Notice}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/maternais/:slug/cardapios`}
        exact
        component={Menus}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/maternais/:slug/fale-conosco`}
        exact
        component={Contact}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/maternais/:slug/faca-parte-da-equipe`}
        exact
        component={WorkUs}
      />
    </Switch>
  );
};

export default NurseryRoutes;
