import React, { useCallback, useRef, useState } from 'react';
import { Form } from '@unform/web';
import { Link, useLocation } from 'react-router-dom';

import { RiArrowLeftSLine } from 'react-icons/ri';
import Lottie from 'react-lottie';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import Swal from 'sweetalert2';

import getValidationErros from '~/utils/getValidationsErrors';

import { Container } from './styles';
import Input from '~/components/Input';

import logo from '~/assets/logos/logo.svg';
import emailSent from '~/assets/animations/email-sent.json';
import signinBackground from '~/assets/banners/signin-background.svg';
import api from '~/services/api';

const ForgotPassword: React.FC = () => {
  const location = useLocation();
  const formRef = useRef<FormHandles>(null);
  const [mailSent, setMailSent] = useState(false);
  const supplier = location.pathname.includes('fornecedor');

  const handleSubmit = useCallback(async (data) => {
    try {
      formRef.current?.setErrors({});

      const schema = Yup.object().shape({
        email: Yup.string()
          .email('Insira um e-mail válido')
          .required('O e-mail é obrigatório'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      api.post('suppliers/sessions/forgot-password', data);

      setMailSent(true);
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErros(error);
        formRef.current?.setErrors(errors);
      } else {
        console.log('');
        console.log('ForgotPassword');
        console.log(error);
        Swal.fire('Oops...', 'Ocorreu um erro tente novamente, por favor');
      }
    }
  }, []);

  return (
    <Container>
      <div className="container py-lg-5">
        <div className="row justify-content-between">
          <div className="col-lg-5 px-4 px-lg-3 pt-5">
            <Link
              to={`${process.env.PUBLIC_URL}/${
                supplier ? 'fornecedor' : 'prestador'
              }/login`}
              className="d-flex d-lg-none align-items-center logo mb-5"
            >
              <img src={logo} alt="logo" className="me-2" />
              <span className="text-secondary text-start d-block small mb-0 fw-bold">
                INSTITUTO <br />
                <span className="d-block h4 mb-0 fw-bold mt-n1">BARUERI</span>
              </span>
            </Link>
            {!mailSent ? (
              <>
                <div className="w-100">
                  <Link
                    to={`${process.env.PUBLIC_URL}/${
                      supplier ? 'fornecedor' : 'prestador'
                    }/login`}
                    className="mb-3 d-inline-flex"
                  >
                    <RiArrowLeftSLine size={44} color="#707070" />
                  </Link>
                  <h1 className="h4 h3-lg text-secondary">Esqueceu a senha?</h1>
                  <p className="h5 fw-light text-secondary">
                    Para restaurar a senha, insira seu e-mail
                  </p>
                </div>
                <Form ref={formRef} onSubmit={handleSubmit} className="mt-5">
                  <label className="d-block w-100 mb-3">
                    <span className="text-secondary fw-medium mb-2 d-block">
                      E-mail
                    </span>
                    <Input
                      type="email"
                      name="email"
                      placeholder="Insira seu e-mail"
                    />
                  </label>
                  <button
                    type="submit"
                    className="btn btn-primary rounded-pill w-100 py-2 mt-5"
                  >
                    Continuar
                  </button>
                </Form>
              </>
            ) : (
              <>
                <div className="w-100">
                  <h1 className="h4 h3-lg text-secondary">
                    E-mail enviado com sucesso
                  </h1>
                  <p className="h5 fw-light text-secondary">
                    Em instantes você receberá um e-mail para recuperar sua
                    senha.
                  </p>
                  <p className="h5 fw-light text-secondary">
                    Verifique sua caixa de entradda, spam ou lixo eletrônico.
                  </p>
                </div>
                <div className="w-100">
                  <div className="my-5 pt-4 pb-3 pe-none">
                    <Lottie
                      options={{
                        animationData: emailSent,
                        autoplay: true,
                        loop: true,
                        rendererSettings: {
                          preserveAspectRatio: 'xMidYMid slice',
                        },
                      }}
                      width={206}
                      height={176}
                    />
                  </div>
                  <Link
                    to={`${process.env.PUBLIC_URL}/${
                      supplier ? 'fornecedor' : 'prestador'
                    }/login`}
                    className="btn btn-primary rounded-pill py-2 w-100"
                  >
                    Ir para o login
                  </Link>
                </div>
              </>
            )}
          </div>
          <div className="col-lg-5 d-none d-lg-block">
            <img src={signinBackground} alt="" className="w-100" />
          </div>
        </div>
      </div>
    </Container>
  );
};

export default ForgotPassword;
