import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Modal as ModalComponent } from 'react-bootstrap';

export const Container = styled.div`
  font-family: 'Poppins';
  h1 {
    margin-top: 122px;
    color: #202020;
  }

  h2 {
    color: #202020;
  }
  h5 {
    color: #4f2e14;
  }
  span {
    color: #4f2e14;
  }
  p {
    color: #707070;
  }

  .bg-see-more {
    background: #a97715;
    border-radius: 0 0 16px 16px;
    img {
      width: 24px;
      height: 24px;
      background-color: #4f450e;
      border-radius: 20px;
      padding: 5px;
    }
  }

  .bg-vagancies {
    border-radius: 16px;
    border: 1px solid #e8e8e8;
    background: #f9f9f9;
    padding: 60px 30px;
  }

  .arrow {
    border-radius: 33px;
    background: #e7e7e7;
    height: 32px;
    width: 32px;
    img {
      width: 15px;
      height: 12px;
    }
  }

  .bg-card {
    border-radius: 16px;
    background: #ffe087;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    height: 216px;
  }

  .btn-pagination {
    display: flex;
    width: 37px;
    height: 37px;
    padding: 0;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    border: 1px solid transparent;
    font-size: 14px;
    font-weight: 700;
    background-color: transparent;

    + .btn-pagination {
      margin-left: 10px;
    }
  }

  .btn-pagination.arrow,
  .btn-pagination.selected,
  .btn-pagination:not(.ellipsis):hover {
    border: 1px solid #707070;
  }

  @media screen and (min-width: 400px) {
    .btn-pagination {
      width: 45px;
      height: 45px;
      font-size: 16px;
    }
  }

  @media screen and (min-width: 992px) {
    .arrow {
      height: 38px;
      width: 38px;
    }

    h1 {
      margin-top: 22px;
    }

    .btn-pagination {
      width: 64px;
      height: 64px;
      padding: 20px;
      font-size: 18px;

      + .btn-pagination {
        margin-left: 20px;
      }
    }
  }

  @media screen and (min-width: 1400px) {
    h1 {
      margin-top: 122px;
    }
  }
`;

export const Modal = styled(ModalComponent)`
  background: rgba(0, 0, 0, 0.3);

  p {
    font-weight: 500;
  }

  .modal-content {
    border-radius: 32px;
    border: 1px solid #f0efef;
    background: #fff;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1);
  }

  .input-radio {
    label {
      margin-left: 16px !important;

      > div {
        min-width: 24px;
        min-height: 24px;
        border-radius: 50%;

        > svg {
          width: 12px;
          height: 12px;
          background-color: #fff;
          border-radius: 50%;
        }
      }

      span {
        line-height: 21px;
      }

      span span {
        color: #aaa;
        font-size: 14px;
        font-weight: 500;
      }
    }
  }

  .box {
    label:not(.checkboxInput-label) span {
      color: #202020;
      font-weight: 500;
      margin-bottom: 8px;
      display: block;
    }

    + label {
      margin-top: 32px;
    }
  }

  label.focused {
    > div {
      border-color: #202020 !important;
    }
  }

  .input-file {
    position: relative;

    .error-box {
      position: absolute;
      bottom: -35px;
    }
  }

  .input-checkbox {
    flex-direction: column;
    justify-content: start;
    align-items: start;

    label {
      margin-left: 0 !important;
      margin-bottom: 16px !important;

      > div {
        min-width: 24px;
        min-height: 24px;
        border-radius: 4px;

        > svg {
          width: 18px;
          height: 18px;
        }
      }

      span {
        line-height: 21px;
      }
      span span {
        color: #aaa;
        font-size: 14px;
        font-weight: 500;
      }
    }
  }
`;
