import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 30px;

  .box {
    border-radius: 46px;
    border: 1px solid #e3dede;
    background: #f9f9f9;

    .sub-box {
      border-radius: 24px;
      background: #fff;

      label:not(.checkboxInput-label) {
        span {
          color: #202020;
          font-weight: 500;
          margin-bottom: 8px;
          display: block;
        }

        + label {
          margin-top: 32px;
        }
      }

      label.focused {
        > div {
          border-color: #202020 !important;
        }
      }

      .input-file {
        position: relative;

        .error-box {
          position: absolute;
          bottom: -35px;
        }
      }

      .input-checkbox {
        flex-direction: column;
        justify-content: start;
        align-items: start;

        label {
          margin-left: 0 !important;
          margin-bottom: 16px !important;

          > div {
            width: 24px;
            height: 24px;
            border-radius: 4px;

            > svg {
              width: 18px;
              height: 18px;
            }
          }
        }
      }
    }
  }

  @media screen and (min-width: 992px) {
    margin-top: 0;
  }
`;
