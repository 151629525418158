import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { TiArrowLeft, TiArrowRight } from 'react-icons/ti';
import { format, parseISO } from 'date-fns';
import List from 'react-chatview';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import { FormHandles } from '@unform/core';
import { IoDocumentText } from 'react-icons/io5';
import { MdClose } from 'react-icons/md';
import { Link, useLocation, useParams } from 'react-router-dom';

import api from '~/services/api';
import getValidationErros from '~/utils/getValidationsErrors';

import { Container, Modal } from './styles';
import InputFile from '~/components/InputFile';
import { useAuth } from '~/hooks/Auth';
import Toast from '~/utils/toast';
import Loading from '~/components/Loading';
import Nurseries, { INursery } from '../Nurseries';

interface IArchive {
  id: string;
  title: string;
  archive_url: string;
}

interface IQuotation {
  id: string;
}

interface IProcess {
  id: number;
  title: string;
  status: 'Em aberto' | 'Em andamento' | 'Finalizado';
  created_at: string;
  archives: IArchive[];
  quotations: IQuotation[];
}

interface IProcessNursery {
  process: IProcess;
}

interface IProcessResponse {
  data: IProcessNursery[];
  from: number;
  to: number;
  total: number;
  last_page: number;
}

interface IFormData {
  cnpj: string;
}

interface IParams {
  nursery?: string;
}

const OpenProcesses: React.FC = () => {
  const location = useLocation();
  const params = useParams<IParams>();
  const formRef = useRef<FormHandles>(null);
  const { user } = useAuth();
  const [recents, setRecents] = useState<IProcess[]>([]);
  const [processes, setProcesses] = useState<IProcess[]>([]);
  const [pageSelected, setPageSelected] = useState(1);
  const [total, setTotal] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [processSelected, setProcessSelected] = useState({} as IProcess);
  const [show, setShow] = useState(false);
  const [showSendQuotation, setShowSendQuotation] = useState(false);
  const [quotation, setQuotation] = useState<File | undefined>();
  const [loading, setLoading] = useState(false);
  const [nursery, setNursery] = useState({} as INursery);

  const type = useMemo(
    () =>
      location.pathname.includes('fornecedor') ? 'fornecedor' : 'prestador',
    [location.pathname]
  );

  const handleLoadProcesses = useCallback(
    async (page, nurseryId) => {
      const response = await api.get<IProcessResponse>(
        `processes-nurseries/nurseries/${nurseryId}`,
        {
          params: {
            status: 'Em aberto',
            page,
            isSupplier: location.pathname.includes('fornecedor'),
            supplier_id: user.id,
          },
        }
      );
      const data = response.data.data.map((processNursery) => ({
        id: processNursery.process.id,
        title: processNursery.process.title,
        status: processNursery.process.status,
        created_at: format(
          parseISO(processNursery.process.created_at),
          'dd/MM/yyyy'
        ),
        archives: [],
        quotations: [],
      }));

      if (page === 1) {
        setRecents(data.slice(0, 3));
        setProcesses(data.slice(3));
      } else {
        setProcesses((state) => [...state, ...data]);
      }
      setTotal(response.data.total);
      setTotalPages(response.data.last_page);
    },
    [location.pathname, user.id]
  );

  useEffect(() => {
    if (params.nursery) {
      api
        .get(`nurseries/${params.nursery}`)
        .then(async (response) => {
          await handleLoadProcesses(1, response.data.id);
          setNursery(response.data);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setNursery({} as INursery);
      setProcesses([]);
    }
  }, [handleLoadProcesses, params.nursery]);

  const handleLoad = useCallback(async () => {
    if (pageSelected < totalPages) {
      await handleLoadProcesses(pageSelected + 1, nursery.id);
      setPageSelected(pageSelected + 1);
    }
  }, [handleLoadProcesses, nursery.id, pageSelected, totalPages]);

  const handleClickProcess = useCallback(
    async (process_id) => {
      const response = await api.get(`processes/${process_id}`, {
        params: {
          supplier_id: user.id,
        },
      });
      setProcessSelected({
        ...response.data,
        archives: response.data.archives.map((archive: IArchive) => {
          if (archive.title === 'Documento') {
            return {
              ...archive,
              title: 'Clique para visualizar a Solicitação de Orçamento',
            };
          }
          return archive;
        }),
      });
      setShow(true);
    },
    [user.id]
  );

  const handleClose = useCallback(() => {
    setShow(false);
  }, []);

  const handleClickSendQuotation = useCallback(() => {
    if (processSelected.quotations?.length > 0) {
      Swal.fire({
        title: 'Atenção!',
        html: `<small class="w-75 d-block mx-auto">Você já enviou ${processSelected.quotations.length
          .toString()
          .padStart(
            2,
            '0'
          )} orçamento(s) para este processo. Gostaria de enviar mais um?</small>`,
        icon: 'warning',
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: 'Sim',
        confirmButtonColor: '#AD2D03',
        cancelButtonColor: '#F15A29',
        cancelButtonText: 'Não',
        reverseButtons: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          setShowSendQuotation(true);
        }
      });
    } else {
      setShowSendQuotation(true);
    }
  }, [processSelected.quotations]);

  const handleCloseSendQuotation = useCallback(() => {
    setShowSendQuotation(false);
  }, []);

  const handleChangeQuotation = useCallback((files: File[]) => {
    setQuotation(files[0]);
  }, []);

  const handleSubmit = useCallback(
    async (data: IFormData) => {
      try {
        setLoading(true);
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          quotation: Yup.string().when('$quotationCheck', {
            is: (quotationCheck: boolean) => !quotationCheck,
            then: Yup.string().required(
              'O documento do orçamento é obrigatório'
            ),
            otherwise: Yup.string(),
          }),
        });

        await schema.validate(data, {
          abortEarly: false,
          context: {
            quotation: !quotation,
          },
        });

        const formData = new FormData();
        formData.append('quotation', quotation as File);
        formData.append('process_id', processSelected.id.toString());
        formData.append('supplier_id', user.id.toString());
        formData.append('nursery_id', nursery.id.toString());
        formData.append('title', `Orçamento: ${user.company}`);

        await api.post('quotations', formData);

        Toast.fire({
          icon: 'success',
          title: 'Orçamento enviado!',
        });

        handleCloseSendQuotation();
        handleClose();
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          formRef.current?.setErrors(errors);
        } else {
          console.log('');
          console.log('OpenProcesses');
          console.log(error);
          Swal.fire('Oops...', 'Ocorreu um erro tente novamente, por favor');
        }
      } finally {
        setLoading(false);
      }
    },
    [
      handleClose,
      handleCloseSendQuotation,
      nursery.id,
      processSelected.id,
      quotation,
      user,
    ]
  );

  return (
    <Container>
      <div className="container">
        {params.nursery ? (
          <>
            <div className="row mb-3 mb-lg-5">
              <div className="col-12 px-4 px-lg-3 mt-5 mb-3 my-lg-5 d-lg-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center mb-4 mb-lg-0">
                  <Link
                    to={`${process.env.PUBLIC_URL}/${type}/processos-abertos`}
                    className="btn-back d-flex align-items-center justify-content-center me-2"
                  >
                    <TiArrowLeft size={28} color="#202020" />
                  </Link>
                  <h1 className="h4 h2-lg text-secondary fw-semibold mb-0">
                    Processos Abertos: {nursery.name}
                  </h1>
                </div>
                <p className="process-qtd px-3 py-2 text-center">
                  Quantidade: <b>{total.toString().padStart(2, '0')}</b>{' '}
                  Processos
                </p>
              </div>
              <div className="col-12 px-4 px-lg-3">
                <p className="h4 mb-3">Recentes</p>
              </div>
              {recents.map((processData) => (
                <button
                  key={processData.id}
                  type="button"
                  className="col-lg-4 px-4 px-lg-3 border-0 bg-transparent text-start"
                  onClick={() => handleClickProcess(processData.id)}
                >
                  <div className="box-process">
                    <div className="d-flex justify-content-lg-end px-4 pt-4">
                      <p className="fw-medium text-secondary tag rounded-pill px-3 py-1">
                        Em aberto
                      </p>
                    </div>
                    <div className="pt-lg-5 px-4 pb-4">
                      <p className="text-white h5 fw-semibold">
                        {processData.title}
                      </p>
                    </div>
                    <div className="d-flex align-items-center justify-content-end px-4 py-3 see-process">
                      <p className="text-white mb-0 me-2">Ver mais</p>
                      <div>
                        <TiArrowRight size={20} color="#fff" />
                      </div>
                    </div>
                  </div>
                </button>
              ))}
            </div>
            <div className="row">
              <div className="col-12 px-4 px-lg-3">
                <p className="h4 mt-5 mb-4">Lista dos processos</p>
              </div>
              <List
                className="col-12 px-4 px-lg-3 mt-4 mb-5 quotations"
                scrollLoadThreshold={100}
                onInfiniteLoad={handleLoad}
              >
                {processes.map((processData) => (
                  <div
                    key={processData.id}
                    className="d-flex align-items-center quotation-box py-2 px-4"
                  >
                    <div className="detail me-3" />
                    <div className="d-flex flex-wrap justify-content-between align-items-center quotation-data">
                      <p className="h6 h5-lg mb-0 text-secondary fw-semibold title mb-3 mb-lg-0 py-2">
                        {processData.title}
                      </p>
                      <div className="d-flex justify-content-lg-end tag-box mx-lg-3">
                        <p className="fw-medium mb-0 px-3 px-lg-4 py-1 py-lg-2 mb-0 text-secondary tag open">
                          Em aberto
                        </p>
                      </div>
                      <button
                        type="button"
                        className="btn rounded-pill btn-see-more p-0 py-lg-2 px-lg-4 d-flex justify-content-center align-items-center"
                        onClick={() => handleClickProcess(processData.id)}
                      >
                        <p className="mb-0 me-lg-2 text-white fw-semibold d-none d-lg-block">
                          Ver mais
                        </p>
                        <div>
                          <TiArrowRight size={20} color="#fff" />
                        </div>
                      </button>
                    </div>
                  </div>
                ))}
              </List>
            </div>
          </>
        ) : (
          <div className="row">
            <div className="col-12 px-4 px-lg-3 mt-5 mb-3 my-lg-5 d-lg-flex justify-content-between align-items-center">
              <h1 className="h4 h2-lg text-secondary fw-semibold mb-4 mb-lg-0">
                Processos Abertos
              </h1>
            </div>
            <Nurseries page="processos-abertos" />
          </div>
        )}
      </div>
      <Modal show={show} onHide={handleClose} size="lg">
        {Object.keys(processSelected).length > 0 && (
          <>
            <Modal.Header className="border-0 pt-4 px-5 mt-2 align-items-center justify-content-between">
              <Modal.Title className="text-secondary fw-semibold mb-0">
                {processSelected.title}
              </Modal.Title>
              <button
                type="button"
                className="bg-transparent border-0"
                onClick={handleClose}
              >
                <MdClose size={40} color="#202020" />
              </button>
            </Modal.Header>
            <Modal.Body className="px-5 my-3">
              <div className="documents">
                {processSelected.archives.map((archive) => (
                  <a
                    href={archive.archive_url}
                    target="_blank"
                    rel="noreferrer"
                    className="btn btn-archive mb-3 d-flex align-items-center p-3"
                  >
                    <IoDocumentText
                      size={24}
                      color="#4A4747"
                      className="me-2"
                    />{' '}
                    {archive.title}
                  </a>
                ))}
              </div>
            </Modal.Body>
            <Modal.Footer className="border-0 pb-4 px-5 mb-2">
              <button
                type="button"
                className="btn btn-primary rounded-pill py-2 px-4"
                onClick={handleClickSendQuotation}
              >
                Enviar orçamento
              </button>
            </Modal.Footer>
          </>
        )}
      </Modal>
      <Modal
        show={showSendQuotation}
        onHide={handleCloseSendQuotation}
        size="lg"
      >
        {Object.keys(processSelected).length > 0 && (
          <Form ref={formRef} onSubmit={handleSubmit}>
            <Modal.Header className="border-0 pt-4 px-5 mt-2 align-items-center justify-content-between">
              <Modal.Title className="text-secondary fw-semibold mb-0">
                Enviar orçamento: {processSelected.title}
              </Modal.Title>
              <button
                type="button"
                className="bg-transparent border-0"
                onClick={handleCloseSendQuotation}
              >
                <MdClose size={40} color="#202020" />
              </button>
            </Modal.Header>
            <Modal.Body className="px-5">
              <label>Orçamento:</label>
              <InputFile
                name="quotation"
                onChange={handleChangeQuotation}
                className="input-file mt-2"
              />
            </Modal.Body>
            <Modal.Footer className="border-0 pb-4 px-5 mb-2">
              <button
                type="submit"
                className="btn btn-primary rounded-pill py-2 px-4"
              >
                Enviar
              </button>
            </Modal.Footer>
          </Form>
        )}
      </Modal>
      <Loading active={loading} text="Enviando seu orçamento..." />
    </Container>
  );
};

export default OpenProcesses;
