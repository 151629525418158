import React from 'react';

import { Container } from './styles';

import purchasingRegulationsMarioIto from '~/assets/documents/regulamento-compras-mario-ito.pdf';
import purchasingRegulationsMarioBezerra from '~/assets/documents/regulamento-de-compras-mario-bezerra.pdf';
import rhRegulationsMarioIto from '~/assets/documents/regulamento-rh-mario-ito.pdf';
import rhRegulationsMarioBezerra from '~/assets/documents/regulamento-de-rh-mario-bezerra.pdf';
import goodPractices from '~/assets/documents/boas-praticas-no-momento-das-refeicoes-instituto-barueri.pdf';

const Regulations: React.FC = () => {
  return (
    <Container>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h1 className="h4 h1-lg mb-4 mb-lg-5">Regulamentos</h1>
            <p className="h6 h4-lg fw-light mb-4 mb-lg-5 pb-lg-5">
              O Instituto Barueri, em sua atuação, busca sempre trabalhar com
              total transparência. A seguir, apresentamos as políticas para a
              contratação de serviços e compras:
            </p>
          </div>
        </div>
        <div className="row mb-5 pb-lg-4">
          <div className="col-12">
            <h2 className="h5 h4-lg px-4 py-3 mb-3">Manual de compras</h2>
          </div>
          <div className="col-12 d-flex overflow-auto all">
            <div className="bg-buy mt-4 d-flex">
              <a
                href={purchasingRegulationsMarioIto}
                className="h5 h3-lg fw-bold text-white w-100 d-flex flex-column justify-content-center px-4 mb-0"
                target="_blank"
                rel="noreferrer"
              >
                <span className="fw-normal">Manual de </span>
                <span>Compras - Mário Ito</span>
              </a>
            </div>

            <div className="bg-buy mt-4 d-flex">
              <a
                href={purchasingRegulationsMarioBezerra}
                className="h5 h3-lg fw-bold text-white w-100 d-flex flex-column justify-content-center px-4 mb-0"
                target="_blank"
                rel="noreferrer"
              >
                <span className="fw-normal">Manual de </span>
                <span>Compras - Mário Bezerra</span>
              </a>
            </div>
          </div>
        </div>

        <div className="row mb-5 pb-lg-4">
          <div className="col-12">
            <h2 className="h5 h4-lg px-4 py-3 mb-3">Manual de RH</h2>
          </div>
          <div className="col-12 d-flex overflow-auto all">
            <div className="bg-rh mt-4 d-flex">
              <a
                href={rhRegulationsMarioIto}
                className="h5 h3-lg fw-bold text-white w-100 d-flex flex-column justify-content-center px-4 mb-0"
                target="_blank"
                rel="noreferrer"
              >
                <span className="fw-normal">Manual de </span>
                <span>RH - Mário Ito</span>
              </a>
            </div>

            <div className="bg-rh mt-4 d-flex">
              <a
                href={rhRegulationsMarioBezerra}
                className="h5 h3-lg fw-bold text-white w-100 d-flex flex-column justify-content-center px-4 mb-0"
                target="_blank"
                rel="noreferrer"
              >
                <span className="fw-normal">Manual de </span>
                <span>RH - Mário Bezerra</span>
              </a>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <h2 className="h5 h4-lg px-4 py-3 mb-3">Manual de boas práticas</h2>
          </div>
          <div className="col-12 px-4 mb-5">
            <div className="row">
              <div className="col-lg-4">
                <div className="bg-practices mt-4 d-flex">
                  <a
                    href={goodPractices}
                    className="h5 h3-lg fw-bold text-white w-100 d-flex flex-column justify-content-center px-4 mb-0"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span className="fw-normal">Manual de </span>
                    <span>Boas práticas</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Regulations;
