import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Form } from '@unform/web';
import Lottie from 'react-lottie';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import Swal from 'sweetalert2';

import getValidationErros from '~/utils/getValidationsErrors';

import { Container } from './styles';
import Input from '~/components/Input';
import InputMask from '~/components/InputMask';
import Select, { IOption } from '~/components/Select';
import InputCheckbox from '~/components/InputCheckbox';
import InputFile from '~/components/InputFile';

import emailSent from '~/assets/animations/email-sent.json';
import api from '~/services/api';
import Loading from '~/components/Loading';

interface IParams {
  slug: string;
}

interface INursery {
  id: number;
  name: string;
  label: string;
  value: string;
  selected: boolean;
  slug: string;
}

interface INurseryData {
  data: INursery[];
  from: number;
  to: number;
  total: number;
  current_page: number;
}

interface IFormData {
  name: string;
  email: string;
  phone: string;
  interestArea: string;
  interestUnits: string;
}

const WorkUs: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const params = useParams<IParams>();
  const [nurseries, setNurseries] = useState<INursery[]>([]);
  const [interestUnits, setInterestUnits] = useState('');
  const [curriculum, setCurriculum] = useState<File | undefined>();
  const [curriculumSent, setCurriculumSent] = useState(false);
  const [loading, setLoading] = useState(false);
  const [interestAreaSelected, setInterestAreaSelected] = useState('');

  useEffect(() => {
    api.get<INurseryData>('nurseries').then((response) => {
      const data = response.data.data.map((nursery) => ({
        id: nursery.id,
        name: nursery.name,
        label: nursery.name,
        value: nursery.name,
        selected: nursery.slug === params.slug,
        slug: nursery.slug,
      }));

      const selectedUnits = data
        .filter((unit) => unit.selected)
        .map((unit) => unit.name);
      setInterestUnits(selectedUnits.join(', '));

      setInterestUnits(selectedUnits.join(', '));
      setNurseries(data);
    });
  }, [params.slug]);

  const handleFocus = useCallback((e) => {
    if (e.target.parentNode.parentNode.parentNode.tagName === 'LABEL') {
      e.target.parentNode.parentNode.parentNode.classList.add('focused');
    } else if (e.target.parentNode.parentNode.tagName === 'LABEL') {
      e.target.parentNode.parentNode.classList.add('focused');
    }
  }, []);
  const handleBlur = useCallback((e) => {
    if (e.target.parentNode.parentNode.parentNode.tagName === 'LABEL') {
      const type =
        e.target.parentNode.parentNode.parentNode.getAttribute('aria-details');
      if (type === 'select') {
        setTimeout(() => {
          e.target.parentNode.parentNode.parentNode.classList.remove('focused');
        }, 300);
      } else {
        e.target.parentNode.parentNode.parentNode.classList.remove('focused');
      }
    } else if (e.target.parentNode.parentNode.tagName === 'LABEL') {
      e.target.parentNode.parentNode.classList.remove('focused');
    }
  }, []);

  const handleChageInterestArea = useCallback((interestAreaData) => {
    setInterestAreaSelected(interestAreaData.id);
  }, []);

  const handleChangeInterestUnits = useCallback((units: INursery[]) => {
    const selectedUnits = units
      .filter((unit) => unit.selected)
      .map((unit) => unit.name);
    setInterestUnits(selectedUnits.join(', '));
  }, []);

  const handleChangeCurriculum = useCallback((files: File[]) => {
    setCurriculum(files[0]);
  }, []);

  const handleSubmit = useCallback(
    async (data: IFormData) => {
      try {
        setLoading(true);
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required('O nome é obrigatório'),
          email: Yup.string().required('O e-mail é obrigatória'),
          phone: Yup.string().required('O telefone/celular é obrigatória'),
          curriculum: Yup.string().when('$curriculumCheck', {
            is: (curriculumCheck: boolean) => !curriculumCheck,
            then: Yup.string().required('O currículo é obrigatório'),
            otherwise: Yup.string(),
          }),
        });

        await schema.validate(data, {
          abortEarly: false,
          context: {
            curriculum: !curriculum,
          },
        });

        const formData = new FormData();
        formData.append('candidate', data.name);
        formData.append('email', data.email);
        formData.append('phone', data.phone);
        formData.append('interest_area', data.interestArea);
        formData.append('interest_units', interestUnits);
        formData.append('curriculum', curriculum as File);

        await api.post('candidates', formData);

        window.scrollTo(0, 0);
        setCurriculumSent(true);
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          formRef.current?.setErrors(errors);
        } else {
          console.log('');
          console.log('WorkUs');
          console.log(error);
          Swal.fire('Oops...', 'Ocorreu um erro tente novamente, por favor');
        }
      } finally {
        setLoading(false);
      }
    },
    [curriculum, interestUnits]
  );

  return (
    <Container className="py-5">
      <div className="container">
        <div className="row justify-content-between align-items-center py-5">
          <div className="col-12 mb-5">
            <h1 className="h2 text-secondary mb-4">
              Faça parte da nossa equipe
            </h1>
            <p className="fs-4">
              Gostaria de trabalhar conosco? Então envie seu currículo pro meio
              do formulário abaixo. Selecione as unidades em que deseja se
              candidatar, caso surjam novas vagas, analisaremos o seu perfil
              profissional e entraremos em contato
            </p>
          </div>
          <div className="col-12 my-2">
            <div className="box py-4 px-3 p-lg-5">
              <div className="sub-box py-4 px-3 p-lg-5">
                {!curriculumSent ? (
                  <Form ref={formRef} onSubmit={handleSubmit} className="row">
                    <div className="col-12 mb-lg-5">
                      <h2 className="text-primary mb-4 mb-lg-5">Formulário</h2>
                      <hr className="d-none d-lg-block" />
                    </div>
                    <div className="col-12 mb-4">
                      <label className="d-block w-100">
                        <span>Nome</span>
                        <Input
                          name="name"
                          placeholder="ex: João Silva"
                          onFocus={handleFocus}
                          onBlur={handleBlur}
                        />
                      </label>
                    </div>
                    <div className="col-12 mb-4">
                      <label className="d-block w-100">
                        <span>E-mail</span>
                        <Input
                          type="email"
                          name="email"
                          placeholder="exemplo@gmail.com"
                          onFocus={handleFocus}
                          onBlur={handleBlur}
                        />
                      </label>
                    </div>
                    <div className="col-12 mb-4">
                      <label className="d-block w-100">
                        <span>Telefone ou celular</span>
                        <InputMask
                          kind="cel-phone"
                          name="phone"
                          placeholder="Digite o telefone/celular"
                          onFocus={handleFocus}
                          onBlur={handleBlur}
                        />
                      </label>
                    </div>
                    <div className="col-12 mb-4">
                      <label className="d-block w-100" aria-details="select">
                        <span>Selecione a área de interesse</span>
                        <Select
                          name="interestArea"
                          options={[
                            {
                              id: '',
                              value: 'Selecione',
                              selected: interestAreaSelected === '',
                              notSelectable: true,
                            },
                            {
                              id: 'Pedagógica',
                              value: 'Pedagógica',
                              selected: interestAreaSelected === 'Pedagógica',
                              notSelectable: false,
                            },
                            {
                              id: 'Administrativa',
                              value: 'Administrativa',
                              selected:
                                interestAreaSelected === 'Administrativa',
                              notSelectable: false,
                            },
                            {
                              id: 'Outros',
                              value: 'Outros',
                              selected: interestAreaSelected === 'Outros',
                              notSelectable: false,
                            },
                          ]}
                          onFocus={handleFocus}
                          onBlur={handleBlur}
                          onlySelect
                          onChange={handleChageInterestArea}
                        />
                      </label>
                    </div>
                    <div className="col-12 mb-4">
                      <label className="d-block w-100" aria-details="select">
                        <span>Selecione a(s) unidade(s) de interesse:</span>
                      </label>
                      <InputCheckbox
                        type="checkbox"
                        name="interestUnits"
                        options={nurseries}
                        className="mt-3 input-checkbox"
                        onChange={handleChangeInterestUnits}
                      />
                    </div>
                    <div className="col-12 mb-5">
                      <label className="d-block w-100" aria-details="select">
                        <span>Currículo</span>
                      </label>
                      <InputFile
                        name="curriculum"
                        onChange={handleChangeCurriculum}
                        className="input-file px-2 px-lg-0"
                      />
                    </div>
                    <div className="col-12">
                      <button
                        type="submit"
                        className="btn btn-primary rounded-pill px-5 py-2 ms-auto d-block w-100 w-lg-auto"
                      >
                        Enviar
                      </button>
                    </div>
                  </Form>
                ) : (
                  <div>
                    <h2 className="text-center text-primary">
                      Currículo enviado com sucesso
                    </h2>
                    <div className="my-5 pt-4 pb-3 pe-none">
                      <Lottie
                        options={{
                          animationData: emailSent,
                          autoplay: true,
                          loop: true,
                          rendererSettings: {
                            preserveAspectRatio: 'xMidYMid slice',
                          },
                        }}
                        width={206}
                        height={176}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Loading active={loading} />
    </Container>
  );
};

export default WorkUs;
