/* eslint-disable import/no-duplicates */
import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import PtBr from 'date-fns/locale/pt-BR';

import { RiArrowDownSLine } from 'react-icons/ri';
import { Container, Menu } from './styles';

import menusIcon from '~/assets/icons/menus.png';
import api from '~/services/api';
import { useNursery } from '~/hooks/Nursery';
import NoData from '~/components/NoData';
import Loading from '~/components/Loading';

interface IMenuResponse {
  id: number;
  month: number;
  year: number;
  archive: {
    archive_url: string;
  };
}

interface IMenu {
  id: number;
  title: string;
  color: string;
  url: string;
}

const Menus: React.FC = () => {
  const { nursery } = useNursery();
  const [menus, setMenus] = useState<IMenu[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (Object.keys(nursery).length > 0) {
      setLoading(true);
      api
        .get<IMenuResponse[]>(`menus/nurseries/${nursery.id}`)
        .then((response) => {
          const data = response.data.map<IMenu>((menu) => {
            const month = format(new Date(0, menu.month - 1), 'MMMM', {
              locale: PtBr,
            });

            const monthAux = `${month.slice(0, 1).toUpperCase()}${month.slice(
              1
            )}`;

            let color = '';
            switch (monthAux) {
              case 'Janeiro':
                color = '#22B5B9';

                break;
              case 'Fevereiro':
                color = '#7E4AC8';

                break;
              case 'Março':
                color = '#01499D';

                break;
              case 'Abril':
                color = '#108CB2';

                break;
              case 'Maio':
                color = '#D7B400';

                break;
              case 'Junho':
                color = '#CE0F3C';

                break;
              case 'Julho':
                color = '#00A189';

                break;
              case 'Agosto':
                color = '#D79B00';

                break;
              case 'Setembro':
                color = '#E7C200';

                break;
              case 'Outubro':
                color = '#E63BA2';

                break;
              case 'Novembro':
                color = '#0075FF';

                break;
              case 'Dezembro':
                color = '#F30303';

                break;

              default:
                break;
            }
            return {
              id: menu.id,
              title: `${monthAux}/${menu.year}`,
              color,
              url: menu.archive.archive_url,
            };
          });
          setMenus(data);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [nursery]);

  return (
    <Container>
      <div className="container py-5">
        <div className="row">
          <div className="col-12 mb-5 d-sm-flex align-items-center justify-content-between">
            <h1 className="text-secondary mb-4 mb-sm-0">Cardápios</h1>
            <button
              type="button"
              className="btn btn-select rounded-pill d-flex justify-content-center align-items-center text-secondary w-100 w-sm-auto"
            >
              Selecione o ano: <b className="mx-2 mb-0">2023</b>{' '}
              <RiArrowDownSLine size={24} color="#202020" />
            </button>
          </div>
          {menus.length > 0 ? (
            <>
              {menus.map((menu) => (
                <div key={menu.id} className="col-lg-4 mb-4">
                  <a href={menu.url} target="_blank" rel="noreferrer">
                    <Menu
                      bgcolor={menu.color}
                      className="p-4 d-flex align-items-end"
                    >
                      <div>
                        <img
                          src={menusIcon}
                          alt="Icone cardápio feliz"
                          className="mb-3"
                        />
                        <p className="h3 fw-semibold">
                          Cardápio - {menu.title.slice(0, 3)}
                        </p>
                      </div>
                    </Menu>
                    <h2 className="h4 mt-3 fw-normal">{menu.title}</h2>
                  </a>
                </div>
              ))}
            </>
          ) : (
            <div className="mb-5">
              <NoData message="Nenhum cardápio cadastrado" />
            </div>
          )}
        </div>
      </div>
      <Loading active={loading} color="#00a189" />
    </Container>
  );
};

export default Menus;
