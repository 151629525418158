import styled from 'styled-components';

interface IMenu {
  active: boolean;
}

interface IAvatar {
  src: string;
}

export const WhiteSpace = styled.div`
  height: 76px;
`;

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 80px;
  background: #f9f9f9;
  //box-shadow: 0px 1px 10px 0px rgba(32, 32, 32, 0.2);
  z-index: 1000;

  hr {
    height: 2px;
    background-color: #ebebeb;
  }

  .logo img {
    width: 45px;
    height: 45px;
  }

  .btn-menu {
    width: 25px;
    height: 25px;

    span {
      display: block;
      width: 100%;
      height: 2px;
      background-color: #202020;
      border-radius: 30px;
      transition-duration: 0.5s;

      :nth-child(2) {
        margin: 6px 0px;
      }
    }
  }

  .btn-menu.active {
    span {
      :nth-child(1) {
        transform: rotate(225deg);
      }

      :nth-child(2) {
        opacity: 0;
        transform: rotate(225deg);
      }

      :nth-child(3) {
        transform: rotate(315deg);
        margin-top: -16px;
      }
    }
  }

  @media screen and (min-width: 991px) {
    width: 328px;
    height: 100vh;
    //border-right: 1px solid #efefef;
    overflow: hidden;

    :hover {
      overflow-y: scroll;
      padding-right: 1.2rem !important;
    }

    .logo img {
      width: 48px;
      height: 48px;
    }
  }
`;

export const Menu = styled.div<IMenu>`
  position: relative;
  overflow: auto;
  left: ${(props) => (props.active ? 0 : -1000)}px;
  background: #f9f9f9;
  transition-duration: 0.3s;
  width: 100%;
  min-height: calc(100vh - 76px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .item-menu {
    color: #09090a;
    border-radius: 12px;
    background: transparent;
    transition-duration: 0.3s;

    svg {
      color: #000;
      transition-duration: 0.3s;
    }

    span {
      width: calc(100% - 36px);
      -webkit-text-fill-color: #09090a;
      -webkit-text-stroke-width: 1px;
      -webkit-text-stroke-color: transparent;
      transition-duration: 0.3s;
    }
  }

  .item-menu:hover,
  .item-menu.active {
    color: #8b4103;
    background: #f3d6be;

    svg {
      color: #8b4103;
    }

    span {
      -webkit-text-fill-color: #8b4103;
      -webkit-text-stroke-color: #8b4103;
    }
  }

  .box-user {
    width: calc(100% - 5px);
    border-radius: 20px;
    background: #ebebeb;
  }

  ::-webkit-scrollbar {
    background: transparent;
    width: 0px !important;
  }

  @media screen and (min-width: 992px) {
    position: unset;
    overflow: unset;
    min-height: calc(100vh - 300px);
  }
`;

export const Avatar = styled.div<IAvatar>`
  width: 52px;
  height: 52px;
  border-radius: 50%;
  border: 2px solid #fff;
  background-image: url(${(props) => props.src});
  background-color: #224b8f;
  background-position: center;
  background-size: cover;
`;
