import React from 'react';
import { Switch } from 'react-router-dom';

import Route from './Route';

import SignIn from '~/pages/Supplier/SignIn';
import ForgotPassword from '~/pages/Supplier/ForgotPassword';
import ChangePassword from '~/pages/Supplier/ChangePassword';
import SignUp from '~/pages/Supplier/SignUp';
import Dashboard from '~/pages/Supplier/Dashboard';
import OpenProcesses from '~/pages/Supplier/OpenProcesses';
import ProcessesInProgress from '~/pages/Supplier/ProcessesInProgress';
import FinalizedProcesses from '~/pages/Supplier/FinalizedProcesses';
import Profile from '~/pages/Supplier/Profile';

const SupplierRoutes: React.FC = () => {
  return (
    <Switch>
      <Route
        path={`${process.env.PUBLIC_URL}/fornecedor/login`}
        exact
        component={SignIn}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/fornecedor/esqueci-minha-senha`}
        exact
        component={ForgotPassword}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/fornecedor/recuperar-senha/:token`}
        exact
        component={ChangePassword}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/fornecedor/cadastro`}
        exact
        component={SignUp}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/fornecedor/cadastro/:step`}
        exact
        component={SignUp}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/fornecedor/cadastro/:token/inicio`}
        exact
        component={SignUp}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/fornecedor/cadastro/:token/:step`}
        exact
        component={SignUp}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/fornecedor/dashboard`}
        exact
        isPrivate
        component={Dashboard}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/fornecedor/processos-abertos`}
        exact
        isPrivate
        component={OpenProcesses}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/fornecedor/processos-abertos/:nursery`}
        exact
        isPrivate
        component={OpenProcesses}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/fornecedor/processos-em-andamento`}
        exact
        isPrivate
        component={ProcessesInProgress}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/fornecedor/processos-em-andamento/:nursery`}
        exact
        isPrivate
        component={ProcessesInProgress}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/fornecedor/processos-finalizados`}
        exact
        isPrivate
        component={FinalizedProcesses}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/fornecedor/processos-finalizados/:nursery`}
        exact
        isPrivate
        component={FinalizedProcesses}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/fornecedor/perfil`}
        exact
        isPrivate
        component={Profile}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/fornecedor/perfil/:step`}
        exact
        isPrivate
        component={Profile}
      />
    </Switch>
  );
};

export default SupplierRoutes;
