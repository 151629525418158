import { darken } from 'polished';
import styled from 'styled-components';

export const Container = styled.footer`
  background: #f0eff4;
  font-family: 'Poppins';
  padding-top: 80px;
  .logo {
    font-family: 'Inter';
    width: 48px;
    height: 48px;
  }

  .adress-side {
    p {
      color: #686666;
      font-size: 16px;
      font-weight: 500;
      line-height: 32px;
    }

    a {
      color: #343434;
      font-size: 16px;
      font-weight: 500;
      line-height: 32px;
    }
  }

  .services-side {
    h3 {
      color: #202020;
      font-size: 18px;
      font-weight: 600;
      letter-spacing: 0.18px;
      margin-bottom: 16px;
    }
    a {
      color: rgba(32, 32, 32, 0.6);
      font-size: 16px;
      font-weight: 400;
      line-height: 28px;
      margin-bottom: 16px;
    }
  }

  hr {
    height: 1px;
    background-color: #cfcfcf;
    opacity: 1;
  }

  .social h3 {
    color: #000;
    font-size: 16px;
    font-weight: 500;
  }

  .text-logo {
    color: #202020;
  }

  .icon-whatsapp-call {
    background: #22de57;
    border-radius: 50%;
    width: 66px;
    height: 66px;
    position: fixed;
    bottom: 100px;
    right: 20px;
  }

  .line-footer {
    border: 1px solid #464646;
  }

  .btn-wpp {
    transition-duration: 0.3s;
    background: #29d26e;
    width: 64px;
    height: 64px;
    border-radius: 50%;
    right: 20px;
    bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 32px;
      height: 32px;
    }

    :hover {
      background: ${darken(0.03, '#29d26e')};
    }
  }

  @media screen and (min-width: 992px) {
    padding-top: 150px;
    .logo {
      width: 63px;
      height: 63px;
    }
    .box {
      h3 {
        line-height: 48px;
      }
    }

    .icon-footer {
      width: 56px;
      height: 56px;
      svg {
        font-size: 28px;
      }
    }

    .icon-whatsapp-call {
      bottom: 10px;
      right: 100px;
    }

    .btn-wpp {
      width: 100px;
      height: 100px;

      svg {
        width: 50px;
        height: 50px;
      }
    }
  }
`;
